import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import config from '../../../config/config';
import { setSupport } from '../../../redux/actions/trip';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useEffect } from 'react';

const ExtraSupport = ({ support, setSupport }) => {
	const [showDetails, setShowDetails] = useState(null);
	const [currentForground, setCurrentForground] = useState(null);
	const [currentTimeout, setCurrentTimeout] = useState();

	// useEffect(() => {
	// 	if (showDetails === null) {
	// 		setCurrentTimeout(
	// 			setTimeout(() => {
	// 				if (!currentForground) {
	// 					setCurrentTimeout(null);
	// 				}
	// 				setCurrentForground(null);
	// 			}, 50)
	// 		);
	// 	}
	// }, [showDetails, currentForground]);

	const returnSupport = () => {
		return config.support.map((level, index) => {
			const { text, value, title } = level;
			return (
				<Fragment key={`trip-type-${index}`}>
					<div className='relative'>
						<div
							style={{
								zIndex: (config.support.length - index) * 2,
							}}
							className='absolute'>
							<button
								onMouseEnter={() => {
									clearTimeout();
									setCurrentForground(index);
									setShowDetails(index);
								}}
								onMouseLeave={() => setShowDetails(null)}
								onClick={() => {
									setSupport(level.value);
								}}
								className={`${
									support === level.value
										? ` newcss-selected-button`
										: ' newcss-unselected-button'
								} support`}>
								<div className='img-background'>
									<img
										src={
											support === level.value
												? level.imageWhite
												: level.imagePurple
										}
										alt=''
									/>
								</div>
								<h3>{title}</h3>
								<AnimatePresence>
									{showDetails === index && (
										<motion.p
											key={`p-detail-${index}`}
											initial={{ y: -100, opacity: 0, height: 0, zIndex: 0 }}
											animate={{
												y: 0,
												opacity: 1,
												height: 'auto',
												zIndex: 10,
												transition: {
													height: {
														duration: 0.25,
													},
													opacity: { duration: 0.25 },
													y: { duration: 0.15 },
												},
											}}
											exit={{
												y: -100,
												opacity: 0,
												height: 0,
												transition: {
													height: {
														duration: 0.1,
													},
													opacity: { duration: 0.15 },
													y: { duration: 0.15 },
												},
											}}>
											{text}
										</motion.p>
									)}
								</AnimatePresence>
							</button>
						</div>
					</div>
				</Fragment>
			);
		});
	};
	return (
		<section className={`trip__form-card`}>
			<h2 className='trip__form-card-header'>
				EXTRA SUPPORT/GUIDANCE FROM ROVE
			</h2>
			{/* <label htmlFor='type'>
				Tell us what type of trip you’re taking (choose 1)
			</label> */}
			<div className='extra-support'>{returnSupport()}</div>
		</section>
	);
};

ExtraSupport.propTypes = {};

const mapStateToProps = (state) => ({
	support: state.trip.support,
});

export default connect(mapStateToProps, { setSupport })(ExtraSupport);
