import React, { Fragment } from 'react';
import img from '../../images/Mask.svg';

export default function PreferenceKickOff(props) {
	return (
		<Fragment>
			<section className='kickoff-container'>
				<img src={img} alt='' />
				<h2>Set your preferences!</h2>
				<p>
					We'd like to get to know YOU as a traveler, no matter the end
					destination.
				</p>
				<p>Don't worry, you can always change these later!</p>
				<button className='newcss-cta-button' onClick={props.nextComponent}>
					Let's do it!
				</button>
			</section>
		</Fragment>
	);
}
