import React from 'react';
import Attachment from './Attachment';

const AttachmentLinks = ({ attachments, removeAttachment }) => {
	return (
		<div className='attachment-links'>
			{attachments && attachments.length ? (
				attachments.map((attachment, index) => {
					return (
						<Attachment
							key={`attachment-${index}`}
							removeAttachment={removeAttachment}
							attachment={attachment}
							index={index}
						/>
					);
				})
			) : (
				<h5>No attachments available.</h5>
			)}
		</div>
	);
};

export default AttachmentLinks;
