import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../../redux/actions/auth';
import ErrMessage from '../Layout/ErrMessage';
import AuthFooter from './AuthFooter';
import FormInput from './FormInput';
toast.configure();

const LogInFunc = ({
	toggleForm,
	token,
	login,
	loginError,
	setCurrentForm,
	currentForm,
}) => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		token: token,
		success: false,
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value,
		});
	};
	const canBeSubmitted = () => {
		const { email, password } = formData;
		return email && password;
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		const { email, password } = formData;
		if (canBeSubmitted) {
			login({ email, password });
		}
	};
	return (
		<div className='auth-container'>
			<form className='auth-form' onSubmit={handleSubmit}>
				{loginError ? <ErrMessage text={loginError} /> : null}
				<FormInput
					value={formData.email}
					label='Email'
					handleChange={handleChange}
					// alert={returnError('firstName')}
					id={'email'}
					type='email'
				/>

				<FormInput
					label='Password'
					handleChange={handleChange}
					// alert={returnError('firstName')}
					id='password'
					type='password'
				/>

				<AuthFooter
					currentForm={currentForm}
					disabled={!canBeSubmitted}
					setCurrentForm={setCurrentForm}
					altForm={'sign-up'}
				/>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	token: state.auth.token,
	loginError: state.errors.loginError,
});

export default connect(mapStateToProps, { login })(LogInFunc);
