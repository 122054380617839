import { errorStrings } from '../config/errors';
import validator from 'validator';
import React from 'react';

export const passwordValidation = (p) => {
	if (/[A-Z]/.test(p) === false) {
		return false;
	} else if (/[0-9]/.test(p) === false) {
		return false;
	} else if (/[!@#$%^&*]/.test(p) === false) {
		return false;
	} else {
		return true;
	}
};
export const canBeSubmitted = (setError, formData) => {
	let passwordComparison = {};

	let allFieldsValid = true;
	let isValidField;
	for (let key in formData) {
		if (key === 'email') {
			isValidField = setError(
				validator.isEmail(formData[key]),
				key,
				errorStrings[key]
			);
		} else if (key === 'password1' || key === 'password2') {
			passwordComparison[key] = true;
			isValidField = setError(
				validator.isStrongPassword(formData[key]),
				key,
				errorStrings[key]
			);
		} else {
			isValidField = setError(formData[key], key, errorStrings[key]);
		}

		if (!isValidField) {
			allFieldsValid = false;
		}
	}
	const isPasswordComparison =
		passwordComparison.password1 && passwordComparison.password2;

	const samePassword = formData.password1 === formData.password2 ? true : false;

	return (isPasswordComparison ? samePassword : true) && allFieldsValid;
};

export const handlePasswordStatus = (password) => {
	if (password && !validator.isStrongPassword(password)) {
		return (
			<span className='weak'>
				Must be 8 or more characters and contain at least 1 number, 1 special
				character, and 1 uppercase character.
			</span>
		);
	} else if (password && validator.isStrongPassword(password)) {
		return <span className='strong'>Strong password</span>;
	} else {
		return null;
	}
};

export const handleSamePassword = (first, second) => {
	if (second && first === second) {
		return <span className='match'>Passwords Match</span>;
	} else if (second && first !== second) {
		return <span className='no-match'>Passwords Do Not Match</span>;
	} else {
		return null;
	}
};
