import React, { useState, useEffect } from 'react';
import Hotel from '../Hotels/Hotel';
import CityStateSelector from './CityStateSelector';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import {
	statelessHotelSearch,
	setAmadeusHotels,
} from '../../redux/actions/amadeus';
import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
import moment from 'moment';
import config from '../../config/config';
const override = css`
	display: block;
	margin: 3rem auto;
`;
const HotelOptions = ({
	statelessHotelSearch,
	trip,
	bookingOptions,
	setAmadeusHotels,
}) => {
	const [loading, setLoading] = useState(true);
	const [hotelGroups, setHotelGroups] = useState(null);
	const [selectedCity, setSelectedCity] = useState('');

	const returnLoader = () => {
		return (
			<div className='newcss-sweet-loading newcss-80vh'>
				<FadeLoader css={override} size={150} color={'black'} loading={true} />
			</div>
		);
	};

	const handleHotelSearch = async () => {
		await statelessHotelSearch(trip._id);
		setLoading(false);
	};

	const setHotelCache = async () => {
		const cache = localStorage.getItem(`hotelCache${trip._id}`);
		if (!cache) {
			await statelessHotelSearch(trip._id);
			setLoading(false);
		} else {
			await setAmadeusHotels(JSON.parse(cache));
			setLoading(false);
		}
	};

	const sortAndMap = (array) => {
		const sorted = array.sort((a, b) => {
			return (
				a.data['RoomRates'][0].RoomRate[0].Total[0]['info']['AmountAfterTax'] -
				b.data['RoomRates'][0].RoomRate[0].Total[0]['info']['AmountAfterTax']
			);
		});

		return sorted.map((hotel, index) => {
			return <Hotel key={`sorted-hotel-${index}`} hotel={hotel} />;
		});
	};

	// This side effect updates hotels if time limit has passed.
	useEffect(() => {
		let prevMount = localStorage.getItem(`lastHotelSearch${trip._id}`);

		if (prevMount) {
			const now = moment(new Date());
			prevMount = moment(new Date(prevMount));

			const minutes = now.diff(prevMount, 'minutes');

			if (minutes >= config.cacheTime) {
				handleHotelSearch();
			} else {
				setHotelCache();
			}
		} else {
			handleHotelSearch();
		}
	}, []);

	// This side effect triggers when updated hotel results are returned or updated in any way.
	useEffect(() => {
		let groups = {
			'All Cities': bookingOptions,
		};
		if (bookingOptions) {
			bookingOptions.forEach((option, index) => {
				const key = `${option.data.hotel_info.address.CityName}, ${
					option.data.hotel_info.address.StateCode ||
					option.data.hotel_info.address.CountryName
				}`;
				if (groups[key]) {
					groups[key] = [...groups[key], option];
				} else {
					groups[key] = [option];
				}
				if (index === 0) {
					setSelectedCity('All Cities');
				}
			});
		}
		setHotelGroups(groups);
	}, [bookingOptions]);

	return (
		<Fragment>
			<CityStateSelector
				allItems={bookingOptions}
				cities={hotelGroups && Object.keys(hotelGroups)}
				setSelectedCity={setSelectedCity}
				selectedCity={selectedCity}
			/>
			{!loading ? (
				<div className='newcss-itinerary'>
					{hotelGroups[selectedCity] && sortAndMap(hotelGroups[selectedCity])}
				</div>
			) : (
				returnLoader()
			)}
		</Fragment>
	);
};

HotelOptions.propTypes = {
	trip: PropTypes.object,
};

const mapStateToProps = (state) => ({
	trip: state.trip,
	bookingOptions: state.amadeus.hotel.bookingOptions,
});

export default connect(mapStateToProps, {
	statelessHotelSearch,
	setAmadeusHotels,
})(HotelOptions);
