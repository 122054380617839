import React, { useEffect } from 'react';
import ItineraryCategories from '../ItinerarySidenav/ItineraryCategories';
import ItemOptions from '../ItemsOptions/ItemOptions';
import ItineraryCalendar from '../Itinerary/ItineraryCalendar';

const PlanTripMain = ({
	selected,
	handleSelected,
	toggleModal,
	modalOpen,
	setDetails,
	itemDetails,
}) => {
	return (
		<div className='itinerary-main'>
			<div className='item-nav'>
				<ItineraryCategories
					id={null}
					handleSelected={handleSelected}
					selected={selected}
					showCities={null}
					toggleModal={toggleModal}
					// selected={null}
					modalOpen={modalOpen}
					saveTrip={null}
					toggleState={null}
				/>
				<ItemOptions
					toggleModal={toggleModal}
					modalOpen={modalOpen}
					selected={selected}
					selectedItem={null}
					toggleState={null}
					setDetails={setDetails}
				/>
			</div>

			<div className={`calendar${modalOpen ? ' sidenav-open' : ''}`}>
				<ItineraryCalendar selected={selected} />
			</div>
		</div>
	);
};

// const mapStateToProps = (state) => ({
// 	trip: state.trip,
// });

export default PlanTripMain;
