import React from 'react';
const Amenity = ({ image, title, enabled }) => {
	return (
		<div className={`amenity-item${enabled ? ' enabled' : ''}`}>
			<img src={enabled ? image.dark : image.grey} alt='' /> <p>{title}</p>
		</div>
	);
};

export default Amenity;
