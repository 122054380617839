import React from 'react';

const PriceDiv = ({ price, text, highlighted }) => {
	return (
		<div className={`price-div ${highlighted && 'highlighted'}`}>
			<p className='price'>{price}</p>
			<p className='subtext'>{text}</p>
		</div>
	);
};

export default PriceDiv;
