import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import config from '../../config/config';

const ProfileDropDown = ({ logout, toggleDropdown, saveTrip }) => {
	const signOut = () => {
		logout();
	};

	useEffect(() => {});
	return (
		<Fragment>
			<div
				autoFocus
				className='profile-dropdown'
				onBlur={(e) => {
					e.preventDefault();

					toggleDropdown(false);
				}}>
				<li>
					<NavLink
						to='/'
						className='nav-links-link'
						style={{ padding: 0 }}
						onClick={async () => {
							if (saveTrip) {
								saveTrip();

								signOut();
							} else {
								signOut();
							}
						}}>
						{config.SignedInLinks.Logout}
					</NavLink>
				</li>
				<li>
					<NavLink
						className='nav-links-link'
						style={{ padding: 0 }}
						to={config.SignedInLinks.contact}
						onClick={async () => {
							if (saveTrip) await saveTrip();
						}}>
						{config.SignedInLinks.Contact}
					</NavLink>
				</li>
				<li>
					<NavLink
						className='nav-links-link'
						style={{ padding: 0 }}
						to={config.SignedInLinks.editPreferences}
						onClick={async () => {
							if (saveTrip) await saveTrip();
						}}>
						Preferences
					</NavLink>
				</li>
				<li>
					<NavLink
						className='nav-links-link'
						style={{ padding: 0 }}
						to={config.SignedInLinks.settings}
						onClick={async () => {
							if (saveTrip) await saveTrip();
						}}>
						Settings
					</NavLink>
				</li>
				<li>
					<NavLink
						className='nav-links-link'
						style={{ padding: 0 }}
						to='/testimonials'
						onClick={async () => {
							if (saveTrip) await saveTrip();
						}}>
						Testimonials
					</NavLink>
				</li>
			</div>
		</Fragment>
	);
};

ProfileDropDown.propTypes = {
	logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(ProfileDropDown);
