import React, { useState } from 'react';
import profile from '../../images/profile.png';
import ProfileDropDown from './ProfileDropDown';
import { connect } from 'react-redux';
import { submitUpdate } from '../../redux/actions/trip';

const SignedInLinks = ({ trip, submitUpdate }) => {
	const [dropdown, toggleDropdown] = useState(false);

	const saveTrip = async () => {
		if (window.location.pathname === '/cart/' && trip._id) {
			const res = await submitUpdate(trip);
			if (res.status === 200) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	return (
		<ul className={'nav-links-fullscreen'}>
			<li
				className='profile-dropdown__container'
				onMouseEnter={() => toggleDropdown(true)}
				onMouseLeave={() => toggleDropdown(false)}>
				<button className='nav-links-link'>
					<img
						className='newcss-profile-image'
						src={profile}
						alt='user profile image'
					/>
				</button>
				{dropdown && (
					<ProfileDropDown
						saveTrip={saveTrip}
						toggleDropdown={toggleDropdown}
					/>
				)}
			</li>
		</ul>
	);
};
const mapStateToProps = (state) => ({
	trip: state.trip,
});

export default connect(mapStateToProps, { submitUpdate })(SignedInLinks);
