import React from 'react';

const Attachment = ({ attachment }) => {
	return (
		<div className='attachment'>
			<a href={attachment.path}>{attachment.name}</a>
		</div>
	);
};

export default Attachment;
