import React from 'react';
import { selectModalItem } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { airlines } from '../../airlines';
import flightImage from '../../images/flight2.svg';
import Legs from './Legs';
import PassengerPricing from './PassengerPricing';

const SmallFlightOffer = ({ selectModalItem, flight, id }) => {
	const { legs, recommendation } = flight.data;
	const legsHasLength = legs && legs.length;
	let leg = legsHasLength && legs[0];
	const { baggage_info, pricing_info } = recommendation;

	const imageURL =
		legsHasLength &&
		leg.flight_details[0].companyId.marketingCarrier &&
		airlines[leg.flight_details[0].companyId.marketingCarrier] &&
		airlines[leg.flight_details[0].companyId.marketingCarrier].image;

	const airlineName =
		legsHasLength &&
		airlines[leg.flight_details[0].companyId.marketingCarrier] &&
		airlines[leg.flight_details[0].companyId.marketingCarrier].name;

	let code, number;

	if (baggage_info) {
		({ code, number } = baggage_info);
	}

	number = parseInt(number);

	return (
		<>
			<section>
				<div className='mobile-wrapper'>
					<header className='itinerary__flight-card-header'>
						<img src={imageURL ? imageURL : flightImage} alt='' />

						<div className='additional-details'>
							{legsHasLength &&
							leg.flight_details[0].companyId.marketingCarrier ? (
								<p className='capitalize' style={{ margin: '0.25em' }}>
									{airlineName}{' '}
								</p>
							) : null}

							<PassengerPricing pricing_info={pricing_info} id={id} />
						</div>
					</header>
					<Legs legs={legs} />
				</div>
				<p className='checked-bags'>
					{number ? 'Checked bags included' : 'Checked bags not included'}
				</p>
			</section>

			<button
				onClick={() => selectModalItem({ type: 'flight-offer', ...flight })}
				className='newcss-item-button'
				style={{
					display: 'block',
				}}>
				Details
			</button>
		</>
	);
};

SmallFlightOffer.propTypes = {
	selectModalItem: PropTypes.func.isRequired,
	selectedItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
	selectedItem: state.trip.selectedItem,
});

export default connect(mapStateToProps, { selectModalItem })(SmallFlightOffer);
