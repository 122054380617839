import { post } from '../../Utilities/api';

export const statelessHotelSearch = (trip_id) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/get_top_10_available',
			{ trip_id },
			authRequired
		).catch((err) => {
			throw {
				message: err.message,
			};
		});
		if (res.data.success) {
			// await dispatch(setTrip(res.data.trip));
			const stringified = JSON.stringify({
				bookingOptions: res.data.bookingOptions,
			});
			localStorage.setItem(`hotelCache${res.data.trip._id}`, stringified);

			localStorage.setItem(
				`lastHotelSearch${res.data.trip._id}`,
				new Date().toISOString()
			);

			await dispatch({
				type: 'STATELESS_HOTEL_SEARCH',
				payload: {
					bookingOptions: res.data.bookingOptions,
				},
			});
		} else {
			dispatch({
				type: 'CLEAR_HOTELS',
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const setAmadeusHotels = (item) => (dispatch) => {
	dispatch({
		type: 'STATELESS_HOTEL_SEARCH',
		payload: item,
	});
};

export const amadeusModalItem = (item) => (dispatch) => {
	dispatch({
		type: 'AMADEUS_MODAL_ITEM',
		payload: item,
	});
};

export const flightSearch = (trip_id, flights) => async (dispatch) => {
	try {
		const authRequired = true;

		const res = await post(
			'/api/v1/amadeus_test/get_top_10_flights',
			{ trip_id },
			authRequired
		);

		dispatch(updateAmadeusItems(res.data.updated_flights, 'flights'));
		localStorage.setItem(
			`flightSearchTime${trip_id}`,
			JSON.stringify(new Date())
		);

		localStorage.setItem(
			`flightSearchItems${trip_id}`,
			JSON.stringify(res.data.updated_flights)
		);
		return true;
	} catch (error) {
		return false;
	}
};

export const updateAmadeusItems = (items, key) => (dispatch) => {
	dispatch({
		type: 'UPDATE_AMADEUS_ITEMS',
		payload: { items, key },
	});
};

export const setCars =
	({ bookingOptions, companies }) =>
	(dispatch) => {
		dispatch({
			type: 'STATELESS_CAR_SEARCH',
			payload: {
				bookingOptions,
				companies,
			},
		});
	};

export const statelessCarSearch = (trip_id) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/amadeus_test/cars_to_client',
			{ trip_id },
			true
		);

		if (res.data.success) {
			let { companies, recommended_car_rentals_by_location, disableItems } =
				res.data;
			let bookingOptions = recommended_car_rentals_by_location;

			localStorage.setItem(
				`lastAmadeusCarSearch${trip_id}`,
				JSON.stringify(new Date())
			);
			localStorage.setItem(
				`carSearchItems${trip_id}`,
				JSON.stringify({
					bookingOptions,
					companies,
					disableItems,
				})
			);

			dispatch({
				type: 'STATELESS_CAR_SEARCH',
				payload: {
					bookingOptions,
					companies,
					disableItems,
				},
			});
		}
	} catch (error) {}
};
