import React from 'react';

const CalSample = () => {
	return (
		<div className='cal-sample'>
			<div className='date-1'>
				<h3 className='day'>Mon</h3>
				<h2 className='date'>July 6</h2>
			</div>
			<div className='date-2'>
				<h3 className='day'>Tue</h3>
				<h2 className='date'>July 7</h2>
			</div>
			<div className='activity-1'>
				<div className='activity'>
					<h3 className='activity-title'>City Walk</h3>
					<p className='activity-time'>9:00am</p>
					<div className='activity-button'>
						<h2>Add to Cart</h2>
					</div>
				</div>
			</div>
			<div className='activity-2'>
				<div className='activity'>
					<h3 className='activity-title'>
						Architecture <br /> Tour
					</h3>
					<p className='activity-time'>9:30am</p>
					<div className='activity-button'>
						<h2>Add to Cart</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CalSample;
