import React, { useState, Fragment, useEffect } from 'react';
import SingleOptionPreference from './SingleOptionPreference';
import config from '../../config/config';
import { AnimatePresence } from 'framer-motion';

const TransportationPreferences = ({
	previousComponent,
	nextComponent,
	edit,
	changePreferenceIndex,
	index,
	handleDirection,
	direction,
}) => {
	const windows = [
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='driveVsDriver'
			title='Do you prefer to drive or be driven when you travel?'
			back={previousComponent}
			next={() => handleDirection(1)}
			children={config.TRANSPORTPREFERENCES.driveVsDriver.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='publicVsPrivate'
			title='How do you like to get around when you travel?'
			back={() => handleDirection(-1)}
			next={() => handleDirection(1)}
			children={config.TRANSPORTPREFERENCES.publicVsPrivate.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='publicTransportationPreferences'
			title='What is your preferred mode of public transportation?'
			back={() => handleDirection(-1)}
			next={nextComponent}
			children={
				config.TRANSPORTPREFERENCES.publicTransportationPreferences.children
			}
		/>,
	];

	return (
		<Fragment>
			{' '}
			<AnimatePresence>
				{windows.map((window, windowIndex) => {
					if (windowIndex === index || edit) return window;
					else return null;
				})}
			</AnimatePresence>
		</Fragment>
	);
};

export default TransportationPreferences;
