import React from 'react';
import Amenity from './Amenity';
import parking from '../../../images/parking.svg';
import pool from '../../../images/pool.svg';
import wifi from '../../../images/noun-wifi-1254486-142350.svg';
import tv from '../../../images/noun-tv-4765847-142350.svg';
import kitchen from '../../../images/kitchen.svg';
import spa from '../../../images/noun-spa-2528450-142350.svg';
import gym from '../../../images/noun-gym-1396008-142350.svg';
import coffeemachine from '../../../images/noun-coffee-machine-2913373-142350.svg';
import breakfast from '../../../images/noun-coffee-2820266-142350.svg';
import bar from '../../../images/noun_cocktail.svg';
import greyParking from '../../../images/noun-parking-2946816-808080.svg';
import greyPool from '../../../images/noun-pool-2548920-808080.svg';
import greyWifi from '../../../images/noun-wifi-1254486-808080.svg';
import greyTv from '../../../images/noun-tv-4765847-808080.svg';
import greyKitchen from '../../../images/noun-kitchen-2838070-808080.svg';
import greySpa from '../../../images/noun-spa-2528450-808080.svg';
import greyGym from '../../../images/noun-gym-1396008-808080.svg';
import greyCoffeemachine from '../../../images/noun-coffee-machine-2913373-808080.svg';
import greyBreakfast from '../../../images/noun-coffee-2820266-808080.svg';
import greyBar from '../../../images/noun-cocktail-4419183-808080.svg';

const Amenities = ({ amenities }) => {
	const hotelAmenityCodes = {
		// Parking

		42: 1,
		53: 1,
		63: 1,
		64: 1,
		65: 1,
		75: 1,
		97: 1,
		116: 1,
		184: 1,
		230: 1,
		376: 1,
		377: 1,
		// Pool

		49: 2,
		54: 2,
		66: 2,
		71: 2,
		253: 2,

		// WIFI
		178: 3,
		179: 3,
		222: 3,
		223: 3,
		259: 3,
		261: 3,
		286: 3,
		343: 3,
		344: 3,

		// Kitchenette

		262: 5,

		// Spa
		61: 6,
		84: 6,
		107: 6,

		// Fitness Center

		35: 7,
		48: 7,
		345: 7,

		// Coffee/Tea
		20: 8,
		137: 8,
		140: 8,
		299: 8,

		//  Breakfast
		138: 9,
		141: 9,
		142: 9,
		159: 9,
		161: 9,
		173: 9,
		250: 9,
		227: 9,
		251: 9,
		252: 9,
		287: 9,

		// Hotel Bar

		85: 10,
		165: 10,
		187: 10,
		188: 10,
		297: 10,
		346: 10,
	};

	const roomAmenityCodes = {
		// Pool

		245: 2,
		280: 2,
		// WIFI
		51: 3,
		54: 3,
		123: 3,
		207: 3,
		226: 3,
		// TV
		18: 4,
		20: 4,
		251: 4,
		273: 4,

		// Kitchenette
		59: 5,
		61: 5,
		// Spa
		// Fitness Center
		// Coffee/Tea
		19: 8,
		//  Breakfast
		160: 9,
		190: 9,
		189: 9,
		199: 9,
		// Hotel Bar
	};

	const foundCodes = {};
	amenities.forEach((amenity) => {
		if (
			amenity.roomAmenity &&
			roomAmenityCodes[amenity.info['RoomAmenityCode']]
		) {
			foundCodes[roomAmenityCodes[amenity.info['RoomAmenityCode']]] = true;
		}
		if (amenity.hotelAmenity && hotelAmenityCodes[amenity.info['Code']]) {
			foundCodes[hotelAmenityCodes[amenity.info['Code']]] = true;
		}
	});

	const amenityOptions = [
		{
			name: 'Free Parking',
			code: 1,
			image: { dark: parking, grey: greyParking },
		},
		{ name: 'Pool', code: 2, image: { dark: pool, grey: greyPool } },
		{ name: 'WiFi', code: 3, image: { dark: wifi, grey: greyWifi } },
		{ name: 'TV', code: 4, image: { dark: tv, grey: greyTv } },
		{
			name: 'Kitchenette',
			code: 5,
			image: { dark: kitchen, grey: greyKitchen },
		},
		{ name: 'Spa', code: 6, image: { dark: spa, grey: greySpa } },
		{ name: 'Fitness Center', code: 7, image: { dark: gym, grey: greyGym } },
		{
			name: 'Coffee Machine',
			code: 8,
			image: { dark: coffeemachine, grey: greyCoffeemachine },
		},
		{
			name: 'Free Breakfast',
			code: 9,
			image: { dark: breakfast, grey: greyBreakfast },
		},
		{ name: 'Hotel Bar', code: 10, image: { dark: bar, grey: greyBar } },
	];
	return (
		<div className='amenity-section'>
			{amenityOptions.map((amenity, index) => (
				<Amenity
					key={`selected-item-amenity-${index}`}
					enabled={foundCodes[amenity.code] && true}
					// image={amenitiesOptions[amenity["info"]["RoomAmenityCode"]].image}
					// title={amenitiesOptions[amenity["info"]["RoomAmenityCode"]].title}
					image={amenity.image}
					title={amenity.name}
				/>
			))}
		</div>
	);
};

export default Amenities;
