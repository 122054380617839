import React from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartItem';
import { connect } from 'react-redux';
import moment from 'moment';
import { airlines } from '../../airlines';
import { returnItemFields } from '../../Utilities/helperFunctions';
import {
	accrisBodyType,
	accrisCategory,
	accrisDriveType,
	accrisFuelAir,
} from '../../Utilities/cars';
import { removeItemFrom, moveItemTo } from '../../redux/actions/trip';

const InCart = ({
	adults,
	children,
	infants,
	inCart,
	currentItems,
	moveItemTo,
	removeItemFrom,
}) => {
	const returnFlightItem = (flight) => {
		const { legs, total_amount } = returnItemFields(flight, [
			'legs',
			'total_amount',
		]);
		const endSegmentIndex =
			legs &&
			legs.length &&
			legs[0].flight_details.length &&
			legs[0].flight_details.length - 1;
		const name =
			endSegmentIndex >= 0 &&
			`${flight.data.legs[0].flight_details[0].departure.location} - ${flight.data.legs[0].flight_details[endSegmentIndex].arrival.location}`;

		const airline =
			legs.length &&
			legs[0].flight_details.length &&
			flight.data.legs[0].flight_details[0].companyId.marketingCarrier;

		const image = airline && airlines[airline] && airlines[airline].image;

		const result = {
			...flight,
			key: flight._id,
			inCart,
			image,
			perName: 'Person',
			name: name || 'Name not available',
			unitAmount: adults + children + infants,
			currencyCode: 'USD',
			amountPer: total_amount / (adults + children + infants),
			total: total_amount,
			startTime: moment(flight.data.start).format('LT L'),
			endTime: moment(flight.data.end).format('LT L'),
		};

		return result;
	};
	const handleMoveTo = (item, itemKey) => {
		moveItemTo({
			item,
			itemKey,
			currentGroup: inCart ? 'cart' : 'selected',
			nextGroup: inCart ? 'selected' : 'cart',
		});
	};

	const handleRemoveFrom = (item, itemKey) => {
		removeItemFrom({
			item,
			itemKey,
			currentGroup: inCart ? 'cart' : 'selected',
		});
	};

	const returnAmadeusCarItem = (car) => {
		const handleCarDate = (item) => {
			let { day, hour, minutes, month, year } = item;
			day = parseInt(day);
			month = parseInt(month) - 1;
			minutes = parseInt(minutes);
			year = parseInt(year);
			hour = parseInt(hour);
			const date = new Date(year, month, day, hour, minutes);

			return {
				unformatted: moment(date),
				formatted: moment(date).format('LLLL'),
			};
		};

		const handleAccris = (vehicleRentalPrefType) => {
			const category = accrisCategory[vehicleRentalPrefType[0]];
			const type = accrisBodyType[vehicleRentalPrefType[1]];
			const transmission = accrisDriveType[vehicleRentalPrefType[2]];
			const feulAir = accrisFuelAir[vehicleRentalPrefType[3]];
			return {
				category,
				type,
				transmission,
				feulAir,
			};
		};
		let image, name, total, startTime, endTime, vehicleRentalPrefType;

		let { vehicleTypeInfo, rateDetailsInfo, pickupDropoffTime, sizedPictures } =
			car.data;

		let { beginDateTime, endDateTime } = pickupDropoffTime;

		name = vehicleTypeInfo && vehicleTypeInfo.carModel;

		vehicleRentalPrefType =
			vehicleTypeInfo.vehicleCharacteristic &&
			vehicleTypeInfo.vehicleCharacteristic.vehicleRentalPrefType;

		const { category, type } = handleAccris(vehicleRentalPrefType);
		name = !name ? `${category}/${type}` : name;
		image =
			sizedPictures &&
			sizedPictures.length &&
			sizedPictures[0].imageURL &&
			sizedPictures[0].imageURL.address &&
			sizedPictures[0].imageURL.address.identifier
				? sizedPictures[0].imageURL.address.identifier
				: null;
		total = rateDetailsInfo.find((detail) => {
			return detail.tariffInfo.rateType === '906';
		}).tariffInfo.rateAmount;

		total = total && parseFloat(total);

		startTime = handleCarDate(beginDateTime).formatted;
		endTime = handleCarDate(endDateTime).formatted;
		const daysBetween = handleCarDate(endDateTime).unformatted.diff(
			handleCarDate(beginDateTime).unformatted,
			'days'
		);

		const result = {
			...car,
			key: car._id || null,
			inCart,
			image,
			name: name || 'Name not available',
			perName: 'Day',
			unitAmount: null,
			currencyCode: 'USD',
			amountPer: total / daysBetween,
			total,
			startTime,
			endTime,
		};
		return result;
	};

	const returnActivityItem = (activity) => {
		const { price, start, end, imageUrl, units, type } = activity;
		const amount = price && price.amount;
		const currencyCode = price && price.currencyCode;
		const amountIsString = amount && typeof amount === 'string';
		return {
			...activity,
			key: activity._id,
			inCart,
			currencyCode,
			image: imageUrl,
			amountPer:
				type === 'individual-activity' && amountIsString
					? amount
					: amount / (adults + infants + children),
			unitAmount: units,
			total: amount && amountIsString ? parseFloat(amount) * units : 0,
			startTime: moment(start).format('LT L'),
			endTime: moment(end).format('LT L'),
		};
	};

	const returnLodgingItem = (item) => {
		const { HotelName, RoomRates, HotelInfo } = returnItemFields(item, [
			'HotelName',
			'RoomRates',
			'HotelInfo',
		]);
		const media =
			HotelInfo &&
			HotelInfo[0] &&
			HotelInfo[0]['Descriptions'] &&
			HotelInfo[0]['Descriptions'][0]['MultimediaDescriptions'] &&
			HotelInfo[0]['Descriptions'][0]['MultimediaDescriptions'][0] &&
			HotelInfo[0]['Descriptions'][0]['MultimediaDescriptions'][0][
				'MultimediaDescription'
			]
				? HotelInfo[0]['Descriptions'][0]['MultimediaDescriptions'][0][
						'MultimediaDescription'
				  ]
				: null;

		const imageURIs =
			media &&
			media.find((item) => item['ImageItems'] && item.info['InfoCode'] === '1');
		let image =
			imageURIs &&
			imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].find(
				(item) =>
					parseInt(item.info['Width']) <= 600 &&
					parseInt(item.info['Width']) >= 450 &&
					item.info['Format'] === '5'
			);
		image = image && image['URL'][0];
		const { Total, NumberOfUnits } = returnItemFields(RoomRates, [
			'Total',
			'NumberOfUnits',
		]);

		let { AmountAfterTax, CurrencyCode } = returnItemFields(Total, [
			'AmountAfterTax',
			'CurrencyCode',
		]);
		AmountAfterTax = AmountAfterTax && parseFloat(AmountAfterTax);
		return {
			...item,
			key: item._id,
			inCart,
			perName: 'Person',
			name: HotelName,
			amountPer: AmountAfterTax / (adults + children + infants),
			unitAmount: NumberOfUnits,
			currencyCode: CurrencyCode,
			total: AmountAfterTax,
			image,
			startTime: null,
			endTime: null,
		};
	};

	const returnTransportItem = (item) => {
		return {
			...item,
			key: `${item._id}`,
			inCart,
			image: item.imageUrl,
			currencyCode: item.price.currencyCode,
			unitAmount: item.units,
			perName: 'Person',
			amountPer: item.price.amount,
			total: parseFloat(item.price.amount) * item.units,
			startTime: moment(item.start).format('LT L'),
			endTime: moment(item.end).format('LT L'),
		};
	};

	const returnItems = (items, returnItemFn, keyName) => {
		return items
			? items.map((item) => {
					const {
						key,
						inCart,
						image,
						_id,
						name,
						type,
						currencyCode,
						unitAmount,
						total,
						startTime,
						endTime,
						amountPer,
						perName,
					} = returnItemFn(item);
					return (
						<CartItem
							handleRemoveFrom={handleRemoveFrom}
							handleMoveTo={handleMoveTo}
							perName={perName}
							item={item}
							key={`${keyName}-${key}`}
							inCart={inCart}
							image={image}
							_id={_id}
							name={name}
							amountPer={amountPer}
							type={type}
							currencyCode={currencyCode}
							unitAmount={unitAmount}
							total={total}
							startTime={startTime}
							endTime={endTime}
						/>
					);
			  })
			: null;
	};
	return currentItems ? (
		<div className='cart-main'>
			{returnItems(
				currentItems.flights,
				returnFlightItem,
				inCart ? 'flight-cart-item' : 'flight-saved-item'
			)}
			{returnItems(
				currentItems.amadeusCars,
				returnAmadeusCarItem,
				inCart ? 'amadeus-car-cart-item' : 'amadeus-car-saved-item'
			)}
			{returnItems(
				currentItems.activities,
				returnActivityItem,
				inCart ? 'activity-cart-item' : 'activity-saved-item'
			)}
			{returnItems(
				currentItems.lodging,
				returnLodgingItem,
				inCart ? 'lodging-cart-item' : 'lodging-saved-item'
			)}
			{returnItems(
				currentItems.transport,
				returnTransportItem,
				inCart ? 'transport-cart-item' : 'transport-saved-item'
			)}
		</div>
	) : null;
};

InCart.propTypes = {
	lodging: PropTypes.object,
	cart: PropTypes.object,
};

const mapStateToProps = (state) => ({
	lodging: state.trip.lodging,
	cart: state.trip.cart,
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, { moveItemTo, removeItemFrom })(InCart);
