import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import _ from './env';

require('./logging');

try {
	ReactDOM.render(
		<Router>
			<App />
		</Router>,
		document.getElementById('root')
	);
} catch (err) {
	console.log('catching error here12', err);
}

serviceWorker.unregister();
