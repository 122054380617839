import React, { Fragment, useState } from 'react';
import LogInFunc from '../Auth/LogInFunc';
import Popup from 'reactjs-popup';
import CreateAccount from '../Auth/CreateAccount';
import PasswordWindow from '../Settings/PasswordWindow';
import ForgotPassword from '../Auth/ForgotPassword';

const LandingPopup = ({ name, button, start, token }) => {
	const [currentForm, setCurrentForm] = useState(start);

	const returnResetPassword = (close) => {
		return (
			<PasswordWindow
				isResetPassword={true}
				token={token}
				setCurrentForm={setCurrentForm}
			/>
		);
	};

	const returnForgotPassword = (close) => {
		return (
			<ForgotPassword
				currentForm={currentForm}
				setCurrentForm={setCurrentForm}
				close={close}
			/>
		);
	};

	const handleCurrentForm = (close) => {
		if (currentForm === 'sign-up') {
			return (
				<Fragment>
					<div className='popup__header'>
						<h3 className='popup__header--text'>Sign Up</h3>
						<i
							className='popup__close'
							onClick={(e) => {
								e.preventDefault();
								setCurrentForm(start);
								close();
							}}>
							<i className='fa fa-times'></i>
						</i>
					</div>
					<CreateAccount
						close={close}
						currentForm={currentForm}
						setCurrentForm={setCurrentForm}
					/>
				</Fragment>
			);
		}
		if (currentForm === 'login') {
			return (
				<Fragment>
					<div className='popup__header'>
						<h3 className='popup__header--text'>Sign In</h3>
						<button
							className='popup__close'
							onClick={(e) => {
								e.preventDefault();
								close();
							}}>
							<i className='fa fa-times'></i>
						</button>
					</div>
					<LogInFunc
						currentForm={currentForm}
						setCurrentForm={setCurrentForm}
					/>
				</Fragment>
			);
		}
		if (currentForm === 'reset-password') {
			return returnResetPassword(close);
		}
		if (currentForm === 'forgot-password') {
			return returnForgotPassword(close);
		}
	};

	return (
		<Popup
			trigger={
				button ? (
					button()
				) : (
					<button
						className={`${name} home-cta-button landing-subheader__button`}>
						Get Started
					</button>
				)
			}
			modal>
			{(close) => (
				<div className='popup' id='popup'>
					<div className='popup__content'>{handleCurrentForm(close)}</div>
				</div>
			)}
		</Popup>
	);
};

export default LandingPopup;
