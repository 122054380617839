const initialState = {
	itineraryModalOpen: false,
	selectedItem: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case 'SELECT_MODAL_ITEM':
			return {
				...state,
				itineraryModalOpen: true,
				selectedItem: payload,
			};
		case 'SET_CUSTOM_START_END':
			return {
				...state,
				selectedItem: {
					...state.selectedItem,
					start: payload.start,

					end: payload.end,
				},
			};
		case 'EDIT_MODAL_FORM':
			return {
				...state,
				selectedItem: {
					...state.selectedItem,
					[payload.key]: payload.value,
				},
			};
		case 'DESELECT_MODAL_ITEM':
			return {
				...state,
				itineraryModalOpen: false,
				selectedItem: null,
			};

		default:
			return state;
	}
}
