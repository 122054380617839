import React from 'react';
import TotalSection from './TotalSection';

const TotalPaid = ({
	headerTitle,
	totalPaid,
	perPersonPrice,
	mobileCurrent,
	mobileCurrentValue,
	isMobile,
}) => {
	return isMobile && mobileCurrent !== mobileCurrentValue ? null : (
		<section className='cart-total-paid__container'>
			<h2>{headerTitle}</h2>
			<section className='cart-total paid'>
				<TotalSection
					text='Total Paid'
					currencyCode='USD'
					price={totalPaid || 0}
					cname='big'
				/>
				<TotalSection
					text='Total Paid per person(avg.)'
					currencyCode='USD'
					price={perPersonPrice}
					cname='big'
				/>
			</section>
		</section>
	);
};

export default TotalPaid;
