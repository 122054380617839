import React from 'react';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';

const HotelOfferContent = ({
	name,
	selectedItem,
	image,
	total,
	timeLength,
	rooms,
	selectModalItem,
	hotel,
}) => {
	const distance =
		hotel &&
		hotel.data &&
		hotel.data.hotel_info &&
		hotel.data.hotel_info.distance
			? hotel.data.hotel_info.distance
			: null;
	return (
		<div
			className={`itinerary-item__card${
				selectedItem && selectedItem._id === hotel._id ? '--selected' : ''
			} hotel`}>
			<img
				className='itinerary-item__card-image'
				src={image || noImage}
				alt='hotel'
				style={{
					display: 'block',
				}}
			/>
			<div className='itinerary-item__card-main'>
				<h3 className='capitalize'>{name.toLowerCase()}</h3>
				<section>
					<p>
						${(parseFloat(total) / timeLength).toFixed(2)}/night
						{/* ${(parseFloat(total) / timeLength / rooms).toFixed(2)}/ night per
						room */}
					</p>
					<p>{`${distance.distance} ${distance.units}`} from city center</p>
				</section>
				<button
					className='newcss-item-button'
					onClick={() => {
						selectModalItem({ type: 'hotel-offer', ...hotel });
					}}>
					Details
				</button>
			</div>
		</div>
	);
};
// Making a change

HotelOfferContent.propTypes = {};

export default HotelOfferContent;
