import React from 'react';

const StepCounter = ({ currentStep, maxStep }) => {
	return (
		<div className='step-counter'>
			{currentStep} / {maxStep}
		</div>
	);
};

export default StepCounter;
