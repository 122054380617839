import React, { useState } from 'react';
import TripForm from './TripForm';
import PaymentDetails from '../CheckoutNew/PaymentDetails';
import ProgressBar from '../ProgressBar';
import PricingCard from '../landing/PricingCard';
import Success from './Success';
import { connect } from 'react-redux';
import { createNewTrip } from '../../redux/actions/trip';
import { submitTrip } from '../../redux/actions/auth';
import ReactGA from 'react-ga';

const CreateTripShowContainer = ({
	stage,
	handleStage,
	errors,
	addTrip,
	trip,
	createNewTrip,
	submitTrip,
	setStage,
	errorSection,
	setErrorSection,
	isAdmin,
}) => {
	const [success, setSuccess] = useState(false);
	const submitAuthorization = async () => {
		const newSubmitted = {
			success: true,
			on: new Date(),
		};
		const newPurchase = {
			...trip.purchase,
			curation: { ...trip.purchase.curation, authorizedOn: new Date() },
		};
		try {
			const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
			ReactGA.initialize(TRACKING_ID);
			ReactGA.event({
				category: 'User_Action',
				action: 'authorize_curation_fee',
				label: 'create_trip_page',
			});

			const submitSuccess = await submitTrip({
				...trip,
				submitted: newSubmitted,
				purchase: newPurchase,
			});

			if (submitSuccess) {
				setSuccess(true);
			}
		} catch (err) {
			console.log(err);
		}
	};
	return success === false ? (
		<div className='create-trip__container'>
			<ProgressBar
				currentStage={stage}
				setStage={handleStage}
				addTrip={addTrip}
				stages={['CURATION FEE', 'TRIP PROFILE', 'CHECKOUT']}
			/>
			{stage === 0 && (
				<PricingCard
					tripForm={true}
					closePricing={() => {
						!trip._id && createNewTrip();
						setStage(1);
					}}
				/>
			)}
			{stage === 1 && (
				<TripForm
					errorSection={errorSection}
					errors={errors}
					isAdmin={isAdmin}
					setSuccess={() => setSuccess(true)}
					nextStage={addTrip}
					setErrorSection={setErrorSection}
				/>
			)}

			{stage === 2 && (
				<PaymentDetails
					handleClick={submitAuthorization}
					setSuccess={() => setSuccess(true)}
					curation={true}
				/>
			)}
		</div>
	) : (
		<Success userId={isAdmin && trip.user._id} />
	);
};

const mapStateToProps = (state) => ({
	errors: state.errors,
	trip: state.trip,
	user: state.auth.user,
});

export default connect(mapStateToProps, { createNewTrip, submitTrip })(
	CreateTripShowContainer
);
