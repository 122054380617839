import React, { Fragment } from 'react';
import DateButton from './DateButton';

const DateButtonSection = ({
	dates,
	selectedDay,
	setSelectedDay,
	dayOptions,
}) => {
	const dayStrings = {
		Mon: 'Monday',
		Tue: 'Tuesday',
		Wed: 'Wednesday',
		Thu: 'Thursday',
		Fri: 'Friday',
		Sat: 'Saturday',
		Sun: 'Sunday',
	};
	const mapDates = () => {
		return dates && dates.length
			? dates.map((date, index) => {
					const dateIsDate = date instanceof Date;

					if (dateIsDate) {
						const formattedDate = date.toDateString();
						const formattedSelected =
							selectedDay instanceof Date && selectedDay.toDateString();

						const day = formattedDate ? formattedDate.slice(0, 3) : null;

						if (dayOptions) {
							return dayOptions[dayStrings[day]] ? (
								<DateButton
									key={`date-${index}`}
									date={date}
									selected={formattedDate === formattedSelected}
									setSelected={setSelectedDay}
								/>
							) : null;
						}
						return (
							<DateButton
								key={`date-${index}`}
								date={date}
								selected={formattedDate === formattedSelected}
								setSelected={setSelectedDay}
							/>
						);
					} else {
						return (
							<DateButton
								key={`date-${index}`}
								date={date}
								selected={date === selectedDay}
								setSelected={setSelectedDay}
							/>
						);
					}
			  })
			: null;
	};

	return (
		<Fragment>
			<label htmlFor='dateRange'>Pick a date</label>
			<div className='date-button-section'>
				<main>{mapDates()}</main>
			</div>
		</Fragment>
	);
};

export default DateButtonSection;
