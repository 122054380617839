import React from 'react';
import PropTypes from 'prop-types';

const WYGItem = ({ title, description, icon }) => {
	// const returnIcons = (amount) => {
	// 	const icons = Array(amount).fill(icon);

	// 	return (
	// 		<div className='icons'>
	// 			{icons.map((iconItem, index) => {
	// 				return <img key={title + index} src={iconItem} alt='' />;
	// 			})}
	// 		</div>
	// 	);
	// };

	return (
		<div className='what-you-get-item'>
			<div className='what-you-get-item__img-container'>
				<img src={icon} alt='' />
			</div>
			<h2>{title}</h2>
			<p>{description}</p>
		</div>
	);
};

WYGItem.propTypes = {};

export default WYGItem;
