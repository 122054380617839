import React, { Fragment, useEffect, useRef, useState } from 'react';
import AirportAutofill from './AirportAutofill';
import ErrMessage from '../Layout/ErrMessage';
import { editFlightDetail } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import { clearFlightError, setFlightError } from '../../redux/actions/errors';
import { findCity } from '../../Utilities/findCityCode';
import { capitalize } from '../../Utilities/helperFunctions';
import AutofillSection from './AutofillSection';
const airportData = require('../../Utilities/new_airport_codes.json');
const newAirportData = require('../../Utilities/new_airport_codes.json');
const AirportInputUnlocked = ({
	value,
	handleChange,
	name,
	error,
	detailIndex,
	editFlightDetail,
	setFlightError,
	clearFlightError,
	clearFlight,
	multiCity,
	inputKey,
	flightDetails,
	handleHotel,
	setTripInputs,
	tripInputs,
}) => {
	const inputRef = useRef(null);
	const heightRefs = useRef([]);
	const [searchResults, setSearchResults] = useState([]);

	const handleSearch = async (e) => {
		e.preventDefault();
		const searchValue = e.target.value.toLowerCase();
		const searchLength = searchValue.length;
		if (!searchValue) {
			setSearchResults([]);
		} else if (searchLength > 2) {
			const results = newAirportData.filter((item) => {
				const airportMatch = item.airportName
					.toLowerCase()
					.startsWith(searchValue);
				const locationMatch = item.locationServed
					.toLowerCase()
					.includes(searchValue);
				const iataMatch = item.iata.startsWith(searchValue);
				return airportMatch || locationMatch || iataMatch;
			});
			// .map((item) => {
			// 	const servedLocation = item.locationServed.split(', ');
			// 	const countryName = servedLocation[servedLocation.length - 1];
			// 	const cityName = servedLocation[0]
			// 		.normalize('NFD')
			// 		.replace(/[\u0300-\u036f]/g, '');

			// 	const city = findCity({
			// 		cityName,
			// 		countryName,
			// 		IATA: item.iata,
			// 	});
			// 	// return item

			// 	return item;
			// });
			setSearchResults(results);
		}
	};

	const returnAirportInfo = (string) => {
		const foundItem =
			airportData && airportData.length
				? airportData.find((item) => item.iata === string)
				: null;
		if (foundItem) {
			const airport = foundItem.airportName;
			return `${string.toUpperCase()} - ${capitalize(airport)} `;
		} else return string;
	};

	const setError = () => {
		if (!value && !error) {
			setFlightError(detailIndex, name, 'Please enter valid airport.');
		}
	};

	const returnSearchResults = () => {
		return searchResults && searchResults.length > 0
			? searchResults.map((result, index) => {
					return (
						<Fragment key={`${inputKey}-index-${index}`}>
							<AirportAutofill
								heightRefs={heightRefs}
								index={index}
								stateCode={result.stateCode}
								name={result.airportName}
								iataCode={result.iata.toUpperCase()}
								onClick={() => {
									const hotelItem = name === 'destination' ? result : null;
									multiCity &&
										name === 'destination' &&
										flightDetails[detailIndex + 1] &&
										editFlightDetail(detailIndex + 1, 'departure', result.iata);
									name === 'destination' &&
										editFlightDetail(detailIndex, 'hotelItem', result);
									editFlightDetail(detailIndex, name, result.iata);
									clearFlightError(detailIndex, name);
									const value = returnAirportInfo(result.iata);
									setTripInputs({
										...tripInputs,
										[name]: returnAirportInfo(result.iata),
									});
									setSearchResults([]);
									handleHotel &&
										handleHotel({ [name]: result.iata, hotelItem });
								}}
							/>
						</Fragment>
					);
			  })
			: null;
	};

	useEffect(() => {
		if (localStorage.getItem(`focus${detailIndex}${name}`)) {
			inputRef.current.focus();
			localStorage.removeItem(`focus${detailIndex}${name}`);
		}
	}, []);

	return (
		<>
			<div className='flight-input'>
				<input
					ref={(el) => {
						inputRef.current = el;
					}}
					autoComplete='off'
					onBlur={setError}
					type='text'
					name={name}
					value={value}
					onChange={(e) => {
						e.preventDefault();
						handleChange(e);
						handleSearch(e);
					}}
				/>
				{value ? (
					<span
						onClick={() => {
							setTripInputs({
								...tripInputs,
								[name]: '',
							});
							localStorage.setItem(`focus${detailIndex}${name}`, true);
							editFlightDetail(detailIndex, name, '');
							clearFlight && clearFlight();
							setSearchResults([]);
						}}
						className='clear-flight-location'>
						+
					</span>
				) : null}
				{error ? <ErrMessage text={error} /> : null}
				<AutofillSection
					returnSearchResults={returnSearchResults}
					searchResults={searchResults}
					heightRefs={heightRefs}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	flightDetails: state.trip.flightDetails,
});

export default connect(mapStateToProps, {
	editFlightDetail,
	setFlightError,
	clearFlightError,
})(AirportInputUnlocked);
