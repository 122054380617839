import { combineReducers } from 'redux';
import trip from './trip';
import preferences from './preferences';
import redirect from './redirect';
import auth from './auth';
import errors from './errors';
import amadeus from './amadeus';
import alert from './alert';
import profile from './profile';
import itineraryModal from './itineraryModal';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
	blacklist: ['trip', 'profile', 'preferences'],
};

const rootReducer = combineReducers({
	trip,
	preferences,
	auth,
	redirect,
	errors,
	amadeus,
	alert,
	itineraryModal,
	profile,
});

export default persistReducer(persistConfig, rootReducer);
