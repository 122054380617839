import React from 'react';
import { useState } from 'react';
import SettingsSideNav from '../Settings/SideNav/SettingsSideNav';
import SettingsMain from '../Settings/SettingsMain';

const Settings = (props) => {
	const [currentWindow, setCurrentWindow] = useState('Password');

	return (
		<div className='settings'>
			<SettingsSideNav setCurrentWindow={setCurrentWindow} />

			<SettingsMain currentWindow={currentWindow} />
		</div>
	);
};

Settings.propTypes = {};

export default Settings;
