import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateContactInfo } from '../../redux/actions/auth';

const ContactInfo = ({ user }) => {
	const [formData, setFormData] = useState({
		email: '',
		phone: '',
	});

	useEffect(() => {
		setFormData({
			email: user.email,
			phoneNumber: user.phoneNumber,
		});
	}, [user.email, user.mail, user.phoneNumber]);
	return (
		<div className='payment-details__section'>
			<h3 className='payment-info__title'>Contact Info</h3>
			<p className='payment-info__text'>
				{`${user.firstName} ${user.lastName}` || 'N/A'}
			</p>
			<p className='payment-info__text'>{formData.email || 'N/A'}</p>
		</div>
	);
};

ContactInfo.propTypes = {
	user: PropTypes.object,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, { updateContactInfo })(ContactInfo);
