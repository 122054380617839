import React, { Fragment } from 'react';
import moment from 'moment';

const PartyReviewCard = ({ party, primary }) => {
	const { firstName, lastName, title, suffix, dob, gender } = party;
	return (
		<Fragment>
			<section className='party-review-card'>
				<h3>{primary ? 'Primary Traveler' : 'Companion Traveler'}</h3>
				<p>
					<b>Name: </b>
					{title && `${title} `}
					{firstName} {lastName} {suffix && ` ${suffix}`}{' '}
				</p>
				<p>
					<b>Date of Birth: </b>
					{moment(dob.toString().slice(0, 10)).format('LL')}
				</p>
				<p>
					<b>Gender: </b>
					{gender || 'female'}
				</p>
			</section>
		</Fragment>
	);
};

export default PartyReviewCard;
