import React from 'react';
import PropTypes from 'prop-types';
import SingleOptionButton from './SingleOptionButton';
import ToggleButton from './ToggleButton';
import { connect } from 'react-redux';
import { addSingleOptionPreference } from '../../redux/actions/preferences';
import { motion } from 'framer-motion';

const SingleOptionPreference = ({
	name,
	title,
	next,
	back,
	children,
	addSingleOptionPreference,
	preferences,
	direction,
	edit,
}) => {
	return (
		<>
			<motion.section
				key={name + title}
				initial={{ transform: `translateX(${150 * direction}%)` }}
				animate={{ transform: 'translateX(0%)' }}
				exit={{ transform: `translateX(${-150 * direction}%)` }}
				transition={{ duration: 0.4 }}>
				<h3 className='preference-title'>{title}</h3>
				<div className='preference-card'>
					{children &&
						children.map((child, index) => {
							let subtitle =
								name === 'fareType' ? (
									<div className='fare-type__sub'>
										<p className='sub-item'>Price: {child.price}</p>
										<p className='sub-item'>
											Seat Selection: {child.seatSeletion ? 'Yes' : 'No'}
										</p>
										<p className='sub-item'>
											Checked Bags: {child.checkedBags ? 'Yes' : 'No'}
										</p>
										<p className='sub-item'>
											Seat Details: {child.seatDetails}
										</p>
									</div>
								) : (
									child.subtitle
								);
							const selected =
								preferences[name] === child.title ||
								JSON.stringify(preferences[name]) ===
									JSON.stringify(child.value);
							return (
								<SingleOptionButton
									key={`${title}-${index}`}
									onClick={() => {
										addSingleOptionPreference(
											name,
											child.value >= 0 || child.value
												? child.value
												: child.title
										);
										next();
									}}
									title={child.title}
									subtitle={subtitle}
									cname={selected ? `newcss-selected-button` : null}
								/>
							);
						})}
				</div>
				{edit ? null : (
					<div className='button-section'>
						{back && <ToggleButton text='Back' buttonAction={back} />}
						{next && <ToggleButton text='Next' buttonAction={next} />}
					</div>
				)}
			</motion.section>
		</>
	);
};

SingleOptionPreference.propTypes = {
	addSingleOptionPreference: PropTypes.func.isRequired,
	preferences: PropTypes.object,
};

const mapStateToProps = (state) => ({
	preferences: state.preferences,
});

export default connect(mapStateToProps, { addSingleOptionPreference })(
	SingleOptionPreference
);
