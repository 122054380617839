import React from 'react';

const SettingsSideNavButton = ({ handleClick, value }) => {
	return (
		<>
			<button value={value} onClick={handleClick}>
				{value}
			</button>
		</>
	);
};

export default SettingsSideNavButton;
