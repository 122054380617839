import React from 'react';
import FlightOptions from './FlightOptions';
import HotelOptions from './HotelOptions';
import ActivityOptions from './ActivityOptions';
import TransportOptions from './TransportOptions';
import CustomItems from './CustomItems';

const ItemOptions = ({ selected, toggleModal, modalOpen }) => {
	const setOptions = () => {
		if (selected === 'Flights') {
			return <FlightOptions />;
		}
		if (selected === 'Hotels') {
			return <HotelOptions />;
		}
		if (selected === 'Activities') {
			return <ActivityOptions />;
		}
		if (selected === 'Transportation') {
			return <TransportOptions />;
		}
		if (selected === 'Add Your Own Item') {
			return <CustomItems />;
		}
	};

	const handleTitle = () => {
		if (!modalOpen && selected === 'Add Sticker') {
			return <h3 className='selected'>{selected}</h3>;
		} else {
			return modalOpen && selected && <h3 className='selected'>{selected}</h3>;
		}
	};

	return (
		<>
			<section className='newcss-item-options-container-container'>
				<section
					className={
						modalOpen
							? 'newcss-item-options-container'
							: 'newcss-item-options-hide'
					}>
					{handleTitle()}
					{modalOpen && setOptions()}
				</section>
				<button
					className='newcss-item-options-container-img'
					onClick={() => selected && toggleModal()}>
					{modalOpen ? '<' : '>'}
				</button>
			</section>
		</>
		// </Sticky>
	);
};

export default ItemOptions;
