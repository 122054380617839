import React from 'react';
import { NavLink } from 'react-router-dom';
import config from '../../config/config';
import buttonIcon from '../../images/noun-get-started.svg';

const AddNewTrip = (props) => {
	return (
		<>
			<NavLink
				className='homepage__subnav-button'
				to={config.SignedInLinks.createTrip}>
				Get Started
				<img src={buttonIcon} alt='' />
			</NavLink>
		</>
	);
};

export default AddNewTrip;
