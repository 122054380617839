import React, { useEffect, useState } from 'react';
import PreferenceKickOff from '../Preferences/PreferenceKickOff';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import requireAuth from '../hoc/AuthComponent';
import redirectHOC from '../hoc/RedirectHOC';
import CurrentPreference from '../Preferences/CurrentPreference';
import PreferenceProgressBar from '../Preferences/PreferenceProgressBar';
import { setRegisterFalse, verifyMe } from '../../redux/actions/auth';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { Redirect, useParams } from 'react-router-dom';
import {
	addPreferences,
	updatePreferences,
} from '../../redux/actions/preferences';
import ReactGA from 'react-ga';
import { set } from 'lodash';

const override = css`
	height: 8rem;
	width: 8rem;
	margin: 2rem;
	top: 50%;
	left: 50%;
`;

const Preferences = ({
	verificationWindow,
	verifyMe,
	preferences,
	setRegisterFalse,
	addPreferences,
	updatePreferences,
}) => {
	const [preferenceData, setPreferenceData] = useState({
		name: 'kickoff',
		index: 0,
	});
	const [verified, setVerified] = useState(false);
	const [loading, setLoading] = useState(false);

	const { verificationToken, userId } = useParams();

	const [submitSuccess, setSubmitSuccess] = useState(false);
	const verifyUser = async () => {
		const response = await verifyMe(verificationToken, userId);

		if (response) {
			setVerified(true);
		}
	};
	const submitPreferences = async () => {
		setLoading(true);
		if (!preferences._id) {
			const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
			ReactGA.initialize(TRACKING_ID);
			ReactGA.event({
				category: 'User_Action',
				action: 'add_preferences',
				label: 'user_signup',
			});

			const res = await addPreferences(preferences);
			if (res) setSubmitSuccess(true);
		} else {
			const res = await updatePreferences(preferences);
			if (res) setSubmitSuccess(true);
		}
	};

	const setCurrentPreference = (name, index) => {
		setPreferenceData({ ...preferenceData, index: null });
		setTimeout(() => {
			setPreferenceData({ ...preferenceData, name, index });
		}, 300);
	};
	const changePreferenceIndex = (num) => {
		const currentIndex = preferenceData.index;
		setPreferenceData({ ...preferenceData, index: null });

		setTimeout(() => {
			setPreferenceData({ ...preferenceData, index: currentIndex + num });
		}, 200);
	};

	useEffect(() => {
		if (
			verificationWindow &&
			window &&
			window.location &&
			window.location.pathname &&
			window.location.pathname.length
		) {
			if (window.location.pathname.slice(0, 14) === '/verify-email/') {
				verifyUser();
			}
		}
	}, []);

	if (submitSuccess) {
		setRegisterFalse();
		return <Redirect to='/itinerary' />;
	}

	return (
		<Fragment>
			{preferenceData.name === 'kickoff' ? (
				<PreferenceKickOff
					nextComponent={() => setCurrentPreference('flight', 0)}
				/>
			) : (
				<div className='newcss-preferences-container'>
					{loading ? (
						<FadeLoader
							css={override}
							height={'30%'}
							width={'7%'}
							margin={'2%'}
						/>
					) : (
						<>
							<PreferenceProgressBar
								preferenceData={preferenceData}
								setCurrentPreference={setCurrentPreference}
							/>
							<CurrentPreference
								submitPreferences={submitPreferences}
								changePreferenceIndex={changePreferenceIndex}
								preferenceData={preferenceData}
								setCurrentPreference={setCurrentPreference}
							/>
						</>
					)}
				</div>
			)}
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	redirect: state.auth.redirect,
	preferences: state.preferences,
});
export default connect(mapStateToProps, {
	verifyMe,
	setRegisterFalse,
	addPreferences,
	updatePreferences,
})(redirectHOC(requireAuth(Preferences)));
