import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PartyInfo from '../CheckoutNew/PartyInfo';
import CartDetails from '../CheckoutNew/CartDetails';
import PricingCard from '../landing/PricingCard';
import { getTrip, submitUpdate } from '../../redux/actions/trip';
import requireAuth from '../hoc/AuthComponent';
import { useParams } from 'react-router-dom';

const Checkout = ({ purchase, trip, submitUpdate, getTrip }) => {
	const { tripId } = useParams();
	const [stage, setStage] = useState(0);
	const [partyReview, setPartyReview] = useState(false);

	const moveStage = (num) => {
		setStage(stage + num);
	};

	useEffect(() => {
		if (purchase && purchase.booking && purchase.booking.authorizedOn) {
			setStage(1);
			setPartyReview(true);
		}
	}, [purchase]);

	useEffect(() => {
		getTrip(tripId);
	}, []);

	const stages = ['BOOKING FEE', 'TRAVELER INFORMATION', 'CHECKOUT'];

	return (
		<div className='checkout-container'>
			<ProgressBar currentStage={stage} setStage={setStage} stages={stages} />
			{stage === 0 && (
				<PricingCard
					bookingCheckout={true}
					moveStage={async () => {
						const submitted = await submitUpdate({
							...trip,
							purchase: {
								...purchase,
								tripBooking: { paid: true, paymentDate: new Date() },
							},
						});
						if (submitted) {
							setStage(1);
						}
					}}
					stage={stage}
					tripForm={true}
				/>
			)}
			{stage === 1 && (
				<PartyInfo
					moveStage={moveStage}
					stage={stage}
					partyReview={partyReview}
					setPartyReview={setPartyReview}
				/>
			)}
			{stage === 2 && <CartDetails moveStage={moveStage} stage={stage} />}
			{/* {stage === 3 && <PaymentDetails moveStage={moveStage} stage={stage} />} */}
		</div>
	);
};
Checkout.propTypes = {
	cart: PropTypes.object,
	trip: PropTypes.object,
	purchase: PropTypes.object,
};

const mapStateToProps = (state) => ({
	_id: state.trip.id,
	trip: state.trip,
	purchase: state.trip.purchase,
	cart: state.trip.cart,
});

export default connect(mapStateToProps, { submitUpdate, getTrip })(
	requireAuth(Checkout)
);
