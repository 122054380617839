import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import SegmentSection from '../../selectedModal/SegmentSection';
import datetimeDifference from 'datetime-difference';
import FlightSubInfo from '../FlightSubInfo';
import { handleTime } from '../../../../Utilities/helperFunctions';
import { connect } from 'react-redux';
const timezones = require('../../../../Utilities/timezone-codes.json');

const ModalFlightDetails = ({
	flight_details,
	returnNewDate,
	selectedItem,
	leg_index,
}) => {
	const returnSameTimezone = (departure, arrival) => {
		const startDate = moment(new Date(departure.date)).format().slice(0, 10);
		const endDate = moment(new Date(arrival.date)).format().slice(0, 10);
		const startTimeZone = timezones[departure.location];
		const endTimeZone = timezones[arrival.location];

		// const departureDate = returnNewDate(departure.date, departure.time);
		let departureTime = moment.tz(
			`${startDate} ${departure.time}`,
			startTimeZone
		);
		let arrivalTime = moment.tz(`${endDate} ${arrival.time}`, endTimeZone);
		let expectedDeparture = departureTime.clone().tz(endTimeZone).format();

		let results = returnTravelTime(
			datetimeDifference(new Date(expectedDeparture), new Date(arrivalTime))
		);
		return results;
	};
	const returnTravelTime = ({ days, hours, minutes }) => {
		let text = '';
		if (days) {
			text = `${days}d`;
		}
		if (hours) {
			text = text ? `${text} ${hours}h` : `${hours}h`;
		}
		if (minutes) {
			text = text ? `${text} ${minutes}m` : `${minutes}m`;
		}
		return text;
	};

	const handleLayover = (start, end, location) => {
		const travelTimeString = returnTravelTime(
			datetimeDifference(
				returnNewDate(start.date, start.time),
				returnNewDate(end.date, end.time)
			)
		);
		const layoverLocation = `${start.airport_info['Airport Name']} (${start.location})`;
		return (
			<SegmentSection
				time={`${travelTimeString} ${layoverLocation}`}
				location={location}
				layover={true}
			/>
		);
	};

	const returnDetails = () => {
		return flight_details.map((segment, index) => {
			return (
				<Fragment key={`modal-flight-detail-${index}`}>
					<div className='flight__info-section__container'>
						<SegmentSection
							time={`${handleTime(segment.departure.time)} - ${
								segment.departure.airport_info['Airport Name']
							} (${segment.departure.location})`}
							location={segment.departure.iataCode}
						/>

						<SegmentSection
							time={`${handleTime(segment.arrival.time)} - ${
								segment.arrival.airport_info['Airport Name']
							} (${segment.arrival.location})`}
							location={segment.arrival.iataCode}
						/>
					</div>
					<FlightSubInfo
						time={returnSameTimezone(segment.departure, segment.arrival)}
						bookingClass={
							selectedItem &&
							selectedItem.data &&
							selectedItem.data.recommendation &&
							selectedItem.data.recommendation.pricing_info &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing
								.length &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing[0]
								.fareDetails &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing[0]
								.fareDetails.length &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing[0]
								.fareDetails[leg_index] &&
							selectedItem.data.recommendation.pricing_info.passenger_pricing[0]
								.fareDetails[leg_index].majCabin
								? selectedItem.data.recommendation.pricing_info
										.passenger_pricing[0].fareDetails[leg_index].majCabin
								: null
						}
					/>
					{index < flight_details.length - 1
						? handleLayover(
								segment.arrival,
								flight_details[index + 1].departure,
								segment.arrival.iataCode
						  )
						: null}
				</Fragment>
			);
		});
	};

	return <>{returnDetails()}</>;
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, null)(ModalFlightDetails);
