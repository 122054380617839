import React, { Fragment } from 'react';
import PricingCard from './PricingCard';
import SupportSection from './SupportSection';

const ServiceSection = () => {
	return (
		<Fragment>
			<section className='service-section'>
				<SupportSection />
				<PricingCard landing={true} />
			</section>
		</Fragment>
	);
};

export default ServiceSection;
