import React, { useState } from 'react';
import { post } from '../../Utilities/api';
import LoadingOverlay from '../Layout/LoadingOverlay';
import * as emailValidator from 'email-validator';
import AuthFooter from './AuthFooter';
import { useEffect } from 'react';
import FormInput from './FormInput';

const ForgotPassword = ({ setCurrentForm, currentForm, close }) => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const forgotPasswordRequest = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setLoading(true);
		try {
			const validEmail = emailValidator.validate(email);
			let res;
			if (validEmail) {
				res = await post('/api/v1/auth/forgot-password', {
					email,
				}).catch((err) => {
					throw {
						message: err.message,
					};
				});
				if (res.data.success) {
					setSuccess(true);
					setLoading(false);
				}
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	useEffect(() => {
		if (submitted && !loading) {
			setTimeout(() => close(), 3000);
		}
	});

	return (
		<>
			<div className='popup__header'>
				<h3 className='popup__header--text'>Forgot Password</h3>
				<button
					className='popup__close'
					onClick={(e) => {
						e.preventDefault();
						setCurrentForm('login');
						close();
					}}>
					<i className='fa fa-times'></i>
				</button>
			</div>

			<div className='auth-container'>
				{submitted ? (
					<LoadingOverlay loading={loading} success={success} />
				) : null}
				<form
					className='update-password
			'
					onSubmit={forgotPasswordRequest}>
					<FormInput
						cName='input-section'
						label='Email'
						handleChange={(e) => setEmail(e.target.value)}
						name='email'
						type='email'
					/>

					<AuthFooter
						setCurrentForm={setCurrentForm}
						currentForm={currentForm}
					/>
				</form>
			</div>
		</>
	);
};

export default ForgotPassword;
