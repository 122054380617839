import React, { Fragment } from 'react';
import DateButton from './DateButton';

const TimeButtonSection = ({ times, selectedTime, setSelectedTime }) => {
	const mapButtons = () => {
		return times && times.length
			? times.map((time, index) => (
					<DateButton
						key={`time-${index}`}
						date={time}
						selected={time === selectedTime}
						setSelected={setSelectedTime}
					/>
			  ))
			: null;
	};

	return (
		<Fragment>
			<label htmlFor='travelerAmount'>Choose a time</label>
			<div className='date-button-section'>
				<main>{mapButtons()}</main>
			</div>
		</Fragment>
	);
};

export default TimeButtonSection;
