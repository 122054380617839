import React, { Component } from 'react';
import CityStateSelector from './CityStateSelector';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Activity from '../Activities/Activity';
import CarRentalOption from './CarRentalOption';
import {
	statelessCarSearch,
	updateAmadeusItems,
	setCars,
} from '../../redux/actions/amadeus';
import config from '../../config/config';
import { FadeLoader } from 'react-spinners';
import moment from 'moment';

import { css } from '@emotion/core';

const airports = require('../../Utilities/new_airport_codes.json');

const override = css`
	display: block;
	margin: 3rem auto;
`;

class TransportOptions extends Component {
	state = {
		showCities: false,
		city: 'All Cities',
		groups: {},
		loading: true,
	};

	returnTransport = () => {
		const groupAmount =
			this.props.adults + this.props.children + this.props.infants;
		return this.props.transport.length
			? this.props.transport.map((data) => {
					return (
						<div
							key={data._id}
							className={`newcss-item-preview${
								this.props.selectedItem &&
								this.props.selectedItem._id === data._id
									? ' selected-item'
									: ''
							}`}>
							<Activity
								// toggleShowModal={this.props.toggleShowModal}

								data={data}
								groupAmount={groupAmount}
							/>
						</div>
					);
			  })
			: null;
	};

	checkLastCarSearch = async () => {
		let prevMount = localStorage.getItem(
			`lastAmadeusCarSearch${this.props.trip_id}`
		);
		let cache = localStorage.getItem(`carSearchItems${this.props.trip_id}`);
		cache = cache !== 'undefined' && JSON.parse(cache);
		if (prevMount) {
			const now = moment(new Date());

			prevMount = prevMount.split('T');
			let prevMountHours = prevMount[1].slice(0, 2);
			let prevMountMinutes = prevMount[1].slice(3, 5);

			prevMount = moment(new Date(prevMount));

			// prevMount = moment(new Date(JSON.parse(prevMount)));
			const minutes = now.diff(prevMount, 'minutes');

			// const { minutes, hours, days, months, years } = datetimeDifference(
			// 	now,
			// 	prevMount
			// );

			const adminChoicesByLocation = {};
			this.props.itineraryItems.amadeusCars.forEach((car) => {
				let { pickupDropoffLocations, location } = car.data;
				const pickupLocationObject = pickupDropoffLocations.find(
					(item) => item.locationType === 'PUP'
				);
				const pickupLocation =
					location[pickupLocationObject.locationDescription.name];

				const { airportTerminalInfo } = pickupLocation;

				const { airportCode } = airportTerminalInfo;
				if (adminChoicesByLocation[airportCode]) {
					adminChoicesByLocation[airportCode].push();
				} else {
					adminChoicesByLocation[airportCode] = [car];
				}
			});

			if (minutes >= config.cacheTime) {
				await this.props.statelessCarSearch(this.props.trip_id);
				this.setState({ loading: false });
			} else {
				this.props.setCars({ bookingOptions: cache.bookingOptions }, 'cars');
				this.setState({ loading: false });
			}
		} else {
			await this.props.statelessCarSearch(this.props.trip_id);
			this.setState({ loading: false });
		}
	};

	setSelectedCity = (city) => {
		this.setState({ city });
	};

	returnAmadeusCars = (currentGroup) => {
		return currentGroup && currentGroup.length
			? currentGroup.map((rate, index) => {
					const location =
						rate && rate.data && rate.data.location
							? rate.data.location
							: this.props.companies[rate.data.carCompanyData.companyCode]
									.location;

					rate = rate && rate.data && rate.data.rate ? rate.data.rate : rate;
					return (
						<CarRentalOption
							key={`${JSON.stringify(rate)}`}
							disableItems={this.props.disableItems}
							detail={{ ...rate, data: { ...rate.data, location } }}
							index={index}
						/>
					);
			  })
			: null;
	};
	componentDidMount() {
		this.checkLastCarSearch();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.bookingOptions !== this.props.bookingOptions) {
			const rates = Object.values(this.props.bookingOptions).flat();
			let groups = {
				...this.state.groups,
				'All Cities': {
					...this.state.groups['All Cities'],
					amadeusCar: rates,
				},
			};
			if (this.props.bookingOptions) {
				for (let key in this.props.bookingOptions) {
					let newKey = airports.find((airport) => {
						return airport.iata.toLowerCase() === key.toLowerCase();
					});

					newKey = newKey ? newKey.locationServed.split(', ') : null;
					newKey = newKey && newKey[0];
					groups = {
						...groups,
						[newKey]: {
							...groups[newKey],
							amadeusCar: this.props.bookingOptions[key],
						},
					};
				}
			}
			this.setState({ groups, loading: false });
		}
	}

	render() {
		let { groups, city } = this.state;
		var arrayBoolean =
			this.props.transport.length || this.props.amadeusCars.length;
		return this.state.loading ? (
			<FadeLoader css={override} />
		) : (
			<>
				{arrayBoolean ? (
					<CityStateSelector
						cities={groups && Object.keys(groups)}
						selectedCity={city}
						setSelectedCity={this.setSelectedCity}
					/>
				) : null}

				<div className='newcss-itinerary'>
					{this.returnTransport()}{' '}
					{groups[city] && groups[city].amadeusCar
						? this.returnAmadeusCars(groups[city].amadeusCar)
						: null}
				</div>
			</>
		);
	}
}

TransportOptions.propTypes = {
	transport: PropTypes.array,
	amadeusCars: PropTypes.array,
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
};

const mapStateToProps = (state) => ({
	transport: state.trip.itineraryItems.transport,
	amadeusCars: state.trip.itineraryItems.amadeusCars,
	bookingOptions: state.amadeus.cars.bookingOptions,
	disableItems: state.amadeus.cars.disableItems,
	companies: state.amadeus.cars.companies,
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
	selectedItem: state.itineraryModal.selectedItem,
	trip_id: state.trip._id,

	authorized: state.trip.authorized,
	itineraryItems: state.trip.itineraryItems,

	paid: state.trip.paid,
});

export default connect(mapStateToProps, {
	statelessCarSearch,
	updateAmadeusItems,
	setCars,
})(TransportOptions);
