import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class Footer extends Component {
	static contextType = null;

	signOut = () => {
		this.context.signOut(this.context.token);
	};

	render() {
		return (
			<div className='footer'>
				<ul className='nav-links-footer'>
					<li>
						<a href='https://www.instagram.com/travelwithrove/' target='_blank'>
							<i className='fa fa-brands fa-instagram'></i>
						</a>
					</li>
					<li>
						<a href='https://www.facebook.com/TravelwithRove' target='_blank'>
							<i className='fa fa-brands fa-facebook'></i>
						</a>
					</li>
				</ul>
				<p>&copy;{new Date().getFullYear()} ROVE</p>
			</div>
		);
	}
}

export default Footer;
