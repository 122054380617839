import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Flight from '../Flights/Flight';
import PropTypes from 'prop-types';
import { flightSearch, updateAmadeusItems } from '../../redux/actions/amadeus';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/core';
import moment from 'moment';
import config from '../../config/config';

const override = css`
	display: block;
	margin: 5rem auto;
`;

const FlightOptions = ({
	flights,
	trip_id,
	flightSearch,
	updateAmadeusItems,
	authorized,
	itineraryItems,
	paid,
}) => {
	const [loading, setLoading] = useState(true);
	const loadFlights = async () => {
		const res = await flightSearch(trip_id, flights);
		if (res) setLoading(false);
	};

	const returnLoader = () => {
		return (
			// <div className='newcss-sweet-loading newcss-80vh'>
			<FadeLoader css={override} size={150} color={'black'} loading={true} />
			// </div>
		);
	};

	const returnFlights = () => {
		return flights && flights.length
			? flights.map((flight) => {
					return (
						<Flight
							cname={'itinerary__flight-card'}
							key={flight._id}
							id={flight._id}
							flight={flight}
						/>
					);
			  })
			: null;
	};

	useEffect(() => {
		let prevMount = localStorage.getItem(`flightSearchTime${trip_id}`);
		let cache = localStorage.getItem(`flightSearchItems${trip_id}`);
		cache = cache !== 'undefined' && JSON.parse(cache);
		if (prevMount) {
			const now = moment(new Date());

			prevMount = prevMount.split('T');
			let prevMountHours = prevMount[1].slice(0, 2);
			let prevMountMinutes = prevMount[1].slice(3, 5);

			prevMount = moment(new Date(prevMount));

			// prevMount = moment(new Date(JSON.parse(prevMount)));
			const minutes = now.diff(prevMount, 'minutes');

			// const { minutes, hours, days, months, years } = datetimeDifference(
			// 	now,
			// 	prevMount
			// );

			if (authorized.flights.length || paid.flights.length) {
				updateAmadeusItems(itineraryItems.flights, 'flights');
				setLoading(false);
			} else if (minutes >= config.cacheTime) {
				loadFlights();
			} else {
				updateAmadeusItems(cache, 'flights');
				setLoading(false);
			}
		} else {
			if (authorized.flights.length || paid.flights.length) {
				updateAmadeusItems(itineraryItems.flights, 'flights');
				setLoading(false);
			} else {
				loadFlights();
			}
		}
	}, []);

	return (
		<>
			{!loading ? (
				<div className='newcss-itinerary'>{returnFlights()}</div>
			) : (
				returnLoader()
			)}
		</>
	);
};

FlightOptions.propTypes = {
	flights: PropTypes.array,
	selectedItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
	flights: state.amadeus.flights.bookingOptions,
	authorized: state.trip.authorized,
	itineraryItems: state.trip.itineraryItems,
	paid: state.trip.paid,
	trip_id: state.trip._id,
});
export default connect(mapStateToProps, { flightSearch, updateAmadeusItems })(
	FlightOptions
);
