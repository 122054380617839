import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Agreement from './Agreement';
import { returnItemFields } from '../../Utilities/helperFunctions';
import CurrentTotal from './CurrentTotal';
import TotalPaid from './TotalPaid';
import FlightToggler from '../CreateTrip/flights/FlightToggler';
import ReactGA from 'react-ga';

const CartTotal = ({
	cart,
	paid,
	handleClick,
	adults,
	children,
	infants,
	curation,
	purchase,
	user,
	iframeSubmitSuccess,
}) => {
	const [subTotal, setSubTotal] = useState(null);
	const [totalPer, setTotalPer] = useState(null);
	const [total, setTotal] = useState(null);
	const [agreementOpen, setAgreementOpen] = useState(false);
	const [mobileCurrent, setMobileCurrent] = useState('current-total');
	const [isMobile, setIsMobile] = useState(false);

	const [totalPaid, setTotalPaid] = useState(null);

	const ref = useRef();

	const setAgreement = (val) => {
		setAgreementOpen(val);
	};

	const curationInfoList = (
		<>
			<ul className='info-list'>
				<li>
					$75 curation fee launches a travel advisor to create your customized
					trip based on your preferences and trip profile
				</li>
				<li>
					You will receive hand selected hotels, flights, activities, and
					transportation options based on your preferences
				</li>
				<li>
					Access to our user friendly calendar allows you to view and plan for
					an amazing adventure by only selecting the items of interest
				</li>
			</ul>
		</>
	);

	const bookingInfoList = (
		<>
			<ul className='info-list'>
				<li>
					$75 booking fee supports securing all items approved and authorized
					through the check out process.
				</li>
				<li>Support in coordinating and confirming with various vendors</li>
				<li>
					Liaison with questions and messages with various contacts domestically
					and internationally
				</li>
			</ul>
		</>
	);

	const totalInfoList = (
		<>
			<ul className='info-list'>
				<li>
					Due to fluctuations in currency conversions, the prices shown for
					activities and transportation may be rounded to the nearest dollar.
					You will be charged the exact amount that reflects the true price,
					which may deviate lightly from the value shown here.
				</li>
			</ul>
		</>
	);

	useEffect(() => {
		const checkMobile = () => {
			if (window.innerWidth <= 555) {
				setIsMobile(true);
			}
			if (window.innerWidth > 555) {
				setIsMobile(false);
			}
		};
		checkMobile();
		window.addEventListener('resize', checkMobile);
		return () => window.removeEventListener('resize', checkMobile);
	}, []);

	useEffect(() => {
		if (!curation) {
			const { lodging, flights, activities, transport, amadeusCars } = cart;

			let subResults = 0;
			let totalResults = 0;

			lodging.forEach((item) => {
				const { data } = item;
				const { RoomRates } = data;
				const { Total } = returnItemFields(RoomRates, ['Total']);
				const { AmountAfterTax } = returnItemFields(Total, ['AmountAfterTax']);
				const total = AmountAfterTax ? parseFloat(AmountAfterTax) : 0;
				totalResults += total;
			});
			flights.forEach((flight) => {
				let { total_amount } = returnItemFields(flight, ['total_amount']);
				total_amount = parseFloat(total_amount);
				totalResults += total_amount;
			});

			amadeusCars.forEach((flight) => {
				const { rateDetailsInfo } = flight.data;
				let car_total = rateDetailsInfo.find((detail) => {
					return detail.tariffInfo.rateType === '906';
				}).tariffInfo.rateAmount;

				car_total = car_total && parseFloat(car_total);
				totalResults += car_total;
			});

			activities.forEach((activity) => {
				const subTimesUnits =
					parseFloat(activity.price.amount) * activity.units;
				const totalTimesUnits =
					parseFloat(activity.price.amount) * activity.units;
				subResults += subTimesUnits;
				totalResults += totalTimesUnits;
			});
			transport.forEach((item) => {
				const subTimesUnits = parseFloat(item.price.amount) * item.units;
				const totalTimesUnits = parseFloat(item.price.amount) * item.units;
				subResults += subTimesUnits;
				totalResults += totalTimesUnits;
			});

			let totalPaidResults = 0;
			paid.lodging.forEach((item) => {
				const { data } = item;
				const { RoomRates } = data;
				const { Total } = returnItemFields(RoomRates, ['Total']);
				const { AmountAfterTax } = returnItemFields(Total, [
					'AmountAfterTax',
					'AmountBeforeTax',
					'CurrencyCode',
				]);
				const total = AmountAfterTax ? parseFloat(AmountAfterTax) : 0;
				totalPaidResults += total;
			});
			paid.flights.forEach((flight) => {
				const { total_amount } = returnItemFields(flight, ['total_amount']);
				const totalTimesUnits = parseFloat(total_amount);
				totalPaidResults += totalTimesUnits;
			});

			paid.activities.forEach((activity) => {
				const amount = activity && activity.price && activity.price.amount;
				const amountIsString = amount && typeof amount === 'string';
				const subTimesUnits = amountIsString
					? parseFloat(amount) * activity.units
					: 0;
				const totalTimesUnits = amountIsString
					? parseFloat(amount) * activity.units
					: 0;
				subResults += subTimesUnits;
				totalPaidResults += totalTimesUnits;
			});
			paid.transport.forEach((item) => {
				const amount = item && item.price && item.price.amount;
				const amountIsString = amount && typeof amount === 'string';
				const subTimesUnits = amountIsString
					? parseFloat(amount) * item.units
					: 0;
				const totalTimesUnits = amountIsString
					? parseFloat(amount) * item.units
					: 0;
				subResults += subTimesUnits;
				totalPaidResults += totalTimesUnits;
			});
			const authBookingFee =
				!purchase.booking ||
				(purchase.booking &&
					!purchase.booking.authorizedOn &&
					!purchase.booking.paidOn &&
					!curation)
					? 75
					: 0;
			const paidBookingFee =
				purchase.booking && purchase.booking.paidOn ? 75 : 0;
			const authCurationFee =
				purchase.curation &&
				purchase.curation.authorizedOn &&
				!purchase.curation.paidOn &&
				curation
					? 75
					: 0;
			const paidCurationFee =
				purchase.curation && purchase.curation.paidOn ? 75 : 0;
			setTotalPaid(totalPaidResults + paidBookingFee + paidCurationFee);
			setSubTotal(subResults);
			setTotal(totalResults + authBookingFee + authCurationFee);
			setTotalPer(
				(totalResults + authBookingFee + authCurationFee) /
					(adults + children + infants) || 0
			);
		} else {
			setTotal(75);
		}
	}, [cart]);

	return (
		<div ref={ref} className={`right total`}>
			{agreementOpen && (
				<Agreement
					closeAgreement={() => setAgreement(false)}
					handleAgreementClick={handleClick}
					firstName={user.firstName}
					lastName={user.lastName}
				/>
			)}

			{isMobile && (
				<FlightToggler
					cName='cart'
					leftTitle='My Cart'
					rightTitle='Entire Trip Costs'
					leftValue='current-total'
					rightValue='total-paid'
					leftSelected={mobileCurrent === 'current-total'}
					rightSelected={mobileCurrent === 'total-paid'}
					leftClick={() => setMobileCurrent('current-total')}
					rightClick={() => setMobileCurrent('total-paid')}
				/>
			)}
			<CurrentTotal
				total={total}
				setAgreementOpen={setAgreementOpen}
				isMobile={isMobile}
				mobileCurrentValue='current-total'
				mobileCurrent={mobileCurrent}
				text={curation ? 'Curation Fee' : 'Estimated Total'}
				headerText='My Cart'
				curation={curation}
				purchase={purchase}
				hoverItem={curation ? curationInfoList : totalInfoList}
				iframeSubmitSuccess={iframeSubmitSuccess}
				totalPer={totalPer}
				bookingInfoList={bookingInfoList}
			/>

			<TotalPaid
				isMobile={isMobile}
				mobileCurrentValue='total-paid'
				mobileCurrent={mobileCurrent}
				headerTitle='Entire Trip Costs'
				totalPaid={totalPaid}
				perPersonPrice={totalPaid / (adults + children + infants)}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	cart: state.trip.cart,
	paid: state.trip.paid,
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
	itineraryItems: state.trip.itineraryItems,
	user: state.auth.user,
});
export default connect(mapStateToProps, null)(CartTotal);
