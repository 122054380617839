import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { addDate, addEmptyFlight } from '../../../redux/actions/trip';
import MultiFlightSection from './MultiFlightSection';
import { setFlightError } from '../../../redux/actions/errors';
import { returnTrueDate } from '../../../Utilities/helperFunctions';
import { AnimatePresence } from 'framer-motion';

const MultiCityTrip = ({
	flightDetails,
	loading,
	addEmptyFlight,
	flightErrors,
	setFlightError,
}) => {
	const handleDate = (date) => {
		if (date instanceof Date) {
			return date.toISOString().slice(0, 10);
		}
		if (date.length === 10) {
			return date.slice(0, 10);
		} else return date;
	};

	// useEffect(() => {
	// 	if (flightDetails.length === 1) {
	// 		let newDate = flightDetails[0].departureDate;

	// 		newDate = returnTrueDate(
	// 			typeof newDate === 'string'
	// 				? newDate.slice(0, 10)
	// 				: newDate.toISOString().slice(0, 10)
	// 		);
	// 		newDate.setDate(newDate.getDate() + 1);
	// 		addEmptyFlight('', '', newDate);
	// 	}
	// }, [flightDetails.length]);

	const mapFlights = () => {
		let sortedFlightDetails = flightDetails.sort((a, b) => {
			if (a.index < b.index) return -1;
			else return 1;
		});

		return sortedFlightDetails.length >= 3
			? sortedFlightDetails.map((flight) => {
					let index = flight.index;
					return (
						<Fragment key={`flightDetailIndex-${index}`}>
							<MultiFlightSection
								flightDetailKey={`flightDetailIndex-${index}`}
								id={flight.id}
								index={index}
								errors={
									flightErrors && flightErrors.length && flightErrors[index]
								}
								endIndex={sortedFlightDetails.length - 1}
								flight={flight}
								departure={flight.departure}
								destination={flight.destination}
								departureDate={handleDate(flight.departureDate)}
								onAddClick={addEmptyFlight}
								newFlight={true}
								sortedFlightDetails={sortedFlightDetails}
							/>
						</Fragment>
					);
			  })
			: sortedFlightDetails.map((flight) => {
					let index = flight.index;
					return (
						<Fragment key={`flightDetailIndex-${index}`}>
							<MultiFlightSection
								flightDetailKey={`flightDetailIndex-${index}`}
								id={flight.id}
								index={index}
								errors={
									flightErrors && flightErrors.length
										? flightErrors[index]
										: null
								}
								endIndex={sortedFlightDetails.length - 1}
								flight={flight}
								departure={flight.departure}
								destination={flight.destination}
								departureDate={handleDate(flight.departureDate)}
								onAddClick={addEmptyFlight}
								newFlight={
									index < sortedFlightDetails.length - 1 ? true : false
								}
								sortedFlightDetails={sortedFlightDetails}
							/>
						</Fragment>
					);
			  });
	};

	return (
		<Fragment>
			<AnimatePresence>
				{flightDetails && flightDetails.length && !loading
					? mapFlights()
					: null}
			</AnimatePresence>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	flightDetails: state.trip.flightDetails,
	returnDate: state.trip.returnDate,
	flightErrors: state.errors.flightErrors,
	returnDateError: state.errors.returnDateError,
});

export default connect(mapStateToProps, {
	addDate,
	addEmptyFlight,
	addDate,
	setFlightError,
})(MultiCityTrip);
