import { get } from '../../Utilities/api';

export const getUserProfile = (id) => async (dispatch) => {
	try {
		const res = await get(`/api/v1/admin/fetch-user/${id}`, {}, true);
		if (res.status === 200) {
			dispatch(setUserProfile(res.data.data));
		}
	} catch (error) {
		console.log(error);
	}
};

export const setUserProfile = (profile) => (dispatch) => {
	dispatch({
		type: 'SET_USER_PROFILE',
		payload: profile,
	});
};

export const clearProfile = () => (dispatch) => {
	dispatch({ type: 'CLEAR_PROFILE' });
};
