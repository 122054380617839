const hotel_city_codes = require('./new_city_codes.json');

export const returnCitySearch = (searchInput) => {
	const split = searchInput.split(' ');

	const filteredCities = hotel_city_codes.filter((item) => {
		const { cityName, countryName } = item;

		return cityName.includes(split[0]) || countryName.includes(split[0]);
	});
	return filteredCities;
};

export const findCity = (object) => {
	let found = hotel_city_codes.find((item) => {
		return (
			item['cityName'] === object['cityName'].toLowerCase() &&
			item['countryName'].includes(object['countryName'].toLowerCase()) &&
			item['cityCode'] === object.cityCode
		);
	});
	found =
		found ||
		hotel_city_codes.find((item) => {
			return (
				item['countryName'].includes(object['countryName'].toLowerCase()) &&
				item['cityCode'] === object.iata
			);
		});
	return found
		? {
				cityCode: found['cityCode'],
				countryCode: found['countryCode'],
				cityName: found['cityName'],
				stateCode: found['stateCode'],
		  }
		: null;
};
