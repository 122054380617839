import React from 'react';
import PropTypes from 'prop-types';
import PartyReviewCard from './PartyReviewCard';
import { connect } from 'react-redux';

const PartyReview = ({ secondaryParty, user }) => {
	const returnSecondaryParties = () => {
		return secondaryParty && secondaryParty.length
			? secondaryParty.map((party) => {
					return <PartyReviewCard key={party._id} party={party} />;
			  })
			: null;
	};
	return (
		<div>
			{' '}
			<PartyReviewCard party={user} primary={true} />
			{returnSecondaryParties()}
		</div>
	);
};

PartyReview.propTypes = {
	user: PropTypes.object,
};
const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, null)(PartyReview);
