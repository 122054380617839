import datetimeDifference from 'datetime-difference';
import moment from 'moment';
import {
	accrisBodyType,
	accrisCategory,
	accrisDriveType,
	accrisFuelAir,
	bootSizeApprox,
} from './cars';
import { toDollarStyle } from './helperFunctions';

const handleCarDate = (item) => {
	let { day, hour, minutes, month, year } = item;
	day = parseInt(day);
	month = parseInt(month) - 1;
	minutes = parseInt(minutes);
	year = parseInt(year);
	hour = parseInt(hour);
	const date = new Date(year, month, day, hour, minutes);

	return moment(date).format('LLLL');
};

const handleMilagePrice = (rateDetailsInfo) => {
	const foundAssociatedCharges =
		rateDetailsInfo &&
		rateDetailsInfo.length &&
		rateDetailsInfo.find((item) => item.associatedCharges);

	let unlimitedMileage = foundAssociatedCharges.associatedCharges.find(
		(item) => item.chargeType === '8' && item.chargePeriodTypeCode === `004`
	);
	return unlimitedMileage ? 'Unlimited Miles' : null;
};

const handleAccris = (vehicleTypeInfo) => {
	let { vehicleCharacteristic, carModel } = vehicleTypeInfo;
	let { vehicleRentalPrefType } = vehicleCharacteristic;
	const category = accrisCategory[vehicleRentalPrefType[0]];
	const type = accrisBodyType[vehicleRentalPrefType[1]];
	const transmission = accrisDriveType[vehicleRentalPrefType[2]];
	const feulAir = accrisFuelAir[vehicleRentalPrefType[3]];
	return {
		category,
		type,
		transmission,
		feulAir,
		carModel,
	};
};

const handlePickupDropoff = ({
	pickupDropoffLocations,
	pickupDropoffTime,
	location,
}) => {
	const pickupLocationObject = pickupDropoffLocations.find(
		(item) => item.locationType === 'PUP'
	);

	const pickupLocation =
		location[pickupLocationObject.locationDescription.name];

	const locationName = pickupLocation.address.addressDetails.line1;

	const { airportTerminalInfo } = pickupLocation;

	const { airportCode } = airportTerminalInfo;

	const terminalName =
		airportTerminalInfo &&
		airportTerminalInfo.terminalInformation &&
		airportTerminalInfo.terminalInformation.name
			? airportTerminalInfo.terminalInformation.name
			: null;

	const terminalString = terminalName
		? terminalName === 'ALL'
			? 'All Terminals'
			: `Terminal ${terminalName}`
		: null;

	return {
		locationName,
		airportCode,
		terminalString,
		pickupTime: handleCarDate(pickupDropoffTime.beginDateTime),
		dropoffTime: handleCarDate(pickupDropoffTime.endDateTime),
	};
};

const handleRentalPrice = ({ beginDateTime, endDateTime, rateDetailsInfo }) => {
	const beginYear = parseInt(beginDateTime.year);
	const beginMonth = parseInt(beginDateTime.month) - 1;
	const beginDay = parseInt(beginDateTime.day);
	const endYear = parseInt(endDateTime.year);
	const endMonth = parseInt(endDateTime.month) - 1;
	const endDay = parseInt(endDateTime.day);

	const beginDate = new Date(beginYear, beginMonth, beginDay);

	const endDate = new Date(endYear, endMonth, endDay);

	const difference = datetimeDifference(beginDate, endDate);

	const estimatedTotal = rateDetailsInfo.find(
		(item) => item.tariffInfo.rateType === '906'
	);

	const perDay =
		parseFloat(estimatedTotal.tariffInfo.rateAmount) / difference.days;

	return {
		perDay: `${toDollarStyle(
			perDay,
			estimatedTotal.tariffInfo.rateCurrency
		)}/day`,
		total: estimatedTotal.tariffInfo.rateAmount,
	};
};

export const sanitizeCarData = (data) => {
	let {
		rateDetailsInfo,
		vehicleTypeInfo,
		pickupDropoffLocations,
		location,
		pickupDropoffTime,
		sizedPictures,
	} = data;

	const miles = handleMilagePrice(rateDetailsInfo);
	const { category, type, transmission, carModel } =
		handleAccris(vehicleTypeInfo);
	const { locationName, airportCode, terminalString, pickupTime, dropoffTime } =
		handlePickupDropoff({
			pickupDropoffLocations,
			location,
			pickupDropoffTime,
		});

	sizedPictures = sizedPictures ? sizedPictures[0] : null;

	let vehicleInfo = sizedPictures
		? sizedPictures.vehicleInformation.vehicleInfo
		: null;

	let bootSize =
		sizedPictures &&
		sizedPictures.vehicleInformation &&
		sizedPictures.vehicleInformation.nonNumericalAttributes &&
		sizedPictures.vehicleInformation.nonNumericalAttributes.find(
			(item) => item.attributeType === 'BS'
		);

	const baggageAmount = bootSize
		? bootSizeApprox[bootSize.attributeDescription]
		: null;

	let capacity = vehicleInfo
		? vehicleInfo.find(({ qualifier }) => {
				return qualifier === 'PAX';
		  })
		: null;

	capacity = capacity && capacity.value;

	let doors = vehicleInfo
		? vehicleInfo.find(({ qualifier }) => {
				return qualifier === 'NOD';
		  })
		: null;

	doors = doors && doors.value;

	const { perDay, total } = handleRentalPrice({
		...pickupDropoffTime,
		rateDetailsInfo,
	});

	return {
		category,
		type,
		transmission,
		miles,
		locationName,
		airportCode,
		terminalString,
		pickupTime,
		dropoffTime,
		carModel,
		perDay,
		total,
		capacity,
		doors,
		baggageAmount,
	};
};
