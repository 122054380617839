import React from 'react';

const HeaderText = (props) => {
	return (
		<div className='time-gutter-text'>
			<p className='multi-day'>
				Multi-
				<br />
				Day
			</p>
		</div>
	);
};

export default HeaderText;
