import React from 'react';
import SmallFlightOffer from './SmallFlightOffer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Flight = ({ cname, id, flight, selectedItem }) => {
	return (
		<>
			<div
				className={`${cname} ${
					selectedItem && id === selectedItem._id ? 'selected-item' : null
				} `}>
				<SmallFlightOffer flight={flight} id={id} />
			</div>
		</>
	);
};

Flight.propTypes = {
	selectedItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, null)(Flight);
