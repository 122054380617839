const initialState = {
	hotel: {
		bookingOptions: null,
	},
	flights: {
		bookingOptions: null,
	},
	cars: {
		bookingOptions: null,
		companies: null,
		disableItems: false,
	},
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case 'STATELESS_HOTEL_SEARCH':
			return {
				...state,
				hotel: {
					bookingOptions: payload.bookingOptions,
				},
			};
		case 'STATELESS_CAR_SEARCH':
			return {
				...state,
				cars: {
					...state.cars,
					...payload,
				},
			};
		case 'CLEAR_HOTELS':
			return {
				...state,
				hotel: {
					bookingOptions: null,
				},
			};
		case 'UPDATE_AMADEUS_ITEMS':
			return {
				...state,
				[payload.key]: {
					bookingOptions: payload.items,
				},
			};

		default:
			return state;
	}
}
