import React, { Fragment } from 'react';
import WhyImageDiv from '../landing/WhyImageDiv';
import WhyList from '../landing/WhyList';
import LandingPopup from '../landing/LandingPopup';
import WhatYouGet from './WhatYouGet';

const WhySection = ({ start, token }) => {
	return (
		<section className='why-section'>
			<WhyImageDiv />
			<div>
				<WhyList />
				<WhatYouGet />
			</div>
		</section>
	);
};

export default WhySection;
