import React from 'react';
import SmallHotelOffer from './SmallHotelOffer';

const Hotel = ({ toggleShowModal, itemID, itemTime, hotel }) => {
	return (
		<>
			<SmallHotelOffer hotel={hotel} />
		</>
	);
};

export default Hotel;
