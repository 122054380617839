import React, { Fragment, useEffect, useState } from 'react';
import SingleOptionPreference from './SingleOptionPreference';
import config from '../../config/config';
import { AnimatePresence } from 'framer-motion';

const FlightPreferences = ({
	nextComponent,
	edit,
	index,
	changePreferenceIndex,
	direction,
	setDirection,
	handleDirection,
}) => {
	const windows = [
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='flightPrice'
			title='What is your pricing preference?'
			back={null}
			next={() => {
				handleDirection(1);
			}}
			children={config.FLIGHTPREFERENCES.flightPrice.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			cname='inline'
			name='seating'
			title='Where do you like to sit on the plane?'
			back={() => {
				handleDirection(-1);
			}}
			next={() => {
				handleDirection(1);
			}}
			children={config.FLIGHTPREFERENCES.seating.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			cname='inline'
			name='seatLocation'
			title='What is your seat preference?'
			back={() => {
				handleDirection(-1);
			}}
			next={() => {
				handleDirection(1);
			}}
			children={config.FLIGHTPREFERENCES.seatLocation.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='fareType'
			title='What is your preferred fare type?'
			back={() => {
				handleDirection(-1);
			}}
			next={() => {
				handleDirection(1);
			}}
			children={config.FLIGHTPREFERENCES.fareType.children}
		/>,

		<SingleOptionPreference
			edit={edit}
			direction={direction}
			cname='inline'
			name='tripInsurance'
			title='Do you want to include trip insurance?'
			back={() => {
				handleDirection(-1);
			}}
			next={nextComponent}
			children={config.FLIGHTPREFERENCES.tripInsurance.children}
		/>,
	];
	const handleWindowChange = () => {
		if (index === 0) {
			return (
				<SingleOptionPreference
					name='flightPrice'
					title='What is your pricing preference?'
					back={null}
					next={() => {
						setDirection(1);
						changePreferenceIndex(1);
					}}
					children={config.FLIGHTPREFERENCES.flightPrice.children}
				/>
			);
		} else if (index === 1) {
			return (
				<SingleOptionPreference
					cname='inline'
					name='seating'
					title='Where do you like to sit on the plane?'
					back={() => {
						setDirection(-1);
						changePreferenceIndex(-1);
					}}
					next={() => {
						setDirection(1);
						changePreferenceIndex(1);
					}}
					children={config.FLIGHTPREFERENCES.seating.children}
				/>
			);
		} else if (index === 2) {
			return (
				<SingleOptionPreference
					cname='inline'
					name='seatLocation'
					title='What is your seat preference?'
					back={() => {
						setDirection(-1);
						changePreferenceIndex(-1);
					}}
					next={() => {
						setDirection(1);
						changePreferenceIndex(1);
					}}
					children={config.FLIGHTPREFERENCES.seatLocation.children}
				/>
			);
		} else if (index === 3) {
			return (
				<SingleOptionPreference
					name='fareType'
					title='What is your preferred fare type?'
					back={() => {
						setDirection(-1);
						changePreferenceIndex(-1);
					}}
					next={() => {
						setDirection(1);
						changePreferenceIndex(1);
					}}
					children={config.FLIGHTPREFERENCES.fareType.children}
				/>
			);
		} else if (index === 4) {
			return (
				<SingleOptionPreference
					cname='inline'
					name='tripInsurance'
					title='Do you want to include trip insurance?'
					back={() => {
						setDirection(-1);
						changePreferenceIndex(-1);
					}}
					next={nextComponent}
					children={config.FLIGHTPREFERENCES.tripInsurance.children}
				/>
			);
		} else {
			return (
				<Fragment>
					<SingleOptionPreference
						name='flightPrice'
						title='What is your pricing preference?'
						children={config.FLIGHTPREFERENCES.flightPrice.children}
					/>
					<SingleOptionPreference
						cname='inline'
						name='seating'
						title='Where do you like to sit on the plane?'
						children={config.FLIGHTPREFERENCES.seating.children}
					/>
					<SingleOptionPreference
						cname='inline'
						name='seatLocation'
						title='What is your seat preference?'
						children={config.FLIGHTPREFERENCES.seatLocation.children}
					/>
					<SingleOptionPreference
						name='fareType'
						title='What is your preferred fare type?'
						children={config.FLIGHTPREFERENCES.fareType.children}
					/>
					<SingleOptionPreference
						cname='inline'
						name='tripInsurance'
						title='Do you want to include trip insurance?'
						children={config.FLIGHTPREFERENCES.tripInsurance.children}
					/>
				</Fragment>
			);
		}
	};

	return (
		<Fragment>
			<AnimatePresence>
				{windows.map((window, windowIndex) => {
					if (windowIndex === index || edit) return window;
					else return null;
				})}
			</AnimatePresence>
		</Fragment>
	);
};

FlightPreferences.propTypes = {};

export default FlightPreferences;
