import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import TimeButtonSection from './TimeButtonSection';
import { updateCartItem } from '../../../redux/actions/trip';
import { setCustomStartEnd } from '../../../redux/actions/itineraryModal';
import TravelerAmount from './TravelerAmount';
import { useCheckType } from '../../../Utilities/helperFunctions';
import Note from './Note';
import DayTable from './DayTable';
import LodgingInfo from '../LodgingInfo';

const ItemOptions = ({
	selectedItem,
	type,
	setCustomStartEnd,
	updateCartItem,
	setEventTime,
}) => {
	// let amenities, descriptive_info;
	const [selectedDay, setSelectedDay] = useState('');
	const [selectedTime, setSelectedTime] = useState('');
	const { transportation, lodging, activity } = useCheckType(
		selectedItem.type || selectedItem.data.type
	);

	// if (lodging) {
	// 	let features = selectedItem.data['RoomRates'][0]['RoomRate'][0]['Features'];

	// 	let roomAmenities = features
	// 		? features[0]['Feature'].map((item) => {
	// 				return {
	// 					fromRoom: true,
	// 					...item,
	// 				};
	// 		  })
	// 		: [];

	// 	const services =
	// 		selectedItem.data.descriptive_info &&
	// 		selectedItem.data.descriptive_info['HotelInfo'][0]['Services'];
	// 	const hotelAmenities = services
	// 		? services[0]['Service']
	// 				.filter((item) => {
	// 					const info = item && item.info;
	// 					const hasAmenityCode = info && info['Code'];
	// 					const hasNoProximity = info && !info['ProximityCode'];
	// 					const isOnsite =
	// 						info && info['ProximityCode'] && info['ProximityCode'] === '1';
	// 					const handleProximity = hasNoProximity || isOnsite;
	// 					return hasAmenityCode && handleProximity;
	// 				})
	// 				.map((item) => {
	// 					return {
	// 						hotelAmenity: true,
	// 						...item,
	// 					};
	// 				})
	// 		: [];
	// 	const GuestRooms =
	// 		selectedItem &&
	// 		selectedItem.data &&
	// 		selectedItem.data.descriptive_info &&
	// 		selectedItem.data.descriptive_info['FacilityInfo'] &&
	// 		selectedItem.data.descriptive_info['FacilityInfo'].length &&
	// 		selectedItem.data.descriptive_info['FacilityInfo'][0]['GuestRooms']
	// 			? selectedItem.data.descriptive_info['FacilityInfo'][0]['GuestRooms']
	// 			: null;
	// 	const amenityGuestRoom =
	// 		GuestRooms && GuestRooms.length && GuestRooms[0]['GuestRoom']
	// 			? GuestRooms[0]['GuestRoom'].find((item) => item['Amenities'])
	// 			: null;

	// 	amenities =
	// 		amenityGuestRoom &&
	// 		amenityGuestRoom['Amenities'] &&
	// 		amenityGuestRoom['Amenities'].length &&
	// 		amenityGuestRoom['Amenities'][0]['Amenity']
	// 			? amenityGuestRoom['Amenities'][0]['Amenity'].map((item) => {
	// 					return {
	// 						roomAmenity: true,
	// 						...item,
	// 					};
	// 			  })
	// 			: [];
	// 	amenities = [...amenities, ...roomAmenities, ...hotelAmenities];
	// }

	// const returnRatePlanData = () => {
	// 	const ratePlan =
	// 		lodging &&
	// 		selectedItem &&
	// 		selectedItem.data &&
	// 		selectedItem.data['RatePlans'] &&
	// 		selectedItem.data['RatePlans'].length &&
	// 		selectedItem.data['RatePlans'][0]['RatePlan'] &&
	// 		selectedItem.data['RatePlans'][0]['RatePlan'].length
	// 			? selectedItem.data['RatePlans'][0]['RatePlan'][0]
	// 			: null;
	// 	let guarantee = ratePlan && ratePlan['Guarantee'];
	// 	let cancelPenalties =
	// 		ratePlan &&
	// 		ratePlan['CancelPenalties'] &&
	// 		ratePlan['CancelPenalties'].length &&
	// 		ratePlan['CancelPenalties'][0]['CancelPenalty']
	// 			? ratePlan['CancelPenalties'][0]['CancelPenalty']
	// 			: null;
	// 	let guaranteeType =
	// 		guarantee &&
	// 		guarantee.length &&
	// 		guarantee[0].info &&
	// 		guarantee[0].info['GuaranteeType']
	// 			? guarantee[0].info['GuaranteeType']
	// 			: null;
	// 	let guaranteesAccepted =
	// 		guarantee &&
	// 		guarantee.length &&
	// 		guarantee[0]['GuaranteesAccepted'] &&
	// 		guarantee[0]['GuaranteesAccepted'].length &&
	// 		guarantee[0]['GuaranteesAccepted'][0]['GuaranteeAccepted']
	// 			? guarantee[0]['GuaranteesAccepted'][0]['GuaranteeAccepted']
	// 			: null;
	// 	return { guaranteeType, guaranteesAccepted, cancelPenalties };
	// };

	const handleTimeOptions = () => {
		return selectedItem &&
			selectedItem.timeOptions &&
			selectedItem.timeOptions.length ? (
			<Fragment>
				<TimeButtonSection
					times={selectedItem.timeOptions}
					setSelectedTime={setSelectedTime}
					selectedTime={selectedTime}
				/>
			</Fragment>
		) : null;
	};

	const handleTravelerAmount = () => {
		if (activity || transportation) {
			return <TravelerAmount type={type} selectedId={selectedItem._id} />;
		}
	};

	// const returnLodgingInfo = () => {
	// 	const { guaranteeType, guaranteesAccepted, cancelPenalties } =
	// 		returnRatePlanData();
	// 	return lodging &&
	// 		selectedItem &&
	// 		selectedItem.data &&
	// 		selectedItem.data.descriptive_info ? (
	// 		<Fragment>
	// 			<Amenities amenities={amenities} />
	// 			<RoomRateDescription selectedItem={selectedItem} />
	// 			<Guarantee
	// 				guaranteeType={guaranteeType}
	// 				guaranteesAccepted={guaranteesAccepted}
	// 			/>
	// 			<LodgingDates descriptive_info={selectedItem.data.descriptive_info} />
	// 			{cancelPenalties && (
	// 				<CancelPenalties cancelPenalties={cancelPenalties} />
	// 			)}
	// 		</Fragment>
	// 	) : null;
	// };

	useEffect(() => {
		if (selectedDay && selectedTime) {
			const year = selectedDay.getFullYear();
			const month =
				selectedDay.getMonth() + 1 < 10
					? `0${selectedDay.getMonth() + 1}`
					: selectedDay.getMonth() + 1;
			const day =
				selectedDay.getDate() < 10
					? `0${selectedDay.getDate()}`
					: selectedDay.getDate();

			// let start = new Date(
			// 	year,
			// 	month - 1,
			// 	day,
			// 	parseInt(selectedTime.slice(0, 2)),
			// 	parseInt(selectedTime.slice(3, 5))
			// );
			let start = new Date(`${year}-${month}-${day}T${selectedTime}:00`);

			let end = new Date(start);
			end.setHours(start.getHours() + parseInt(selectedItem.timeLength));
			if (selectedItem._id && activity) {
				setCustomStartEnd(start, end);
				if (selectedItem.inCart)
					updateCartItem({ ...selectedItem, start, end }, 'activities');
			}
			if (selectedItem._id && transportation) {
				setCustomStartEnd(start, end);

				if (selectedItem.inCart)
					updateCartItem({ ...selectedItem, start, end }, 'transport');
			}
		}
	}, [selectedDay, selectedTime]);

	return selectedItem ? (
		<Fragment>
			<section className='options-section'>
				{handleTravelerAmount()}
				<DayTable
					selectedItem={selectedItem}
					selectedDay={selectedDay}
					setSelectedDay={setSelectedDay}
				/>
				{handleTimeOptions()}
				{lodging && <LodgingInfo selectedItem={selectedItem} />}
			</section>
			{selectedItem.adminNotes && <Note note={selectedItem.adminNotes} />}
		</Fragment>
	) : null;
};

ItemOptions.propTypes = {
	selectedItem: PropTypes.object,
	setCustomStartEnd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, { setCustomStartEnd, updateCartItem })(
	ItemOptions
);
