import React, { Fragment } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';

const CancelPenalties = ({ cancelPenalties }) => {
	const returnDateFormat = (date) => moment(new Date(date)).format('LLLL');
	const returnDeadline = (deadline) =>
		deadline && (
			<span>
				- Cancellations need to be made by {returnDateFormat(deadline)} local
				time
			</span>
		);

	const returnAmountCharged = (amountCharged, currencyCode, deadline) =>
		amountCharged && (
			<span>
				- You will be charged {getSymbolFromCurrency(currencyCode)}
				{amountCharged} if cancellation is made after{' '}
				{returnDateFormat(deadline)}
			</span>
		);

	const returnPercentCharged = (percentCharged, deadline) =>
		percentCharged && (
			<span>
				- You will be charged {percentCharged}% of total cost if cancellation is
				made after {returnDateFormat(deadline)}
			</span>
		);

	const destructurePenalties = () => {
		return cancelPenalties.map((penalty, index) => {
			const { info } = penalty;
			const deadline =
				penalty['Deadline'] && penalty['Deadline'][0].info['AbsoluteDeadline'];
			const amountPercent =
				penalty['AmountPercent'] && penalty['AmountPercent'][0].info;
			const amountCharged = amountPercent && amountPercent['Amount'];
			const percentCharged = amountPercent && amountPercent['Percent'];
			const nonRefundable = info['NonRefundable'];
			const currencyCode = amountPercent && amountPercent['CurrencyCode'];
			return nonRefundable !== 'true' ? (
				<Fragment key={`selected-item-penaly-${index}`}>
					<span>Cancellation Policy: </span>
					{returnDeadline(deadline)}
					{returnAmountCharged(amountCharged, currencyCode)}
					{returnPercentCharged(percentCharged)}
				</Fragment>
			) : (
				<span>- Non-Refundable</span>
			);
		});
	};
	return <div className='cancel-penalties'>{destructurePenalties()}</div>;
};

export default CancelPenalties;
