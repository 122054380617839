import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { clearTrip } from '../../redux/actions/trip';
import { put } from '../../Utilities/api';

const checkoutDetailsHOC = (WrappedComponent) => {
	const CheckoutDetails = ({ trip, clearTrip, ...props }) => {
		const [authorizedSuccess, setAuthorizedSuccess] = useState(false);

		const updateTripDetails = async (setLoading) => {
			let authorizedOn =
				trip &&
				trip.purchase &&
				trip.purchase.booking &&
				trip.purchase.booking.authorizedOn
					? trip.purchase.booking.authorizedOn
					: null;
			// if !authorizedOn then new booking authorization will be made
			authorizedOn = authorizedOn || new Date();
			const res = await put(
				'/api/v1/data/private/trip/authorize-items',
				{
					...trip,
					purchase: {
						...trip.purchase,
						booking: { ...trip.purchase.booking, authorizedOn },
					},
				},
				true
			);

			if (res.status === 200) {
				clearTrip();
				setAuthorizedSuccess(true);
			}
		};

		if (authorizedSuccess) {
			return (
				<Redirect
					to={{
						pathname: '/itinerary/',
						state: { alert: 'Authorization Success!' },
					}}
				/>
			);
		}

		return trip._id ? (
			<WrappedComponent
				{...props}
				updateTripDetails={updateTripDetails}
				trip={trip}
			/>
		) : null;
	};

	const mapStateToProps = (state) => ({
		trip: state.trip,
	});

	return connect(mapStateToProps, { clearTrip })(CheckoutDetails);
};

export default checkoutDetailsHOC;
