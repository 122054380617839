import axios from 'axios';

// Managed by redirects in public/_redirects
const baseURL = process.env.REACT_APP_API_URL || ''; //process.env.REACT_APP_API_URL

const makeRequest = (method, pathname, data, authRequired) => {
	let token = localStorage.getItem('authToken');
	token = token || localStorage.getItem('adminToken');
	const url = baseURL + pathname;
	let headers = {
		Accept: '*/*',
		'content-type': 'application/json',
	};

	if (authRequired && token)
		headers = { ...headers, authorization: `Bearer ${token}` };

	return axios({ method, url, headers, data });
};

export const get = (pathname, data, authRequired) =>
	makeRequest('get', pathname, data, authRequired);

export const put = (pathname, data, authRequired) =>
	makeRequest('put', pathname, data, authRequired);

export const post = (pathname, data, authRequired) =>
	makeRequest('post', pathname, data, authRequired);

export const remove = (pathname, data, authRequired) =>
	makeRequest('delete', pathname, data, authRequired);

export const run_ip_check = () => {
	// update user's IP address in database if it has changed: run on login, register and page refresh
	axios.get('https://geolocation-db.com/json/').then((ip_response) => {
		let new_ip_address = ip_response.data.IPv4;
		let old_ip_address = localStorage.getItem('ip_address');

		if (old_ip_address !== new_ip_address) {
			post('/api/v1/amadeus_test/save_ip', { ip: new_ip_address }, true).then(
				(response) => {
					localStorage.setItem('ip_address', new_ip_address);
				}
			);
		}
	});
};

export const submitItineraryUpdate = async (trip, fns) => {
	try {
		const res = await put(
			'/api/v1/data/private/trip/update-itinerary',
			trip,
			true
		).catch((err) => {
			throw {
				message: err.message,
			};
		});
		if (res.status === 200) {
			if (fns) {
				fns.forEach((fn) => fn());
			}
			return res;
		}
	} catch (error) {
		return error;
	}
};
