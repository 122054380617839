import { connect } from 'react-redux';
import React from 'react';
import TravelerNumAge from './TravelerNumAge';
import PropTypes from 'prop-types';

const TravelerAmountSection = ({ adults, children, infants }) => {
	return (
		<section className='trip__form-card'>
			<h2 className='trip__form-card-header'>NUMBER OF TRAVELERS</h2>

			<div className='newcss-grid-3'>
				<TravelerNumAge
					cname='adults'
					range='adults'
					label='Adults (12+)'
					amount={adults}
				/>
				<TravelerNumAge
					cname='children'
					range='children'
					label='Children (2 - 12 years)'
					amount={children}
				/>
				<TravelerNumAge
					cname='infants'
					range='infants'
					label='Infants (Under 2)'
					amount={infants}
				/>
			</div>
		</section>
	);
};

TravelerAmountSection.propTypes = {
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
};

const mapStateToProps = (state) => ({
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, null)(TravelerAmountSection);
