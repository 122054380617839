import React, { useState } from 'react';
import { toDollarStyle } from '../../Utilities/helperFunctions';
import infoIcon from '../../images/noun-information-1281661-142350-small.svg';
import InfoBubble from '../Layout/InfoBubble';

const TotalSection = ({ price, text, cname, hoverItem }) => {
	const [hovered, setHovered] = useState(false);
	return (
		<div className={`total-section ${cname}`}>
			<span>
				{text}
				{hoverItem && <InfoBubble hoverItem={hoverItem} />}
				{/* {hoverItem && (
					<span>
						<img
							onMouseOver={() => setHovered(true)}
							onMouseLeave={() => setHovered(false)}
							src={infoIcon}
							alt='information icon'
							className='info-icon'
						/>
						{hovered && hoverItem}
					</span>
				)} */}
			</span>{' '}
			<span>{toDollarStyle(price, 'USD')} </span>
		</div>
	);
};

TotalSection.propTypes = {};

export default TotalSection;
