import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { handleText } from '../../../redux/actions/trip';
const UserComments = ({ comments, handleText }) => {
	const handleChange = (event) => {
		event.preventDefault();
		const name = event.target.name;
		const text = event.target.value;
		handleText(name, text);
	};

	return (
		<Fragment>
			<label className='newcss-spacer'>
				What else should we keep in mind when helping you plan your perfect
				trip. Please share details here.
			</label>
			<textarea
				name='comments'
				id='typeDetails'
				value={comments}
				onChange={handleChange}
			/>
		</Fragment>
	);
};

UserComments.propTypes = {
	comments: PropTypes.string,
	handleText: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	comments: state.trip.comments,
});
export default connect(mapStateToProps, { handleText })(UserComments);
