import React, { Fragment, useEffect } from 'react';
import { setTripType } from '../../../redux/actions/trip';
import config from '../../../config/config';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrMessage from '../../Layout/ErrMessage';
import { setTravelTypeError } from '../../../redux/actions/errors';

const TripTypes = ({
	setTripType,
	tripType,
	travelTypeError,
	setTravelTypeError,
}) => {
	const returnTripTypes = () => {
		return config.tripTypes.map((type, index) => {
			const { text, cname, imageWhite, imagePurple } = type;
			return (
				<Fragment key={`trip-type-${index}`}>
					<button
						onClick={() => {
							setTripType(text);
						}}
						id={text}
						className={
							tripType === text
								? 'newcss-selected-button' + ' ' + cname
								: 'newcss-unselected-button' + ' ' + cname
						}>
						<img
							src={tripType === text ? imageWhite : imagePurple}
							alt={`${text} icon`}
						/>
						<p>{text}</p>
					</button>
				</Fragment>
			);
		});
	};

	useEffect(() => {
		if (tripType && travelTypeError) {
			setTravelTypeError('');
		}
	}, [tripType]);

	return (
		<Fragment>
			<label htmlFor='type'>
				Tell us what type of trip you’re taking (choose 1)
				{travelTypeError && <ErrMessage inline={true} text={travelTypeError} />}
			</label>
			<div className='newcss-grid-4 trip-types'>{returnTripTypes()}</div>
		</Fragment>
	);
};

TripTypes.propTypes = {
	tripType: PropTypes.string,
	setTripType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	tripType: state.trip.travelType,
	travelTypeError: state.errors.travelTypeError,
});

export default connect(mapStateToProps, { setTripType, setTravelTypeError })(
	TripTypes
);
