import React, { useEffect, useState } from 'react';
import { get } from '../../Utilities/api';
import TestimonialShow from '../TestimonialShow';
import { AnimatePresence } from 'framer-motion';

const Testimonials = () => {
	const [list, setList] = useState([]);
	const [current, setCurrent] = useState(null);

	const getTestimonials = async () => {
		const res = await get('/api/v1/data/testimonial/get', {}, false);
		if (res) {
			setList(res.data.foundTestimonials);
		}
	};

	useEffect(() => {
		getTestimonials();
	}, []);
	return (
		<div className='newcss-homepage-center'>
			<div className='testimonial-list'>
				<AnimatePresence initial={true} mode='wait'>
					{current ? (
						<TestimonialShow
							closeModal={() => setCurrent(null)}
							testimonial={current}
						/>
					) : null}
				</AnimatePresence>
				{list && list.length
					? list.map((testimonial) => {
							return (
								<section
									className='trip-container'
									onClick={(e) => {
										e.preventDefault();
										setCurrent(testimonial);
									}}>
									<div className='newcss-vert-grid-2-1 trip__card  testimonial-card'>
										<img src={testimonial.photos[0]} alt='' />

										<div className='main'>
											<h3>{testimonial.title}</h3>
											<p>{testimonial.description}</p>
										</div>
									</div>
								</section>
							);
					  })
					: null}
				{list && list.length && list.length % 2 === 1 ? (
					<section className='slot'></section>
				) : null}
			</div>
		</div>
	);
};

export default Testimonials;
