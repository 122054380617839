console.original_log = console.log;
console.original_warn = console.warn;
console.original_info = console.info;

let turn_off_logs_in = 'prod';

let path = window.location.host;
let environment = 'prod';
if (path.indexOf('localhost') !== -1) {
	environment = 'dev';
} else {
	if (path) {
		let path_tokens = path.split('.');
		if (path_tokens[0] === 'dev') {
			environment = 'dev';
		}
	} else {
		environment = 'dev';
	}
}

console.log = function (...params) {
	if (environment === turn_off_logs_in) {
		// do nothing
	} else {
		console.original_log(...params);
	}
};

console.warn = function (...params) {
	if (environment === turn_off_logs_in) {
		// do nothing
	} else {
		console.original_warn(...params);
	}
};

console.info = function (...params) {
	if (environment === turn_off_logs_in) {
		// do nothing
	} else {
		console.original_info(...params);
	}
};
