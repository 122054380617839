import React, { Fragment } from 'react';
import PriceDiv from './PriceDiv';

const PricingHeader = ({ curation, booking, landing }) => {
	return (
		<Fragment>
			<h3 className='pricing-title'>LET'S PLAN YOUR TRIP!</h3>
			<header className='pricing-header'>
				<PriceDiv
					price='$75'
					highlighted={!landing && curation && true}
					text='Curation Fee'
				/>
				<p>+</p>
				<PriceDiv
					price='$75'
					highlighted={!landing && booking && true}
					text='Booking Fee'
				/>
				<p>=</p>
				<PriceDiv price='$150' text='Custom Planned Trip' />
			</header>
		</Fragment>
	);
};

export default PricingHeader;
