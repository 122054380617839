import React from 'react';
import PropTypes from 'prop-types';
import { selectModalItem } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import {
	accrisBodyType,
	accrisCategory,
	accrisDriveType,
	accrisFuelAir,
} from '../../Utilities/cars';
import { toDollarStyle } from '../../Utilities/helperFunctions';

import noImage from '../../images/noun_Camera_prohibited_1554484.svg';

import carSVG from '../../images/noun-car-5226133-142350.svg';

const CarRentalOption = ({
	detail,
	selectModalItem,
	index,
	selectedItem,
	tripId,
	disableItems,
}) => {
	let {
		carCompanyData,
		pickupDropoffLocations,
		pickupDropoffTime,
		rateDetailsInfo,
		rateIdentifier,
		rateMarketingInfo,
		rateStatus,
		ruleInfo,
		sizedPictures,
		validityPeriod,
		vehicleTypeInfo,
	} = detail && detail.data ? detail.data : null;

	let picture =
		sizedPictures &&
		sizedPictures.length &&
		sizedPictures[0].imageURL &&
		sizedPictures[0].imageURL.address &&
		sizedPictures[0].imageURL.address.identifier
			? sizedPictures[0].imageURL.address.identifier
			: null;

	const handleAccris = () => {
		let { vehicleCharacteristic } = vehicleTypeInfo;
		let { vehicleTypeOwner, vehicleRentalPrefType } = vehicleCharacteristic;
		const category = accrisCategory[vehicleRentalPrefType[0]];
		const type = accrisBodyType[vehicleRentalPrefType[1]];
		const transmission = accrisDriveType[vehicleRentalPrefType[2]];
		const feulAir = accrisFuelAir[vehicleRentalPrefType[3]];
		return {
			category,
			type,
			transmission,
			feulAir,
		};
	};
	const { category, type, transmission, feulAir } = handleAccris();

	const handleVehicleType = () => {
		let { carModel, vehicleCharacteristic } = vehicleTypeInfo;
		let { vehicleTypeOwner, vehicleRentalPrefType } = vehicleCharacteristic;
		if (carModel) return `${carModel}`;
		else if (vehicleCharacteristic) {
			if (vehicleTypeOwner === 'ACR') {
				return `${category}/${type}`;
			}
		} else return null;
	};

	const handleSelect = () => {
		selectModalItem(detail);
	};

	const handlePrice = () => {
		const estimatedTotal = rateDetailsInfo.find(
			(item) => item.tariffInfo.rateType === '906'
		);
		return `${toDollarStyle(
			estimatedTotal.tariffInfo.rateAmount,
			estimatedTotal.tariffInfo.rateCurrency
		)} `;
	};

	return (
		<div
			key={detail._id}
			className={`itinerary-item__card${
				selectedItem && selectedItem._id === detail._id ? '--selected' : ''
			} car`}>
			<img
				className={`itinerary-item__card-image${picture ? '' : ' no-image'}`}
				src={picture || carSVG}
				alt='hotel'
				style={{
					display: 'block',
				}}
			/>
			<div className='itinerary-item__card-main'>
				<h3>{handleVehicleType()}</h3>
				<section>
					<p>{handlePrice()}</p>
					<p>{feulAir}</p>
				</section>
				<button
					disabled={disableItems}
					className='newcss-item-button'
					onClick={handleSelect}
					value={JSON.stringify(detail)}>
					Details
				</button>
			</div>
		</div>
	);
};

CarRentalOption.propTypes = {};

const mapStateToProps = (state) => ({
	// selectedItem: state.trip.selectedItem,
	tripId: state.trip._id,
});

export default connect(mapStateToProps, { selectModalItem })(CarRentalOption);
