import React from 'react';
import NewTripTitle from './NewTripTitle';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';

const TripCard = ({ trip, index }) => {
	return (
		<section key={trip._id} className='trip-container' id={trip._id}>
			<div className='newcss-vert-grid-2-1 trip__card'>
				<a
					href={`/plan-trip/${trip._id}`}
					style={!trip.itineraryItems ? { pointerEvents: 'none' } : null}>
					<img
						className={!trip.photoUrl ? 'no-image' : ''}
						src={trip.photoUrl || noImage}
						alt={`trip image`}
					/>

					<NewTripTitle
						index={index}
						trip={trip}
						pending={!trip.curated ? true : false}
						returnDate={trip.returnDate}
						hotels={trip.hotelDetails}
						flights={trip.flightDetails}
					/>
				</a>
			</div>
		</section>
	);
};

export default TripCard;
