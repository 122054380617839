import React from 'react';
import { Fragment } from 'react';
import moment from 'moment';
import { handleTime } from '../../../Utilities/helperFunctions';

const DateButton = ({ date, setSelected, selected }) => {
	const handleSelected = (date) => {
		setSelected(date);
	};

	return (
		<Fragment>
			<button
				className={`date-time-button ${selected && 'selected'} `}
				onClick={() => handleSelected(date)}>
				{date instanceof Date
					? moment(date).format('MMMM Do')
					: handleTime(date)}
			</button>
		</Fragment>
	);
};

export default DateButton;
