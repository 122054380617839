import React, { useEffect } from 'react';
import FlightInfoContainer from './Flights/FlightInfoContainer';

const FlightInfo = ({ itineraryItem, index }) => {
	const { flight_details } = itineraryItem;

	useEffect(() => {
		const flightMap = {};
		if (flight_details && flight_details.length)
			flight_details.forEach((segment, index) => {
				const { departure, arrival } = segment;
				flightMap[index] = {
					departure: departure.time,
					arrival: arrival.time,
				};
			});
	}, [itineraryItem.flight_details]);

	return <FlightInfoContainer flight_details={flight_details} index={index} />;
};

export default FlightInfo;
