import React from 'react';
import { removeAllFlights, setFlightType } from '../../../redux/actions/trip';
import { connect } from 'react-redux';
import Error from '../Error';

const FlightToggler = ({
	// removeAllFlights,
	// flightError,
	// returnDateError,
	// isMultiCity,
	// setIsMultiCity,
	// setFlightType,
	leftTitle,
	rightTitle,
	leftValue,
	rightValue,
	leftSelected,
	rightSelected,
	leftClick,
	rightClick,
	errors,
	cName,
}) => {
	return (
		<div className={`newcss-grid-2-buttons${cName ? ` ${cName}` : ''}`}>
			<button
				onClick={() => {
					leftClick();
				}}
				id='tripType'
				value={leftValue}
				className={
					leftSelected
						? 'newcss-selected-button button-left'
						: 'newcss-unselected-button button-left'
				}>
				{leftTitle}
			</button>
			<button
				onClick={() => {
					rightClick();
				}}
				id='tripType'
				value={rightValue}
				className={
					rightSelected
						? 'newcss-selected-button button-right'
						: 'newcss-unselected-button button-right'
				}>
				{rightTitle}
			</button>
			{errors}
		</div>
	);
};

FlightToggler.propTypes = {};

export default connect(null, {})(FlightToggler);
