import React from 'react';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { setRedirect } from '../../redux/actions/redirect';
import SaveTripAlert from './SaveTripAlert';
import { useState } from 'react';
import redirectHOC from '../hoc/RedirectHOC';
import { submitItineraryUpdate } from '../../Utilities/api';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Navbar = ({ token, newUser, trip, setRedirect, isAdmin }) => {
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertPathname, setAlertPathname] = useState('');

	const redirect = async (pathname) => {
		if (window.location.pathname === '/create-trip/' && trip._id) {
			setAlertPathname(pathname);
			setAlertOpen(true);
		} else if (window.location.pathname === '/cart' && trip._id) {
			const res = await submitItineraryUpdate(trip);
			if (res.status === 200) {
				setRedirect(pathname);
			}
		} else {
			setRedirect(pathname);
		}
	};
	const setNav = () => {
		if (!token) {
			return (
				<Fragment>
					<nav
						className={`newcss-navbar navbar__landing ${
							token ? 'navbar__hidden' : null
						}`}>
						<div className='nav-center'>
							<div className='nav-header'>
								<button
									onClick={() => redirect('/itinerary/')}
									className='logo'>
									ROVE
								</button>

								<section className='nav-links-desktop'>
									<SignedOutLinks />
								</section>

								<section className='nav-links-mobile'>
									<SignedOutLinks />
								</section>
							</div>
						</div>
					</nav>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					{alertOpen ? (
						<SaveTripAlert redirect={alertPathname} trip={trip} />
					) : null}
					<nav className={`newcss-navbar `}>
						<div className='nav-center'>
							<div className='nav-header'>
								<button
									onClick={() =>
										redirect(`/itinerary/${isAdmin ? `${trip.user._id}` : ''}`)
									}
									className='logo'>
									ROVE
								</button>

								{!newUser ? (
									<Fragment>
										<section className='nav-links-desktop'>
											<SignedInLinks />
										</section>
									</Fragment>
								) : null}
							</div>
						</div>
					</nav>
				</Fragment>
			);
		}
	};

	return setNav();
};

Navbar.propTypes = {
	token: PropTypes.string,
	onItinerary: PropTypes.bool,
	newUser: PropTypes.bool,
};
const mapStateToProps = (state) => ({
	token: state.auth.token,
	onItinerary: state.auth.onItinerary,
	newUser: state.auth.newUser,
	trip: state.trip,
});

export default connect(mapStateToProps, { setRedirect })(redirectHOC(Navbar));
