import React, { useState } from 'react';
import LoadingOverlay from '../Layout/LoadingOverlay';
import authFormHOC from '../hoc/AuthForm';
import {
	// resetPassword,
	updatePassword,
} from '../../redux/actions/auth';
import { connect } from 'react-redux';
import { post } from '../../Utilities/api';

const PasswordWindow = ({
	isResetPassword,
	setCurrentForm,
	handlePasswordStatus,
	handleSamePassword,
	// resetPassword,
}) => {
	const initialState = {
		currentPassword: '',
		newPassword1: '',
		newPassword2: '',
		submitted: false,
		loading: false,
		success: false,
	};
	const [formData, setFormData] = useState(initialState);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const resetPassword = async (password) => {
		try {
			const res = await post('/api/v1/auth/reset-password', {
				password,
				resetPasswordToken: localStorage.getItem('resetPasswordToken'),
			}).catch((err) => {
				throw {
					message: err.message,
				};
			});

			return res;
		} catch (error) {}
	};

	const handlePassword = async (e) => {
		e.preventDefault();
		setFormData({ ...formData, submitted: true, loading: true });
		try {
			let res;
			if (isResetPassword) {
				res = await resetPassword(formData.newPassword1);
			} else {
				res = await updatePassword(formData);
			}

			if (res.data.success) {
				setFormData({
					...formData,
					submitted: true,
					loading: false,
					success: true,
				});
				if (isResetPassword) {
					// setTimeout(() => setCurrentForm('login'), 4000);
				}
			}
		} catch (err) {
			console.log('err', err);
			setFormData({
				...formData,
				submitted: true,
				loading: false,
				success: false,
			});
		}
		// setTimeout(() => {
		// 	setFormData(initialState);
		// 	setCurrentForm && setCurrentForm('login');
		// }, 4000);
	};
	return (
		<>
			<form
				className='update-password
			'
				onSubmit={handlePassword}>
				{formData.submitted ? (
					<LoadingOverlay
						loading={formData.loading}
						success={formData.success}
					/>
				) : null}
				<h4>Update Password</h4>
				{!isResetPassword ? (
					<div>
						<label htmlFor='currentPassword'>Current Password</label>
						<input
							onChange={handleChange}
							type='password'
							name='currentPassword'
							value={formData.currentPassword}
						/>
					</div>
				) : null}
				<div>
					<label htmlFor='password1'>New Password</label>
					<input
						onChange={handleChange}
						type='password'
						name='newPassword1'
						value={formData.newPassword1}
					/>
					{handlePasswordStatus(formData.newPassword1)}
				</div>
				<div>
					<label htmlFor='password2'>Retype New Password</label>
					<input
						onChange={handleChange}
						type='password'
						name='newPassword2'
						value={formData.newPassword2}
					/>
					{handleSamePassword(formData.newPassword1, formData.newPassword2)}
				</div>
				<button>Save New Password</button>
			</form>
		</>
	);
};

PasswordWindow.propTypes = {};

export default connect(null, {
	// resetPassword,
	// updatePassword,
})(authFormHOC(PasswordWindow));
