import React, { useEffect, useRef } from 'react';
import LandingPopup from '../landing/LandingPopup';
import config from '../../config/config';
export const SignedOutLinks = (props) => {
	const headerRef = useRef(null);

	const button = () => {
		return (
			<a className='nav-links-link' style={{ color: 'white' }}>
				{config.SignedOutLinks.Login}
			</a>
		);
	};

	//
	const handleStart = () => {
		return window.location.pathname.slice(0, 16) === '/reset-password/'
			? 'reset-password'
			: 'login';
	};

	useEffect(() => {
		if (
			window &&
			window.location &&
			window.location.pathname &&
			window.location.pathname.length &&
			window.location.pathname.slice(0, 16) === '/reset-password/' &&
			headerRef.current.firstChild &&
			headerRef.current.firstChild.firstChild
		) {
			headerRef.current.firstChild.firstChild.click();
		}
	}, [headerRef]);

	return (
		<ul
			className='nav-links login'
			ref={headerRef}
			style={{ padding: 0 }}
			id='nav-header'>
			<li>
				<LandingPopup button={button} start={handleStart()} />
			</li>
		</ul>
	);
};

export default SignedOutLinks;
