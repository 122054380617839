import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import uuid from 'react-uuid';

import arrow from '../images/noun_Arrow_1256499.svg';
import { loadAuthUser } from '../redux/actions/auth';
import { connect } from 'react-redux';
const TestimonialShow = ({ closeModal, testimonial, focusedHighlight }) => {
	const [currentTestimonial, setCurrentTestimonial] = useState(0);
	const { title, testimonials, photos, description, destinations } =
		testimonial;

	useEffect(() => {
		if (focusedHighlight) {
			testimonial.testimonials.forEach((item, index) => {
				if (item._id === focusedHighlight._id) {
					setCurrentTestimonial(index);
				}
			});
		}
	}, []);
	return (
		<motion.div
			key={testimonial._id}
			className='tool-tip__outer'
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			exit={{
				opacity: 0,
			}}
			style={{
				width: '100vw',
				height: '100vh',
				position: 'fixed',
				top: '0',
				left: '0',
				backgroundColor: 'rgba(0, 0, 0, 0.4)',
				zIndex: '1000000',
			}}>
			<div className='testimonial-inner'>
				<button className='close' onClick={closeModal}>
					+
				</button>

				<header>
					<div className='left'>
						<h3>{title}</h3>
						<h5 className='capitalize'>
							{destinations.map((destination, index) => {
								const { location } = destination;
								return index === destinations.length - 1 ? (
									<>
										{`${location.cityName}, ${
											location.stateCode !== 'null'
												? location.stateCode.toUpperCase() + ', '
												: ''
										}${location.countryName}`}
									</>
								) : (
									<>
										{`${location.cityName}, ${
											location.stateCode !== 'null'
												? location.stateCode.toUpperCase() + ', '
												: ''
										}${location.countryName}`}{' '}
										<br />
									</>
								);
							})}
						</h5>
					</div>
					<img
						className='right'
						src={photos?.length ? photos[0] : null}
						alt=''
					/>
				</header>
				<p className='description'>{description}</p>
				<section
					className='testimonials'
					style={testimonials.length > 1 ? null : { justifyContent: 'center' }}>
					{testimonials.length > 1 ? (
						<button
							className='left'
							onClick={(e) => {
								e.preventDefault();
								if (currentTestimonial < 0) {
									setCurrentTestimonial((curr) => {
										return curr - 1;
									});
								}
							}}>
							<img src={arrow} alt='' />
						</button>
					) : null}

					<main>
						<h4>Testimonials:</h4>
						<p>{testimonials[currentTestimonial].feedback}</p>
					</main>

					{testimonials.length > 1 ? (
						<button
							className='right'
							onClick={(e) => {
								e.preventDefault();
								if (currentTestimonial < testimonials.length - 1) {
									setCurrentTestimonial((curr) => {
										return curr + 1;
									});
								}
							}}>
							<img style={{ transform: 'rotate(180deg)' }} src={arrow} alt='' />
						</button>
					) : null}
				</section>
			</div>
		</motion.div>
	);
};

export default connect(null, { loadAuthUser })(TestimonialShow);
