import React from 'react';
import { useHistory } from 'react-router-dom';

const DestinationPickerResults = ({
	results,
	restart,
	destination,
	showButtons,
}) => {
	const history = useHistory();
	return (
		<>
			<div className='destination-picker__results'>
				{results && results.length ? (
					results.map((item) => {
						return destination ? (
							<button
								onClick={() =>
									history.push({
										pathname: '/create-trip',
										state: { destinations: [item] },
									})
								}
								className={`destination-picker-button card${
									results.length > 4 ? 'width-27p' : ''
								}`}>
								<p className='capitalize'>
									{item.location.cityName},{' '}
									{item.location.countryCode.toUpperCase()}
								</p>
							</button>
						) : (
							<button
								onClick={() =>
									history.push({
										pathname: '/create-trip',
										state: { destinations: item.destinations },
									})
								}
								className='destination-picker-button card'>
								<p className='capitalize'>
									{item.destinations.map((destination, index) => {
										return index === item.destinations.length - 1
											? `${destination.location.cityName}, 
											${destination.location.countryCode.toUpperCase()}`
											: `${destination.location.cityName}, 
											${destination.location.countryCode.toUpperCase()} -> `;
									})}
								</p>
							</button>
						);
					})
				) : (
					<h3>No options found</h3>
				)}
			</div>
			{showButtons ? (
				<div className='buttons'>
					<a href='/create-trip' className='button'>
						Start New Trip
					</a>
					<button onClick={restart}>Restart</button>
				</div>
			) : null}
		</>
	);
};

export default DestinationPickerResults;
