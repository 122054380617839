import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';

import ErrMessage from '../Layout/ErrMessage';
const AddTravelers = ({ number, traveler, primary, handleChange, errors }) => {
	const { title, dob, gender } = traveler;
	const genderRef = useRef();
	const [formErrors, setFormErrors] = useState({
		firstName: '',
		lastName: '',
		dob: '',
	});

	const errorText = {
		firstName: `Please fill out party's first name.`,
		lastName: `Please fill out party's last name.`,
		dob: `Please fill out party's date of birth.`,
	};

	useEffect(() => {
		if (!traveler.gender) {
			genderRef.current.value = 'female';
			const target = {
				name: 'gender',
				value: 'female',
			};
			handleChange({ target });
		}
	}, []);

	const returnErrorMessage = (key) => {
		return errors && errors[key] ? <ErrMessage text={errors[key]} /> : null;
	};

	return traveler ? (
		<Fragment>
			<form className='newcss-add-traveler-form primary'>
				<h3 className='newcss-add-traveler-form__title'>
					{primary ? 'Primary ' : 'Companion '}Traveler {number}
				</h3>
				<div className='newcss-grid-6-11-11'>
					<div>
						<label hmtlFor='title'>Title</label>
						<input
							name='title'
							defaultValue={traveler.title}
							onChange={handleChange}
							value={title}
						/>
					</div>
					<div>
						<label hmtlFor='firstName'>First Name*</label>
						<input
							name='firstName'
							onChange={handleChange}
							value={traveler.firstName}
						/>
						{returnErrorMessage('firstName')}
					</div>
					<div>
						<label hmtlFor='middleName'>Middle Name</label>
						<input
							name='middleName'
							onChange={handleChange}
							value={traveler.middleName}
						/>
					</div>
				</div>
				<div className='newcss-add-traveler-grid-2-1'>
					<div>
						<label hmtlFor='lastName'>Last Name*</label>
						<input
							name='lastName'
							onChange={handleChange}
							value={traveler.lastName}
						/>
						{returnErrorMessage('lastName')}
					</div>
					<div>
						<label hmtlFor='suffix'>Suffix</label>
						<input
							name='suffix'
							onChange={handleChange}
							value={traveler.suffix}
						/>
					</div>
				</div>
				<div className='newcss-add-traveler-grid-2-1'>
					<div>
						<label htmlFor='dob'>Date of Birth*</label>
						<input
							name='dob'
							onChange={handleChange}
							value={dob && dob.slice(0, 10)}
							type='date'
							placeholder='MM/DD/YYYY'
						/>
						{returnErrorMessage('dob')}
					</div>
					<div>
						<label htmlFor='gender'>Gender</label>

						<select
							ref={genderRef}
							name='gender'
							id='gender'
							onChange={handleChange}
							value={gender || 'female'}>
							<option name='gender' value='male'>
								Male
							</option>
							<option
								name='gender'
								// selected={traveler.gender === 'female'}
								value='female'>
								Female
							</option>
						</select>
					</div>
				</div>
			</form>
		</Fragment>
	) : null;
};

export default AddTravelers;
