import React from 'react';
import moment from 'moment';
import { airlines } from '../../../../airlines';

import flightImage from '../../../../images/flight2.svg';
import FlightPrice from '../FlightPrice';
import SegmentTitle from '../SegmentTitle';
import ModalFlightDetails from './ModalFlightDetails';
import { connect } from 'react-redux';
import datetimeDifference from 'datetime-difference';

const FlightInfoContainer = ({ flight_details, index, selectedItem }) => {
	const returnNewDate = (date, time) => {
		const newDate = new Date(date);
		if (time) {
			const hours = time.slice(0, 2);
			const min = time.slice(3);
			newDate.setHours(hours);
			newDate.setMinutes(min);
		}
		return newDate;
	};
	const departureDate = flight_details[0].departure.date;
	// const departureTime = flight_details[0].departure.time;
	const arrivalDate = flight_details[flight_details.length - 1].arrival.date;
	// const arrivalTime = flight_details[flight_details.length - 1].arrival.time;

	return (
		<div className='flight__info'>
			{index === 0 && (
				<FlightPrice
					price={`$${selectedItem.data.recommendation.pricing_info.passenger_pricing[0].total_amount}`}
					// currency={selectedItem.data.price.currency}
				/>
			)}

			<SegmentTitle
				date={moment(flight_details[0].departure.date).format('dddd MMM Do')}
				daySpan={datetimeDifference(
					returnNewDate(departureDate),
					returnNewDate(arrivalDate)
				)}
				direction={index === 0 ? 'Departing' : 'Returning'}
				image={
					airlines[flight_details[0].companyId.marketingCarrier]
						? airlines[flight_details[0].companyId.marketingCarrier].image
						: flightImage
				}
			/>
			<ModalFlightDetails
				returnNewDate={returnNewDate}
				flight_details={flight_details}
				leg_index={index}
				selectedItem={selectedItem}
			/>
		</div>
	);
};

FlightInfoContainer.propTypes = {};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, null)(FlightInfoContainer);
