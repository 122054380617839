import React from 'react';
import EditPreferences from '../pages/EditPreferences';
import PasswordWindow from './PasswordWindow';

const SettingsMain = ({ currentWindow }) => {
	const setPreferenceWindow = () => {
		return <EditPreferences />;
	};
	const setPasswordWindow = () => {
		return <PasswordWindow />;
	};
	const handleCurrentWindow = () => {
		if (currentWindow === 'Preferences') {
			// return setPreferenceWindow();
		}
		if (currentWindow === 'Password') {
			return setPasswordWindow();
		}
	};
	return <div className='settings__main'>{handleCurrentWindow()}</div>;
};

export default SettingsMain;
