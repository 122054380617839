import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import TravelerNumAge from '../../CreateTrip/secondary-travelers/TravelerNumAge';
import { setItemUnits } from '../../../redux/actions/trip';

const TravelerAmount = ({
	adults,
	children,
	infants,
	type,
	setItemUnits,
	selectedId,
}) => {
	const [travelerAmount, setTravelerAmount] = useState({
		adults,
		children,
		infants,
	});

	const handleChange = (e, num) => {
		setTravelerAmount({
			...travelerAmount,
			[e.target.name]: travelerAmount[e.target.name] + num,
		});
	};

	useEffect(() => {
		if (type === 'individual-activity') {
			setItemUnits(
				travelerAmount.adults +
					travelerAmount.children +
					travelerAmount.infants,
				selectedId,
				'activities'
			);
		} else if (type === 'public-transport') {
			setItemUnits(
				travelerAmount.adults +
					travelerAmount.children +
					travelerAmount.infants,
				selectedId,
				'transport'
			);
		}
	}, []);
	return (
		<Fragment>
			<section className='itinerary-modal-traveler-amount'>
				<div className='main'>
					<TravelerNumAge
						handleChange={handleChange}
						cname='adults'
						range='adults'
						label='Adults (12+)'
						amount={travelerAmount.adults}
					/>
					<TravelerNumAge
						handleChange={handleChange}
						cname='children'
						range='children'
						label='Children (2 - 12 years)'
						amount={travelerAmount.children}
					/>
					<TravelerNumAge
						handleChange={handleChange}
						cname='infants'
						range='infants'
						label='Infants (Under 2)'
						amount={travelerAmount.infants}
					/>
				</div>
			</section>
		</Fragment>
	);
};
TravelerAmount.propTypes = {
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
};

const mapStateToProps = (state) => ({
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, {
	setItemUnits,
})(TravelerAmount);
