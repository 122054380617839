import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CartTotal from './CartTotal';
import CartToggler from './CartToggler';
import InCart from './InCart';
import CartTitle from './CartTitle';
import { connect } from 'react-redux';
import checkoutDetailsHOC from '../hoc/CheckoutDetails';

const CartDetails = ({ stage, purchase, trip, updateTripDetails }) => {
	const [currentView, setCurrentView] = useState('My Cart');
	const [currentItems, setCurrentItems] = useState(null);
	// const returnCurrentItems = () => {
	// 	if (trip) {
	// 		if (currentView === 'cart') {
	// 			return trip.cart;
	// 		}
	// 		if (currentView === 'saved') {
	// 			return trip.selected;
	// 		}
	// 	}
	// };

	useEffect(() => {
		if (trip) {
			if (currentView === 'My Cart') {
				setCurrentItems(trip.cart);
			}
			if (currentView === 'Saved For Later') {
				setCurrentItems(trip.selected);
			}
		}
	}, [currentView, trip]);

	return (
		<div className='cart-details'>
			<div className='left'>
				<CartToggler
					setCurrentView={setCurrentView}
					currentView={currentView}
				/>
				<CartTitle text={currentView} />

				<InCart
					cartCheckout={true}
					inCart={currentView === 'My Cart' ? true : false}
					currentItems={currentItems}
				/>
				{/* {currentView === 'Saved For Later' && <Saved />} */}
			</div>
			<CartTotal
				iframeSubmitSuccess={true}
				handleClick={updateTripDetails}
				stage={stage}
				purchase={purchase}
			/>
		</div>
	);
};

CartDetails.propTypes = {
	cart: PropTypes.object,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	purchase: state.trip.purchase,
	trip: state.trip,
});

export default connect(mapStateToProps, null)(checkoutDetailsHOC(CartDetails));
