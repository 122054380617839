import React, { useEffect } from 'react';
import checkmark from '../../images/checkmark.svg';

const Alert = ({ text, cname, removeAlert }) => {
	useEffect(() => {
		setTimeout(() => {
			removeAlert();
		}, 5000);
	}, []);
	return (
		<div className={`alert ${cname}`}>
			{cname === 'success' && <img src={checkmark} alt='' />} <p>{text}</p>
		</div>
	);
};

export default Alert;
