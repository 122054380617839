import React from 'react';
import { selectModalItem } from '../../redux/actions/trip';
import { connect } from 'react-redux';
import { useCheckType } from '../../Utilities/helperFunctions';

import customSticker from '../../images/noun-sticker-1070910-142350.svg';

const Activity = ({ data, selectModalItem, custom, index }) => {
	const { name, imageUrl, price, type, hotelPickup, title } = data;
	const { activity } = useCheckType(type);

	const handleSelect = () => {
		selectModalItem({ ...data, index });
	};
	const handleHotelPickup = () => {
		if (activity) {
			return (
				<p>
					{hotelPickup ? 'Hotel Pickup Included' : 'Hotel Pickup Not Included'}
				</p>
			);
		}
		return null;
	};
	return (
		<>
			<div className='hotel-image-container'>
				<img
					className={`newcss-item-image${custom ? ' custom' : ''}`}
					style={{
						display: 'block',
					}}
					src={(!custom && imageUrl) || customSticker}
					alt='Activity'
				/>
			</div>
			<div className='newcss-grid-small-item activity'>
				<section>
					<p>{title || name}</p>
					{!custom && price && (
						<p>
							~ ${price.amount}/
							{type === 'group-activity' || type === 'private-transport'
								? 'group'
								: 'person'}
						</p>
					)}
					{handleHotelPickup()}
				</section>
				<button
					className='newcss-item-button'
					onClick={handleSelect}
					value={JSON.stringify(data)}>
					Details
				</button>
			</div>
		</>
	);
};

export default connect(null, { selectModalItem })(Activity);
