import React from 'react';

const HowItWorksSection = ({ image, header, body }) => {
	return (
		<div className='text'>
			<div className='logo-underlay'></div>
			<img src={image} alt='' className='landing__details-image' />
			<h3>{header}</h3>
			<p>{body}</p>
		</div>
	);
};

export default HowItWorksSection;
