import React from 'react';
import { connect } from 'react-redux';
import CategoryButtons from './CategoryButtons';

const ItineraryCategories = ({
	handleSelected,
	selected,
	modalOpen,
	toggleModal,
}) => {
	const handleToggle = (name) => {
		if (!modalOpen) {
			handleSelected(name);
			toggleModal();
		}
		if (modalOpen && name === selected) {
			toggleModal();
		}
		if (modalOpen && name !== selected) {
			handleSelected(name);
		}
	};

	const handleSelectedClass = (value) => {
		return selected === value
			? 'newcss-item-buttons-container-button-selected'
			: '';
	};

	return (
		<div className='newcss-item-buttons-container'>
			<CategoryButtons
				handleToggle={handleToggle}
				handleSelectedClass={handleSelectedClass}
				handleSelected={handleSelected}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	trip: state.trip,
	pathname: state.redirect.pathname,
});

export default connect(mapStateToProps, null)(ItineraryCategories);
