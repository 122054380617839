import React, { useState } from 'react';
import ToolTipVideo from './ToolTipVideo';
import ToolTipDescription from './ToolTipDescription';
import ToolTipButton from './ToolTipButton';
import { AnimatePresence, motion } from 'framer-motion';
import StepCounter from './StepCounter';
import uuid from 'react-uuid';
import leftIconHighlight from '../../video/Rove - Itinerary - 22 March 2023.mp4';
import headerNavigation from '../../video/Rove - Itinerary Headers - 22 March 2023.mp4';
import topRightDetails from '../../video/Rove - Top Right Details - 22 March 2023.mp4';
import itineraryDetails from '../../video/Rove - Itinerary Details - 22 March 2023.mp4';
import { loadAuthUser } from '../../redux/actions/auth';
import { connect } from 'react-redux';
const ToolTipModal = ({ closeModal, showToolTips, loadAuthUser, user }) => {
	const [currentStep, setCurrentStep] = useState(0);

	const steps = [
		{
			url: leftIconHighlight,
			text: `Use the icons on the left hand side of your screen to navigate between flights, hotels, activities, transportation, and creating your own item. If you ever want to collapse the options for more itinerary real estate, use the less-than sign (<) to close and open available trip options.`,
		},
		{
			url: headerNavigation,
			text: `After callapsing the options, there is a week and day filter towards the top left of the itinerary. Use this to filter to a specific day or leave it on the default of the weekly view. The Today, Back and Next button on the right hand side of the itinerary allow you to navigate between weeks or days.`,
		},
		{
			url: topRightDetails,
			text: `The Trip Document text on the top right of your screen will open a pop up that will contain attachments of confirmations after you have authorized an item for purchase. The profile icon allows you to navigate to your preferences, settings, contact support and log out. The cart icon can be selected to navigate to the checkout process. The blue bubble above the cart icon contains the number of items you have your cart.`,
		},
		{
			url: itineraryDetails,
			text: `After adding items to your itinerary, collapse the options to see your entire weekly view. To view an item you added, select the title for more detail on that particular item to appear. Here you can view additional details and remove it from your itinerary and cart if you so choose. Another way to remove it from your cart is select the ‘x' on the bottom right corner of the item on your itinerary. The cart with the plus above it moves the item from your cart checkout into the save for later section.`,
		},
	];

	const changeStep = (num) => {
		setCurrentStep((current) => {
			if (current + num < steps.length && current + num >= 0)
				return current + num;
			else return current;
		});
	};

	return (
		<motion.div
			key={uuid()}
			className='tool-tip__outer'
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			exit={{
				opacity: 0,
			}}
			style={{
				width: '100vw',
				height: '100vh',
				position: 'absolute',
				top: '0',
				left: '0',
				backgroundColor: 'rgba(0, 0, 0, 0.4)',
				zIndex: '1000000',
			}}>
			<div className='tool-tip__inner'>
				<button className='close' onClick={closeModal}>
					+
				</button>
				<AnimatePresence>
					{currentStep === null ? null : (
						<>
							<ToolTipVideo url={steps[currentStep].url} />
							<ToolTipDescription text={steps[currentStep].text} />
						</>
					)}
				</AnimatePresence>
				<div className='tool-tip__nav'>
					<ToolTipButton text='Prev.' onClick={() => changeStep(-1)} />
					<StepCounter currentStep={currentStep + 1} maxStep={steps.length} />
					<ToolTipButton text='Next' onClick={() => changeStep(1)} />
				</div>
				<div className='tool-tip__checkbox'>
					<label htmlFor='showToolTips'>Don't Show Me This Again</label>
					<input
						onChange={() => {
							loadAuthUser({ ...user, showToolTips: !showToolTips });
						}}
						type='checkbox'
						name='showToolTips'
						defaultChecked={!showToolTips}
					/>
				</div>
			</div>
		</motion.div>
	);
};

export default connect(null, { loadAuthUser })(ToolTipModal);
