import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MultiDayTripInputs from './MultiDayTripInputs';
import { addNewHotel } from '../../../redux/actions/trip';
import { addHotelErrorObject } from '../../../redux/actions/errors';
import { AnimatePresence } from 'framer-motion';

const MultiDayTripSection = ({
	hotelDetails,
	loading,
	addNewHotel,
	idealLocation,
	lodgingPrice,
	hotelErrors,
	addHotelErrorObject,
	hasError,
	setErrorSection,
	errorSection,
}) => {
	const returnHotelDetails = () => {
		hotelDetails = hotelDetails.sort((a, b) => {
			if (a.index < b.index) return -1;
			else return 1;
		});

		return hotelDetails.length === 4 && !loading
			? hotelDetails.map((trip) => {
					const { index } = trip;

					const prevHotel = hotelDetails[index - 1];
					const nextHotel = hotelDetails[index + 1];
					return (
						<MultiDayTripInputs
							errors={hotelErrors && hotelErrors.length && hotelErrors[index]}
							key={`multidaytripinputs-index-${index}`}
							hotelInfo={trip}
							index={index}
							currentCity={trip.city}
							currentPriceRange={trip.priceRange}
							suggestedStart={trip.suggestedStartDate}
							suggestedEnd={trip.suggestedEndDate}
							currentRooms={trip.rooms}
							currentStart={trip.startDate}
							currentRadius={trip.radius}
							currentEnd={trip.endDate}
							prevHotel={prevHotel}
							nextHotel={nextHotel}
							minus={true}
						/>
					);
			  })
			: hotelDetails.map((details) => {
					const { index } = details;
					const prevHotel = hotelDetails[index - 1];
					const nextHotel = hotelDetails[index + 1];
					if (index === hotelDetails.length - 1) {
						return (
							<MultiDayTripInputs
								key={`multidaytripinputs-index-${index}`}
								errors={hotelErrors && hotelErrors.length && hotelErrors[index]}
								hotelInfo={details}
								index={index}
								prevHotel={prevHotel}
								nextHotel={nextHotel}
								currentPriceRange={details.priceRange}
								currentRooms={details.rooms}
								currentCity={details.city}
								currentStart={details.startDate}
								suggestedStart={details.suggestedStartDate}
								suggestedEnd={details.suggestedEndDate}
								currentRadius={details.radius}
								currentEnd={details.endDate}
								minus={true}
							/>
						);
					} else {
						return (
							<MultiDayTripInputs
								key={`multidaytripinputs-index-${index}`}
								prevHotel={prevHotel}
								nextHotel={nextHotel}
								hotelInfo={details}
								index={index}
								errors={hotelErrors && hotelErrors.length && hotelErrors[index]}
								currentCity={details.city}
								currentPriceRange={details.priceRange}
								currentRooms={details.rooms}
								currentStart={details.startDate}
								suggestedStart={details.suggestedStartDate}
								suggestedEnd={details.suggestedEndDate}
								currentRadius={details.radius}
								currentEnd={details.endDate}
								minus={true}
							/>
						);
					}
			  });
	};

	useEffect(() => {
		if (hotelDetails.length === 0)
			addNewHotel({
				city: '',
				startDate: '',
				endDate: '',
				radius: idealLocation,
				rooms: 1,
				priceRange: lodgingPrice,
				flightIndex: 0,
			});
		addHotelErrorObject();
	}, [hotelDetails.length]);

	useEffect(() => {
		if (hasError) {
			let hotelValid = true;
			hotelDetails.forEach((hotel, index) => {
				if (!hotel.city) {
					hotelValid = false;
				}
				if (!hotel.startDate) {
					hotelValid = false;
				}
				if (!hotel.endDate) {
					hotelValid = false;
				}
				if (hotel.radius < 0) {
					hotelValid = false;
				}

				// Fix end dates
				// if (index !== 0 && index !== hotelDetails.length - 1) {
				// 	hotel.endDate = hotelDetails[index + 1].startDate;
				// }
			});
			if (hotelValid) setErrorSection({ ...errorSection, hotel: false });
		}
	}, [hotelDetails]);
	return (
		<Fragment>
			<section className={`trip__form-card${hasError ? ' error' : ''}`}>
				<h2 className='trip__form-card-header'>HOTELS </h2>
				<p className='trip__form-card-description'>
					Include any other cities or towns you would like to explore and spend{' '}
					<strong>more than a single day</strong> visiting. An example would be
					Cinque Terre on your Rome trip or Nice on your Paris trip.
				</p>

				<AnimatePresence>{returnHotelDetails()}</AnimatePresence>
			</section>
		</Fragment>
	);
};

MultiDayTripSection.propTypes = {
	hotelDetails: PropTypes.array,
	loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	hotelDetails: state.trip.hotelDetails,
	loading: state.trip.loading,
	idealLocation: state.preferences.idealLocation,
	lodgingPrice: state.preferences.lodgingPrice,
	hotelErrors: state.errors.hotelErrors,
});

export default connect(mapStateToProps, { addNewHotel, addHotelErrorObject })(
	MultiDayTripSection
);
