import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	returnKey,
	toDollarStyle,
	useCheckType,
} from '../../Utilities/helperFunctions';

const CartItem = ({
	item,
	name,
	type,
	total,
	image,
	adults,
	children,
	infants,
	amountPer,
	inCart,
	unitAmount,
	perName,
	handleMoveTo,
	handleRemoveFrom,
}) => {
	const { activity, lodging, transportation, flight, amadeusCars } =
		useCheckType(type);
	const key = returnKey({
		activity,
		lodging,
		transportation,
		flight,
		amadeusCars,
	});

	return (
		<div className='cart-item'>
			<img src={image} alt='' />
			<section>
				<div className='main-info'>
					<span>{name}</span>
					<div className='prices'>
						{!amadeusCars && (
							<span>
								{activity || transportation ? `Qty: ${unitAmount}` : null}
								{lodging && `# of Rooms: ${unitAmount}`}
								{flight && `# of Travelers: ${children + infants + adults}`}
							</span>
						)}
						<span>
							Price Per {perName}*: {toDollarStyle(amountPer, 'USD')}
						</span>
						<span>
							Total:
							{activity || transportation ? '~ ' : ''}
							{toDollarStyle(total, 'USD')}{' '}
						</span>
					</div>
				</div>
				<div className='buttons'>
					<button onClick={() => handleRemoveFrom(item, key)}>Remove</button>
					{
						<button onClick={() => handleMoveTo(item, key)}>
							{inCart ? 'Save for Later' : 'Move to Cart'}
						</button>
					}
				</div>
			</section>
		</div>
	);
};

CartItem.propTypes = {
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
};

const mapStateToProps = (state) => ({
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, null)(CartItem);
