const initialState = {
	token: localStorage.getItem('authToken'),
	isAuthenticated: null,
	loading: true,
	newUser: false,
	redirect: '',
	user: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case 'USER_LOADED':
			return {
				...state,
				token: localStorage.getItem('authToken'),
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		case 'SET_REGISTER_FALSE':
			return {
				...state,
				newUser: false,
			};
		case 'VERIFY_USER':
			return {
				...state,
				user: payload,
			};
		case 'HANDLE_TRAVEL_DOCUMENT':
			return {
				...state,
				user: {
					...state.user,
					travelerDocument: state.user.travelerDocument
						? {
								...state.user.travelerDocument,
								[payload.key]: payload.value,
						  }
						: {
								[payload.key]: payload.value,
						  },
				},
			};
		case 'ADD_USER_TRIP':
			return {
				...state,
				user: {
					...state.user,
					trips: [...state.user.trips, payload],
				},
			};
		case 'UPDATE_CONTACT':
			return {
				...state,
				user: {
					...state.user,
					...payload,
				},
			};
		case 'UPDATE_USER_TRIP':
			return {
				...state,
				user: {
					...state.user,
					trips: state.user.trips.map((trip) =>
						trip._id === payload._id ? payload : trip
					),
				},
			};
		case 'HANDLE_USER_TEXT':
			return {
				...state,
				user: {
					...state.user,
					[payload.key]: payload.value,
				},
			};
		case 'REGISTER_CONFIRMED':
			return {
				...state,
				token: localStorage.getItem('authToken'),
				isAuthenticated: true,
				newUser: true,
				loading: false,
			};
		case 'REGISTER_DENIED':
		case 'AUTH_DENIED':
		case 'LOGIN_DENIED':
		case 'LOGOUT':
			return {
				...state,
				user: null,
				token: null,
				isAuthenticated: false,
				loading: false,
			};
		case 'UPDATE_USER':
			return {
				...state,
				user: {
					...state.user,
					...payload,
				},
			};
		case 'UPDATE_USER_TRIPS':
			return {
				...state,
				user: {
					...state.user,
					trips: [...state.user.trips, payload],
				},
			};
		// case 'REMOVE_REDIRECT':
		// 	return { ...state, redirect: null };
		case 'LOGIN_CONFIRMED':
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		default:
			return state;
	}
}
