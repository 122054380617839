import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FlightPreferences from '../Preferences/FlightPreferences';
import LodgingPreferences from '../Preferences/LodgingPreferences';
import TransportationPreferences from '../Preferences/TransportationPreferences';
import ActivityPreferences from '../Preferences/ActivityPreferences';
import { connect } from 'react-redux';
import {
	addPreferences,
	getUserPreferences,
	loadPreferences,
	updatePreferences,
} from '../../redux/actions/preferences';
import requireAuth from '../hoc/AuthComponent';
import redirectHOC from '../hoc/RedirectHOC';
import { Redirect } from 'react-router-dom';
// import preferences from '../../redux/reducers/preferences';

const EditPreferences = ({
	getUserPreferences,
	preferences,
	updatePreferences,
}) => {
	const [loading, setLoading] = useState(false);
	const [submitSuccess, setSubmitSuccess] = useState(false);

	const submitPreferences = async () => {
		setLoading(true);

		const res = await updatePreferences(preferences);
		if (res) setSubmitSuccess(true);
	};
	useEffect(() => {
		if (!preferences._id) {
			getUserPreferences();
		}
	}, [getUserPreferences, preferences._id]);

	if (submitSuccess) {
		return <Redirect to='/itinerary' />;
	}
	return (
		<div className='edit-preferences'>
			<FlightPreferences edit={true} />
			<LodgingPreferences edit={true} />
			<TransportationPreferences edit={true} />
			<ActivityPreferences edit={true} submitPreferences={submitPreferences} />
		</div>
	);
};

EditPreferences.propTypes = {
	loadPreferences: PropTypes.func.isRequired,
	user: PropTypes.object,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
	preferences: state.preferences,
});

export default connect(mapStateToProps, {
	loadPreferences,
	getUserPreferences,
	updatePreferences,
})(redirectHOC(requireAuth(EditPreferences)));
