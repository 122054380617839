import React from 'react';

const ToolTipVideo = ({ url }) => {
	return (
		<video controls loop autoPlay>
			<source src={url} type='video/mp4' />
		</video>
	);
};

export default ToolTipVideo;
