import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setAuthError, removeAuthError } from '../../redux/actions/errors';
// import validator from 'validator';
// import { errorStrings } from '../../config/errors';
import ErrMessage from '../Layout/ErrMessage';
import { setErrorFields } from '../../redux/actions/alert';
import {
	canBeSubmitted,
	handlePasswordStatus,
	handleSamePassword,
} from '../../Utilities/authUtils';
const authFormHOC = (WrappedComponent) => {
	const AuthFormComponent = ({
		setAuthError,
		removeAuthError,
		authErrors,
		...props
	}) => {
		const [formData, setFormData] = useState(null);
		const handleChange = (e) => {
			const { id, value } = e.target;
			if (value) {
				removeAuthError(id);
			}
			if (id === 'newsletter') {
				setFormData({
					...formData,
					[e.target.id]: e.target.checked,
				});
			} else {
				setFormData({
					...formData,
					[e.target.id]: e.target.value,
				});
			}
		};

		const setError = (boolean, key, message) => {
			if (!boolean) {
				setAuthError(key, message);
			}
			return boolean;
		};

		const returnError = (key) => {
			return authErrors && authErrors[key] ? (
				<ErrMessage text={authErrors[key]} />
			) : null;
		};
		useEffect(() => {
			return function cleanUp() {
				setFormData(null);
			};
		}, []);

		return (
			<WrappedComponent
				setErrorFields={setErrorFields}
				setAuthError={setAuthError}
				removeAuthError={removeAuthError}
				canBeSubmitted={() => canBeSubmitted(setError, formData)}
				authErrors={authErrors}
				handleChange={handleChange}
				handlePasswordStatus={handlePasswordStatus}
				handleSamePassword={handleSamePassword}
				returnError={returnError}
				formData={formData}
				setFormData={setFormData}
				{...props}
			/>
		);
	};

	const mapStateToProps = (state) => ({
		authErrors: state.errors.authErrors,
	});

	return connect(mapStateToProps, {
		setAuthError,
		removeAuthError,
	})(AuthFormComponent);
};

export default authFormHOC;
