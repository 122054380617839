import React, { Fragment } from 'react';

import ecommerceLogo from '../../images/ecommerceLogo.png';
import itineraryLogo from '../../images/itineraryLogo.png';
import bulletinLogo from '../../images/bulletinLogo.png';
import planLogo from '../../images/planLogo.png';
import HowItWorksSection from './HowItWorksSection';

const HowItWorks = () => {
	return (
		<div className='landing__details'>
			<h4 className='landing__details-title' style={{ textAlign: 'center' }}>
				<strong>How It Works</strong>
			</h4>
			<div className='LP-1'>
				<HowItWorksSection
					image={itineraryLogo}
					header='1. Share trip details'
					body='Set your budget, lodging and activity preferences to help our travel
				advisors provide you the perfect trip options.'
				/>

				<HowItWorksSection
					image={bulletinLogo}
					header='2. Explore a curated list'
					body='Receive personalized options for flights, hotels, transportation and
				activities based on your travel desires for a $75 curation fee.'
				/>

				<HowItWorksSection
					image={planLogo}
					header='3. Plug and play!'
					body='Play with these personalized options by placing them onto your
				itinerary creating a customized vacation.'
				/>

				<HowItWorksSection
					image={ecommerceLogo}
					header='4. Shop a la carte'
					body='When ready to book, simply check-out and pay for chosen options and
				final $75 booking fee.'
				/>
			</div>
		</div>
	);
};

export default HowItWorks;
