import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const LodgingDates = ({ selectedItem, descriptive_info }) => {
	const returnCheckinCheckout = () => {
		const { RoomRates } = selectedItem.data;
		const { Rate } = RoomRates[0]['RoomRate'][0]['Rates'][0];
		let policy =
			descriptive_info['Policies'] &&
			descriptive_info['Policies'][0]['Policy'].find(
				(item) => item['PolicyInfo']
			);
		policy =
			policy &&
			policy['PolicyInfo'] &&
			policy['PolicyInfo'].length &&
			policy['PolicyInfo'][0];
		return policy ? (
			<div className='checkin-checkout'>
				<div>
					<span>
						<span className='title'>Check In Time:</span>
						<span className='time'>
							{Rate[0].info.EffectiveDate}{' '}
							{policy && policy.info['CheckInTime']}
						</span>
					</span>
				</div>
				<div>
					<span>
						<span className='title'>Check Out Time: </span>
						<span className='time'>
							{Rate[Rate.length - 1]['Total']
								? Rate[Rate.length - 2].info.ExpireDate
								: Rate[Rate.length - 1].info.ExpireDate}{' '}
							{policy && policy.info['CheckOutTime']}
						</span>
					</span>
				</div>
			</div>
		) : (
			<div className='checkin-checkout'>
				<div>
					<span className='title'>Check In Date:</span>
					<span className='time'>{Rate[0].info.EffectiveDate}</span>
				</div>
				<div>
					<span className='title'>Check Out Date: </span>
					<span className='time'>
						{Rate[Rate.length - 1]['Total']
							? Rate[Rate.length - 2].info.ExpireDate
							: Rate[Rate.length - 1].info.ExpireDate}
					</span>
				</div>
			</div>
		);
	};
	return <div className='reservation-section'>{returnCheckinCheckout()}</div>;
};

LodgingDates.propTypes = {
	selectedItem: PropTypes.object,
};
const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, null)(LodgingDates);
