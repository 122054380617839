import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MultiFlightTrip from './MultiFlightTrip';
import RoundTrip from './RoundTrip';
import {
	addEmptyFlight,
	handleText,
	removeAllFlights,
	removeHotelByFlightIndex,
	setFlightType,
	updateFlightDetails,
} from '../../../redux/actions/trip';
import FlightToggler from './FlightToggler';
import { addFlightErrorObject } from '../../../redux/actions/errors';
import Error from '../Error';

const TripProfileFlight = ({
	flightError,
	returnDateError,
	flightDetails,
	_id,
	addEmptyFlight,
	addFlightErrorObject,
	hasError,
	setErrorSection,
	errorSection,
	returnDate,
	setFlightType,
	flightType,
	updateFlightDetails,
	handleText,
	removeHotelByFlightIndex,
}) => {
	const handleRemoveErrorBorder = () => {
		let flightValid = true;
		flightDetails.forEach((flight, index) => {
			if (!flight.departure) {
				flightValid = false;
			}
			if (!flight.destination) {
				flightValid = false;
			}
			if (!flight.departureDate) {
				flightValid = false;
			}
		});

		if (flightDetails.length === 1 && !returnDate) {
			flightValid = false;
		}
		if (flightValid) setErrorSection({ ...errorSection, flight: false });
	};
	useEffect(() => {
		if (_id && flightDetails.length === 0) {
			addEmptyFlight();
			addFlightErrorObject();
		}

		if (hasError) {
			handleRemoveErrorBorder();
		}
	}, [_id, flightDetails.length]);

	useEffect(() => {
		if (hasError) {
			handleRemoveErrorBorder();
		}
	}, [flightDetails]);

	const leftClick = () => {
		if (flightType === 'multi-city') {
			removeHotelByFlightIndex({ 1: true, 2: true });
			const newDetails = [flightDetails[0]];
			const newReturnDate =
				flightDetails[flightDetails.length - 1].departureDate;
			updateFlightDetails(newDetails);
			handleText('returnDate', newReturnDate);

			// removeAllFlights();
			setFlightType('round-trip');
		}
	};

	const rightClick = () => {
		if (flightType === 'round-trip') {
			addEmptyFlight(flightDetails[0].destination, '', returnDate);
			handleText('returnDate', null);
			// removeAllFlights();
			setFlightType('multi-city');
		}
		// clearFlightErrors();
	};

	const errors = (
		<>
			{flightError && <Error text='Please fill out at least one flight.' />}
			{returnDateError && (
				<Error text='Please enter a valid return date (mm/dd/yyyy).' />
			)}
		</>
	);

	useEffect(() => {
		if (!flightType && flightDetails.length < 2) {
			setFlightType('round-trip');
		}
		if (!flightType && flightDetails.length > 1) {
			setFlightType('multi-city');
		}
	}, []);

	return (
		<Fragment>
			<section className={`trip__form-card${hasError ? ' error' : ''}`}>
				<h2 className='trip__form-card-header'>FLIGHTS</h2>

				<FlightToggler
					leftTitle={'Round Trip'}
					rightTitle={'Multi-City Trip'}
					leftValue={'roundTrip'}
					rightValue={'multiCityTrip'}
					leftSelected={flightType === 'round-trip'}
					rightSelected={flightType === 'multi-city'}
					leftClick={leftClick}
					rightClick={rightClick}
					errors={errors}
				/>
				{flightType === 'round-trip' ? <RoundTrip /> : <MultiFlightTrip />}
			</section>
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	_id: state.trip._id,
	errors: state.trip.errors,
	flightDetails: state.trip.flightDetails,
	hotelDetails: state.trip.hotelDetails,
	returnDate: state.trip.returnDate,
	flightType: state.trip.flightType,
});

export default connect(mapStateToProps, {
	removeAllFlights,
	addEmptyFlight,
	addFlightErrorObject,
	setFlightType,
	updateFlightDetails,
	handleText,
	removeHotelByFlightIndex,
})(TripProfileFlight);
