import friendsPurple from '../images/friends_purple.png';
import familyPurple from '../images/family_purple.png';
import selfPurple from '../images/person_purple.png';
import purpleHearts from '../images/noun-two-hearts-1927945-17234D.svg';
import whiteHearts from '../images/noun-two-hearts-1927945-FFFFFF.svg';
import friendsWhite from '../images/friends_white.png';
import familyWhite from '../images/family_white.png';
import selfWhite from '../images/person_white.png';

import rejectWhite from '../images/noun-reject-1890961-FFFFFF.svg';
import rejectPurple from '../images/noun-reject-1890961-142350.svg';

import inProgressWhite from '../images/noun-in-progress-556632-FFFFFF.svg';
import inProgressPurple from '../images/noun-in-progress-556632-142350.svg';

import doneWhite from '../images/noun-done-1195562-FFFFFF.svg';
import donePurple from '../images/noun-done-1195562-142350.svg';

import flight from '../images/flight-black.svg';
import hotel from '../images/hotel-black.svg';
import activity from '../images/activity-black.svg';
import transportation from '../images/transportation-black.svg';

export default {
	//LINKS //////////////////

	WYG: {
		flights: {
			title: 'Flights',
			description:
				'A minimum of - flights will be selected for you based on your preferences.',
			icon: flight,
			roundIconAmount: 4,
			multiIconAmount: 4,
		},
		lodging: {
			title: 'Lodging',
			description: 'At least - lodging options per - will be made available.',
			icon: hotel,
			roundIconAmount: 5,
			multiIconAmount: 3,
		},
		activities: {
			title: 'Activities',
			description:
				'No less than - activities - will be hand selected based on your preferences and trip profile details.',
			icon: activity,
			roundIconAmount: 8,
			multiIconAmount: 4,
		},
		transportation: {
			title: 'Transportation',
			description: 'There will be a minimum of - transportation options per -.',
			icon: transportation,
			roundIconAmount: 2,
			multiIconAmount: 2,
		},
	},
	SignedInLinks: {
		home: '/',
		cart: '/cart/:tripId',
		Cart: 'Cart',
		itinerary: 'itinerary',
		Itinerary: 'Start My Trip!',
		ItineraryPaid: 'Enjoy Your Trip!',
		settings: '/settings',
		purchase: '/purchase/',
		Purchase: 'Start My Trip!',
		createTrip: '/create-trip',
		CreateTrip: 'Create New Trip',
		Logout: 'Log Out',
		preferences: '/my-preferences/',
		editPreferences: '/edit-preferences',
		Preferences: 'My Preferences',
		preferencesKickOff: '/my-preferences-begin',
		PreferencesKickOff: 'asdf',
		userinfo: '/userinfo/',
		contact: '/contact/',
		Contact: 'Support',
		trip: '/trip',
		Trip: 'Trips',
		planTrip: 'plan-trip/:id',
		PlanTrip: 'Plan Trip',
		wait: '/wait/',
	},
	cacheTime: 15,
	emailMeURL: process.env.REACT_APP_EMAILMEFORM,
	SignedOutLinks: {
		signup: '/sign-up/',
		Signup: 'Sign Up',
		login: '/sign-in/',
		Login: 'Sign In',
	},
	activityTypeText: [
		{ cname: 'beach', text: 'Beach' },
		{ cname: 'active', text: 'Active' },
		{ cname: 'restuarants', text: 'Wine & Dine' },
		{ cname: 'spa', text: 'Spa' },
		{ cname: 'sightseeing', text: 'Sightseeing' },
		{ cname: 'relax', text: 'Relax' },
		{ cname: 'pool', text: 'Pool time' },
		{ cname: 'shopping', text: 'Shopping' },
		{ cname: 'hiking', text: 'Hiking' },
		{ cname: 'entertainment', text: 'Shows/Entertainment' },
	],
	tripTypes: [
		{
			imageWhite: whiteHearts,
			imagePurple: purpleHearts,
			text: `Couple's Getaway`,
			cname: 'romantic',
		},
		{
			imageWhite: friendsWhite,
			imagePurple: friendsPurple,
			text: 'Friends Trip',
			cname: 'friends',
		},
		{
			imageWhite: familyWhite,
			imagePurple: familyPurple,
			text: 'Family Vacation',
			cname: 'family',
		},
		{
			imageWhite: selfWhite,
			imagePurple: selfPurple,
			text: 'Solo Adventure',
			cname: 'self',
		},
	],
	support: [
		{
			value: 0,
			title: `I'm all set!`,
			text: `My dates, locations and order of items is accurate with no changes or suggestions needed`,
			imageWhite: doneWhite,
			imagePurple: donePurple,
		},
		{
			value: 1,
			title: 'Review Needed',
			text: 'Please review my trip information and make sure it is optimized for a perfect trip',
			imageWhite: inProgressWhite,
			imagePurple: inProgressPurple,
		},
		{
			value: 2,
			title: 'I Need Help!',
			text: 'I need help! I would love feedback and support to plan the perfect trip. I filled out the form the best I could but dates, location and order of items can be adjusted based on Rove’s suggestions or feedback',
			imageWhite: rejectWhite,
			imagePurple: rejectPurple,
		},
	],

	//CMS
	CMS: {
		trip: {
			firstTrip: {
				h1: 'Let’s plan your first trip!',
				h2: 'Choose your package.',
				ul: [
					'Fill out a trip profile so that we can curate the perfect trip based on your travel wants!',
					'Receive hotel, flight, activities, transportation options based on your preferences',
					'Choose what you’d like to add to your itinerary à la cart.',
					'$100 curation fee applied to your purchase.',
				],
				ul1: [
					'Fill out a trip profile so that we can curate the perfect trip based on your travel wants!',
					'Receive activities options based on your preferences',
					'Choose what you’d like to add to your itinerary à la cart.',
					'$100 curation fee applied to your purchase.',
				],
				button: "Let's get started!",
				button1: 'Get started!',
				tier0: 'Rove Lite',
				tier1: 'Rove Full',
				strike0: '$79',
				strike1: '$129',
				price0: '$49',
				price1: '$99',
			},
			h1: '',
		},
		createTripForm: {
			h1: 'Trip Profile',
			h2: [
				'Flight',
				'Want to do any day trips?',
				'Travelers',
				'Type of Travel',
				'Number of Travelers',
			],
			p: 'Fill out the form below so that we can help you plan a memorable trip.',
			descriptions: [
				'Beach',
				'Active',
				'Wine & Dine',
				'Spa',
				'Sightseeing',
				'Relax',
				'Pool time',
				'Shopping',
				'Hiking',
				'Shows/Entertainment',
			],
		},
	},
	FLIGHTPREFERENCES: {
		flightPrice: {
			id: 'flightPrice',
			title: 'What is your pricing preference?',
			style: 'full-width',
			children: [
				{
					title: 'Lowest possible fare',
					subtitle: null,
				},
				{
					title: 'Direct flight when possible',
					subtitle: null,
				},
				{
					title:
						"I'll pay a fair price to get there optimally, but I don't want to overspend",
					subtitle: null,
				},
			],
		},
		seating: {
			id: 'seating',
			title: 'Where do you like to sit on the plane?',
			style: 'grid-4',
			children: [
				{
					title: 'Front',
					subtitle: null,
				},
				{
					title: 'Middle',
					subtitle: null,
				},
				{
					title: 'Back',
					subtitle: null,
				},
				{
					title: 'No Preference',
					subtitle: null,
				},
			],
		},
		seatLocation: {
			id: 'seatLocation',
			title: 'What is your seat preference?',
			style: 'grid-3',
			children: [
				{
					title: 'Window',
					subtitle: null,
				},
				{
					title: 'Aisle',
					subtitle: null,
				},
				{
					title: 'No Preference',
					subtitle: null,
				},
			],
		},
		fareType: {
			id: 'fareType',
			title: 'What is your preferred fare type?',
			style: 'full-width',
			children: [
				{
					title: 'Basic Economy',
					price: '$',
					seatSelection: false,
					checkedBags: false,
					seatDetails: 'Standard',
				},
				{
					title: 'Economy',
					price: '$$',
					seatSelection: true,
					checkedBags: true,
					seatDetails: 'Standard',
				},
				{
					title: 'Premium Economy',
					price: '$$$',
					seatSelection: true,
					checkedBags: true,
					seatDetails: 'Standard',
				},
				{
					title: 'Business Class',
					price: '$$$$',
					seatSelection: true,
					checkedBags: true,
					seatDetails: 'Extra Leg Room and Wider Seats',
				},
				{
					title: 'First Class',
					price: '$$$$',
					seatSelection: true,
					checkedBags: true,
					seatDetails: 'Lie-flat Seat',
				},
			],
		},
		tripInsurance: {
			id: 'tripInsurance',
			title: 'Do you want to include trip insurance?',
			style: 'grid-3',
			children: [
				{
					title: 'Yes',
					subtitle: null,
				},
				{
					title: 'No',
					subtitle: null,
				},
				{
					title: "I'm not sure, yet",
					subtitle: null,
				},
			],
		},
	},

	LODGINGPREFERENCES: {
		lodgingPrice: {
			id: 'lodgingPrice',
			title: 'What is your preferred lodging price point?',
			style: 'full-width',
			children: [
				{
					title: 'Cheaper is better',
					subtitle: 'Less than $150',
					value: { min: '0.00', max: '150.00' },
				},
				{
					title: 'Decent place that is reasonably priced',
					subtitle: 'Between $150 and $250',
					value: { min: '150.00', max: '250.00' },
				},
				{
					title: 'Hit me up with the life of luxury',
					subtitle: 'Over $250',
					value: { min: '250.00', max: '0.00' },
				},
			],
		},
		idealLocation: {
			id: 'idealLocation',
			title: 'What is your ideal lodging location?',
			style: 'full-width',
			children: [
				{
					title: 'Heart of the city/town',
					subtitle: 'less than 5 miles from city center',
					value: 5,
				},
				{
					title: 'Slightly removed from the heart of the action',
					subtitle: 'Between 5 and 10 miles from city center',
					value: 10,
				},
				{
					title: "I don't mind commuting to save dollars on lodging",
					subtitle: 'Over 10 miles from city center',
					value: 20,
				},
			],
		},
		lodgingType: {
			id: 'lodgingType',
			title: 'What is your ideal type of lodging?',
			style: 'full-width',
			children: [
				{
					title: 'I love hotel chains',
					subtitle: null,
				},
				{
					title: 'Boutique, boutique, boutique!',
					subtitle: null,
				},
				{
					title: "I'm happy with either depending on price and location",
					subtitle: null,
				},
			],
		},
	},

	TRANSPORTPREFERENCES: {
		driveVsDriver: {
			id: 'driveVsDriver',
			title: 'Do you prefer to drive or be driven when you travel?',
			style: 'full-width',
			children: [
				{
					title: 'I prefer to drive myself',
					subtitle: null,
				},
				{
					title: 'I prefer to be driven in a foreign city',
					subtitle: null,
				},
			],
		},
		publicVsPrivate: {
			id: 'publicVsPrivate',
			title: 'How do you like to get around when you travel?',
			style: 'full-width',
			children: [
				{
					title:
						'I prefer public transportation when possible (i.e. trains & buses)',
					subtitle: null,
				},
				{
					title: 'I prefer a private car/van transfer',
					subtitle: null,
				},
			],
		},
		publicTransportationPreferences: {
			id: 'publicTransportationPreferences',
			title: 'What is your preferred mode of public transportation?',
			style: 'grid-4',
			children: [
				{
					title: 'Buses',
					subtitle: null,
				},
				{
					title: 'Trains',
					subtitle: null,
				},
				{
					title: 'Whatever gets me there the quickest',
					subtitle: null,
				},
				{
					title: 'Whatever is the cheapest',
					subtitle: null,
				},
			],
		},
	},

	ACTIVITYPREFERENCES: {
		tours: {
			id: 'tours',
			title: 'What types of tours are you interested in?',
			multiSelect: true,
			style: 'grid-3',
			children: [
				{
					title: 'Sightseeing',
					subtitle: null,
				},
				{
					title: 'Shore Excursion',
					subtitle: null,
				},
				{
					title: 'Food',
					subtitle: null,
				},
				{
					title: 'History',
					subtitle: null,
				},
				{
					title: 'Architectural',
					subtitle: null,
				},
				{
					title: 'Art',
					subtitle: null,
				},
			],
		},
		sightSeeingTours: {
			id: 'sightSeeingTours',
			title: 'What length and depth of tours would you like to see offered?',
			style: 'full-width',
			children: [
				{
					title: 'I prefer all day tours to really pack in the sights',
					subtitle: null,
				},
				{
					title: 'I like a tour but half day max',
					subtitle: null,
				},
				{
					title: 'I prefer tours to be specific and directed, not a catch all',
					subtitle: null,
				},
			],
		},
		physicalActivity: {
			id: 'physicalActivity',
			title: 'How do you feel about physical activities when traveling?',
			style: 'full-width',
			children: [
				{
					title:
						'I love physical activity on my trips (i.e. scuba diving, hiking, waterfall repelling, etc.)',
					subtitle: null,
				},
				{
					title:
						"I want to see a few physical activity options, but don't go crazy. I am on vacation",
					subtitle: null,
				},
				{
					title: "Hard pass on the physical activity. I'm here for some R&R",
					subtitle: null,
				},
			],
		},
		activitySamples: {
			id: 'activitySamples',
			title:
				'Would you be interested in any of the following activities when traveling?',
			style: 'grid-4',
			multiSelect: true,
			children: [
				{
					title: 'Concerts',
					subtitle: null,
				},
				{
					title: 'Theater Performances',
					subtitle: null,
				},
				{
					title: 'Sporting Events',
					subtitle: null,
				},
				{
					title: 'Festivals',
					subtitle: null,
				},
			],
		},
	},
};
