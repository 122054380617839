import React, { useEffect } from 'react';
import { setRedirect } from '../../redux/actions/redirect';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ErrorPage = ({ setRedirect, pathname }) => {
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setRedirect('/itinerary');
	// 	}, 4000);
	// }, []);

	// if (pathname) return <Redirect to={pathname} />;

	return (
		<div className='error-window'>
			<main>
				<h2>Oops, something went wrong. We are working on it.</h2>
				<button
					onClick={() => {
						if (window.location.pathname === '/itinerary') {
							window.location.reload(false);
						} else {
							setRedirect('/itinerary');
						}
					}}>
					Let's try this again
				</button>
			</main>
		</div>
	);
};
const mapStateToProps = (state) => ({
	pathname: state.redirect.pathname,
});

export default connect(mapStateToProps, { setRedirect })(ErrorPage);
