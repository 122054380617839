import React, { Component } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import config from '../../config/config';

export default class Wait extends Component {
	render() {
		return (
			<div className='newcss-wait-container'>
				<div className='newcss-wait'>
					<IoIosCheckmarkCircle style={{ color: '#00E388' }} />
					<h2>Well done!</h2>
					<p>
						Our talented travel advisors will create a customized trip based on
						your preferences and trip profile. Yout trip details will be
						available in 48 hours. We’ll send you an email once they’re ready.
					</p>
					<p>
						Need support? <a href={config.SignedInLinks.contact}>Support</a>
					</p>
				</div>
			</div>
		);
	}
}
