import React from 'react';
import FormInput from './FormInput';

const CreateAccountInputs = ({
	handleChange,
	returnError,
	handlePasswordStatus,
	handleSamePassword,
	password1,
	password2,
	newsletter,
}) => {
	const passwordRequirements = (
		<div className='info-list'>
			<p>Password must contain:</p>
			<ul>
				<li>at least 7 characters</li>
				<li>1 uppercase and 1 lowercase character</li>
				<li>1 special character</li>
			</ul>
		</div>
	);
	return (
		<>
			<FormInput
				label='First Name'
				handleChange={handleChange}
				alert={returnError('firstName')}
				id={'firstName'}
				type='text'
			/>

			<FormInput
				label='Last Name'
				handleChange={handleChange}
				alert={returnError('lastName')}
				id={'lastName'}
				type='text'
			/>

			<FormInput
				label='Email'
				handleChange={handleChange}
				alert={returnError('email')}
				id='email'
				type='email'
			/>

			<FormInput
				label='Password'
				handleChange={handleChange}
				alert={returnError('password1')}
				hoverItem={passwordRequirements}
				id='password1'
				type='password'
				status={handlePasswordStatus(password1)}
			/>

			<FormInput
				label='Retype Password'
				handleChange={handleChange}
				alert={returnError('password2')}
				id='password2'
				type='password'
				status={handleSamePassword(password1, password2)}
			/>
			<label>
				<input
					type='checkbox'
					id='newsletter'
					checked={newsletter}
					onChange={handleChange}
				/>{' '}
				Sign up for our newsletter!
			</label>
		</>
	);
};

export default CreateAccountInputs;
