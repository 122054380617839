import React, { useEffect, useState } from 'react';
import { set } from 'react-ga';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { logout, verifyMe } from '../../redux/actions/auth';
import { setRedirect } from '../../redux/actions/redirect';
import { clearTrip } from '../../redux/actions/trip';

const requireAuth = (WrappedComponent) => {
	const AuthenticatedComponent = ({
		location,
		setRedirect,
		clearTrip,
		logout,
		match,
		authUser,
		userProfile,
		pathname,
		verifyMe,
		...props
	}) => {
		const [isAdmin, setIsAdmin] = useState(false);
		const params = useParams();
		const returnDocumentTitle = (pathname, params) => {
			if (pathname) {
				let prefix = 'Rove ';
				let preferences = pathname.includes('-preferences')
					? ' - Preferences'
					: '';
				let createTrip = pathname.includes('create-trip')
					? ' - Trip Profile'
					: '';
				let editTrip = pathname.includes('edit-trip/')
					? ' - Edit Trip Profile'
					: '';
				let planTrip = pathname.includes('plan-trip/') ? ' - Itinerary' : '';
				let itinerary = pathname.includes('/itinerary') ? ' - Dashboard' : '';
				let cart = pathname.includes('/cart') ? ' - Checkout' : '';
				const isAdmin = pathname.includes('admin') ? 'Admin' : '';

				let home = pathname === '/' ? 'Homepage' : null;
				let result =
					prefix +
					isAdmin +
					(preferences ||
						createTrip ||
						home ||
						editTrip ||
						planTrip ||
						cart ||
						itinerary);

				return result;
			} else {
				return false;
			}
		};

		const currentTitle = returnDocumentTitle(window.location.pathname, params);
		document.title = currentTitle || document.title;

		const verifyUser = async () => {
			const response = await verifyMe(
				params?.verificationToken,
				params?.userId
			);
			console.log('response', response);
			if (!response) {
				clearTrip();
				logout();
				setRedirect('/');
			}
		};
		useEffect(() => {
			if (params?.verificationToken && params?.userId) {
				console.log('is verifying user');
				verifyUser();
			}
			if (!localStorage.getItem('authToken')) {
				clearTrip();
				logout();
				setRedirect('/');
			}
			if (
				location &&
				location.pathname &&
				location.pathname.slice(0, 6) === '/admin'
			) {
				setIsAdmin(authUser && authUser.role === 'admin');
			}

			if (userProfile && authUser) {
				if (authUser.role !== 'admin' && userProfile._id !== authUser._id) {
					clearTrip();
					logout();
					setRedirect('/');
				}
			}
		}, [location]);

		if (pathname) {
			return <Redirect to={pathname} />;
		}

		return (
			<WrappedComponent
				{...props}
				authUser={authUser}
				userProfile={userProfile}
				location={location}
				isAdmin={isAdmin}
				clearTrip={clearTrip}
			/>
		);
	};

	const mapStateToProps = (state) => ({
		pathname: state.redirect.pathname,
		authUser: state.auth.user,
		userProfile: state.profile.userProfile,
	});

	return connect(mapStateToProps, {
		setRedirect,
		clearTrip,
		logout,
		verifyMe,
	})(AuthenticatedComponent);
};

export default requireAuth;
