import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { addActivity, removeActivity } from '../../../redux/actions/trip';
import ErrMessage from '../../Layout/ErrMessage';
import { useEffect } from 'react';
import { setActivityThemeError } from '../../../redux/actions/errors';
import config from '../../../config/config';

const ActivityTypes = ({
	activityThemes,
	addActivity,
	removeActivity,
	activityThemeError,
	setActivityThemeError,
}) => {
	const handleActivity = (activity) => {
		const activityIncluded = activityThemes.includes(activity);
		const lessThanMax = activityThemes.length < 4;
		if (!activityIncluded && lessThanMax) {
			addActivity(activity);
		} else if (activityIncluded) {
			removeActivity(activity);
		}
	};

	const returnActivityTypes = () => {
		return config.activityTypeText.map((type, index) => {
			const { cname, text } = type;
			return (
				<button
					key={`activity-types-${index}`}
					id={text}
					onClick={() => handleActivity(text)}
					className={
						activityThemes.includes(text)
							? 'newcss-selected-button' + ' ' + cname
							: 'newcss-unselected-button' + ' ' + cname
					}>
					{text}
				</button>
			);
		});
	};
	useEffect(() => {
		if (activityThemes.length && activityThemeError) {
			setActivityThemeError('');
		}
	}, [activityThemes.length]);

	return (
		<Fragment>
			<label className='newcss-spacer'>
				Words that describe your trip (select up to 4)
				{activityThemeError && (
					<ErrMessage inline={true} text={activityThemeError} />
				)}
			</label>
			<div className='newcss-grid-type'>{returnActivityTypes()}</div>
		</Fragment>
	);
};

ActivityTypes.propTypes = {
	activityThemes: PropTypes.array,
	addActivity: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	activityThemes: state.trip.activityThemes,
	activityThemeError: state.errors.activityThemeError,
});

export default connect(mapStateToProps, {
	addActivity,
	removeActivity,
	setActivityThemeError,
})(ActivityTypes);
