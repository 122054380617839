const initialState = {
	_id: '',
	flightPrice: '',
	seating: '',
	seatLocation: '',
	fareType: '',
	tripInsurance: '',
	lodgingPrice: '',
	idealLocation: '',
	lodgingType: '',
	driveVsDriver: '',
	publicVsPrivate: '',
	publicTransportationPreferences: '',
	tours: [],
	sightSeeingTours: '',
	physicalActivity: '',
	activitySamples: '',
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case 'CLEAR_PREFERENCES':
			return {
				_id: '',
				flightPrice: '',
				seating: '',
				seatLocation: '',
				fareType: '',
				tripInsurance: '',
				lodgingPrice: '',
				idealLocation: '',
				lodgingType: '',
				driveVsDriver: '',
				publicVsPrivate: '',
				publicTransportationPreferences: '',
				tours: [],
				sightSeeingTours: '',
				physicalActivity: '',
				activitySamples: '',
			};
		case 'ADD_SINGLE_OPTION_PREFERENCE':
			return {
				...state,
				[payload.preference]: payload.value,
			};
		case 'ADD_PARTY_INFO':
			return {
				...state,
				...payload,
			};
		case 'ADD_MULTI_OPTION_PREFERENCE':
			return {
				...state,
				[payload.preference]: [...state[payload.preference], payload.value],
			};
		case 'REMOVE_MULTI_OPTION_PREFERENCE':
			return {
				...state,
				[payload.preference]: [
					...state[payload.preference].filter((item) => item !== payload.value),
				],
			};
		case 'LOAD_PREFERENCES':
			return {
				...state,
				...payload,
			};

		default:
			return state;
	}
}
