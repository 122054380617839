import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SingleOptionPreference from './SingleOptionPreference';
import config from '../../config/config';
import MultiOptionPreference from './MultiOptionPreference';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { setRegisterFalse, updateUser } from '../../redux/actions/auth';
import {
	addPreferences,
	updatePreferences,
} from '../../redux/actions/preferences';
import { AnimatePresence } from 'framer-motion';

const ActivityPreferences = ({
	previousComponent,
	preferences,
	setRegisterFalse,
	edit,
	addPreferences,
	updatePreferences,
	index,
	changePreferenceIndex,
	submitPreferences,
	handleDirection,
	direction,
}) => {
	const windows = [
		<MultiOptionPreference
			edit={edit}
			direction={direction}
			name='tours'
			title='What types of tours are you interested in?'
			back={previousComponent}
			next={() => handleDirection(1)}
			children={config.ACTIVITYPREFERENCES.tours.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='sightSeeingTours'
			title='What length and depth of tours would you like to see offered?'
			back={() => handleDirection(-1)}
			next={() => handleDirection(1)}
			children={config.ACTIVITYPREFERENCES.sightSeeingTours.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='physicalActivity'
			title='How do you feel about physical activities when traveling?'
			back={() => handleDirection(-1)}
			next={() => handleDirection(1)}
			children={config.ACTIVITYPREFERENCES.physicalActivity.children}
		/>,
		<MultiOptionPreference
			edit={edit}
			direction={direction}
			name='activitySamples'
			title='Would you be interested in any of the following activities when traveling?'
			next={null}
			back={() => handleDirection(-1)}
			submit={submitPreferences}
			children={config.ACTIVITYPREFERENCES.activitySamples.children}
		/>,
	];

	return (
		<Fragment>
			{' '}
			<AnimatePresence>
				{windows.map((window, windowIndex) => {
					if (windowIndex === index || edit) return window;
					else return null;
				})}
			</AnimatePresence>
		</Fragment>
	);
};

ActivityPreferences.propTypes = {
	preferences: PropTypes.object,
	updateUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	preferences: state.preferences,
});
export default connect(mapStateToProps, {
	updateUser,
	addPreferences,
	updatePreferences,
})(ActivityPreferences);
