import React, { useEffect, useState } from 'react';
import AirportInputUnlocked from '../../Inputs/AirportInputUnlocked';
import TripDate from './TripDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	addDate,
	addFlight,
	editFlightDetail,
	editSuggestedHotel,
	removeFlight,
} from '../../../redux/actions/trip';
import moment from 'moment';
import {
	setFlightError,
	setReturnDateError,
} from '../../../redux/actions/errors';
import {
	capitalize,
	returnAirportCityData,
	returnTrueDate,
} from '../../../Utilities/helperFunctions';
const airports = require('../../../Utilities/new_airport_codes.json');

const RoundTrip = ({
	addDate,
	addFlight,
	returnDate,
	editFlightDetail,
	flightDetails,
	editSuggestedHotel,
	flightErrors,
	setFlightError,
	returnDateError,
	setReturnDateError,
}) => {
	// const [returnDateError, setReturnDateError] = useState('');
	const [departureError, setDepartureError] = useState('');
	const [destinationError, setDestinationError] = useState('');
	const [departureDateError, setDepartureDateError] = useState('');
	const [tripData, setTripData] = useState({
		departure: null,
		destination: null,
		departureDate: '',
	});

	const [tripInputs, setTripInputs] = useState({
		departure: '',
		destination: '',
		departureDate: '',
	});

	const [tripErrors, setTripErrors] = useState({
		departure: '',
		destination: '',
		departureDate: '',
	});

	const errorStrings = {
		departure: 'Please select a valid location from the drop down menu',
		destination: 'Please select a valid location from the drop down menu',
		departureDate: 'Please select a valid date from the drop down menu',
	};

	const handleDate = (date) => {
		if (date instanceof Date) {
			return date.toISOString().slice(0, 10);
		}
		return date.slice(0, 10);
	};
	const clearFlight = (name) => {
		setTripInputs({
			...tripInputs,
			[name]: '',
		});
		setTripData({
			...tripData,
			[name]: null,
		});
	};

	const clearError = (name) => {
		setTripErrors({
			...tripErrors,
			[name]: '',
		});
	};
	const addData = (property, data) => {
		setTripData({
			...tripData,
			[property]: data,
		});
	};

	const handleChange = (e) => {
		setTripInputs({
			...tripInputs,
			[e.target.name]: e.target.value,
		});
	};

	const setFlightInput = (inputName, values) => {
		setTripInputs({
			...tripInputs,
			[inputName]: `${values.name} - ${values.iataCode}`,
		});
	};

	const handleFlight = (data) => {
		const { departure, destination, departureDate } = tripData;
		const validFlight = departure && destination && departureDate;
		for (let key in tripData) {
			if (!tripData[key]) {
				setTripErrors({
					...tripErrors,
					[key]: errorStrings[key],
				});
			}
		}

		if (validFlight) {
			addFlight(data);
		}
	};

	useEffect(() => {
		const flightDetail = flightDetails[0];

		if (
			flightDetail &&
			flightDetail.destination &&
			flightDetail.departureDate
		) {
			editSuggestedHotel({
				city: returnAirportCityData(flightDetail),
				startDate: moment(returnTrueDate(flightDetail.departureDate))
					.format()
					.slice(0, 10),
				flightIndex: 0,
			});
		}
		if (returnDate)
			editSuggestedHotel({
				endDate: moment(returnTrueDate(returnDate)).format().slice(0, 10),
				flightIndex: 0,
			});
	}, [flightDetails[0], returnDate]);

	useEffect(() => {
		if (flightDetails.length > 1) {
			for (let index = flightDetails.length - 1; index === 1; index--) {
				removeFlight(index);
			}
		}
		const flightDetail =
			flightDetails && flightDetails.length && flightDetails[0];
		if (flightDetail) {
			const { departure, destination, departureDate } = flightDetail;
			const returnAirportInfo = (string) => {
				const foundItem = airports.find((item) => item.iata === string);
				if (foundItem) {
					const airport = foundItem.airportName;
					return ` ${string.toUpperCase()} - ${capitalize(airport)}`;
				} else return string;
			};
			setTripInputs({
				departure: departure ? returnAirportInfo(departure) : '',
				destination: destination ? returnAirportInfo(destination) : '',
				departureDate: departureDate ? handleDate(departureDate) : '',
			});
		}
	}, [flightDetails]);

	return (
		<div className='round-trip__section' onBlur={() => handleFlight(tripData)}>
			<TripDate
				roundTrip={true}
				cname='round-trip__departure-date'
				handleChange={handleChange}
				label='Departure Date'
				name='departureDate'
				value={tripInputs.departureDate}
				index={0}
				addDate={(e) => {
					editFlightDetail(0, e.target.name, e.target.value);
					editSuggestedHotel({
						flightIndex: 0,
						startDate: e.target.value,
					});
				}}
				sortedFlightDetails={flightDetails}
				error={flightErrors.length && flightErrors[0].departureDate}
				clearError={() => setFlightError(0, 'departureDate', '')}
				addError={() =>
					setFlightError(0, 'departureDate', errorStrings.departureDate)
				}
			/>
			<TripDate
				cname='round-trip__return-date'
				handleChange={(e) => {
					e.preventDefault();
					addDate(e.target.name, e.target.value);
				}}
				label='Return Date'
				name='returnDate'
				sortedFlightDetails={flightDetails}
				value={returnDate ? handleDate(returnDate) : ''}
				error={returnDateError}
				clearError={() => setReturnDateError('')}
				addError={() =>
					setReturnDateError(
						'Please select a valid date from the drop down menu'
					)
				}
			/>
			<div className='round-trip__departure'>
				<label htmlFor={`originLocationCode`} className='active'>
					Departure (Airport)
				</label>

				<AirportInputUnlocked
					setTripInputs={setTripInputs}
					tripInputs={tripInputs}
					inputKey={`roundTripFlightDetail-departure`}
					value={tripInputs.departure}
					name='departure'
					addFlight={addData}
					handleChange={handleChange}
					setFlightInput={setFlightInput}
					detailIndex={0}
					error={flightErrors.length && flightErrors[0].departure}
					clearError={() => setDepartureError('')}
					clearFlight={clearFlight}
					addError={() => setDepartureError(errorStrings.departure)}
				/>
			</div>
			<div className='round-trip__destination'>
				<label htmlFor='destinationLocationCode' className='active'>
					Destination (Airport)
				</label>

				<AirportInputUnlocked
					setTripInputs={setTripInputs}
					tripInputs={tripInputs}
					inputKey={`roundTripFlightDetail-destination`}
					value={tripInputs.destination}
					name='destination'
					addFlight={addData}
					handleChange={handleChange}
					setFlightInput={setFlightInput}
					detailIndex={0}
					error={
						flightErrors && flightErrors.length
							? flightErrors[0].destination
							: null
					}
					clearError={() => setDestinationError('')}
					clearFlight={clearFlight}
					addError={() => setDestinationError(errorStrings.destination)}
				/>
			</div>
		</div>
	);
};

RoundTrip.propTypes = {
	addDate: PropTypes.func.isRequired,
	addFlight: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	returnDate: state.trip.returnDate,
	flightDetails: state.trip.flightDetails,
	flightErrors: state.errors.flightErrors,
	returnDateError: state.errors.returnDateError,
});

export default connect(mapStateToProps, {
	editFlightDetail,
	addDate,
	addFlight,
	editSuggestedHotel,
	setFlightError,
	setReturnDateError,
})(RoundTrip);
