import React, { useEffect, useState } from 'react';
import checkmark from '../../images/checkmark.svg';
import { Redirect } from 'react-router';
import config from '../../config/config';
const Success = ({ userId }) => {
	const [timerRunning, setTimerRunning] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setTimerRunning(false);
		}, 15000);
	}, []);
	if (!timerRunning) {
		return (
			<Redirect
				to={`/${(userId && 'admin-') || ''}${config.SignedInLinks.itinerary}/${
					userId || ''
				}`}
			/>
		);
	}

	return (
		<div className='success-container'>
			<img src={checkmark} alt='' />
			<h3>Well done!</h3>
			<p>
				You have successfully submitted your Trip Profile! Check your email for
				more details.
			</p>
			<p>
				We are so excited to help you plan your next adventure! The Rove team
				will begin working on creating a trip that fits your needs and
				interests.
			</p>
			<span>Need support? Contact support@travelwithrove.com</span>
		</div>
	);
};

Success.propTypes = {};

export default Success;
