import React, { Fragment } from 'react';

const PassengerPricing = ({ pricing_info, id }) => {
	const returnAdditionalServices = () => {
		return pricing_info.additionalServices.map((service, index) => {
			return (
				<p
					key={`additional-service-${id}-${index}`}
					style={{ margin: '0.25em' }}>
					{service.type}: {service.amount}
				</p>
			);
		});
	};

	const returnPassengerPricing = () => {
		return pricing_info &&
			pricing_info.passenger_pricing &&
			pricing_info.passenger_pricing.length
			? pricing_info.passenger_pricing.map((pricing, index) => {
					return (
						<Fragment key={`passenger-pricing-${id}-${index}`}>
							<p style={{ margin: '0.25em' }}>{pricing.fareDetails.majCabin}</p>

							{pricing_info && pricing_info.additionalServices ? (
								<div>
									<p style={{ margin: '0.25em' }}>Additional Service Fees:</p>
									<section>{returnAdditionalServices()}</section>
								</div>
							) : null}
							<p style={{ margin: '0.25em' }}>
								${pricing_info.passenger_pricing[0].total_amount} <br /> per
								person
							</p>
						</Fragment>
					);
			  })
			: null;
	};
	return <>{returnPassengerPricing()}</>;
};

export default PassengerPricing;
