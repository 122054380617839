import React from 'react';
import { editModalForm } from '../../redux/actions/itineraryModal';
import { connect } from 'react-redux';
import {
	addCustomEvent,
	removeCustomEvent,
	updateCustom,
} from '../../redux/actions/trip';
import { returnTrueDate } from '../../Utilities/helperFunctions';

const CustomEventForm = ({
	selectedItem,
	editModalForm,
	addCustomEvent,
	deselectModalItem,
	updateCustom,
	removeCustomEvent,
}) => {
	const { title, startDate, startTime, endTime, description } = selectedItem;

	const handleEdit = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		editModalForm(name, value);
	};

	return (
		<>
			<p className='custom-form__description'>
				You can use this space to create your own stickers to display on your
				itinerary. This could include dinner reservations, specific shopping
				destinations or your own activities you want to slot into your trip.
				Enjoy!
			</p>
			<form
				className='custom-event'
				onSubmit={(e) => {
					e.preventDefault();
					editModalForm('startDate', returnTrueDate(startDate));
					addCustomEvent({ ...selectedItem, new: false });
					deselectModalItem();
				}}>
				<div>
					<label htmlFor='title'>Title</label>
					<input
						onChange={handleEdit}
						name='title'
						type='text'
						id='email'
						value={title}
					/>
				</div>
				{/* {registerErrors.email ? <ErrMessage text={null} /> : null} */}
				<div>
					<label htmlFor='description'>Description</label>
					<textarea
						id='g-recaptcha-response'
						name='description'
						value={description}
						onChange={handleEdit}></textarea>
				</div>
				{/* {registerErrors.password1 ? (
					<ErrMessage text={registerErrors.password1} />
				) : null} */}
				<div className='form__row'>
					<div>
						<label htmlFor='startDate'>Start Date</label>
						<input
							onChange={handleEdit}
							type='date'
							name='startDate'
							value={startDate}
						/>
					</div>
				</div>
				<div className='form__row'>
					<div>
						<label htmlFor='startTime'>Start Time</label>
						<input
							onChange={handleEdit}
							type='time'
							name='startTime'
							value={startTime}
						/>
					</div>

					<div>
						<label htmlFor='endTime'>End Time</label>
						<input
							onChange={handleEdit}
							type='time'
							name='endTime'
							value={endTime}
						/>
					</div>
				</div>
				{/* {registerErrors.password2 ? (
					<ErrMessage text={registerErrors.password2} />
				) : null} */}
				<div className='auth__footer'>
					{!selectedItem.new ? (
						<>
							<button
								onClick={(e) => {
									e.preventDefault();
									updateCustom(selectedItem);
									deselectModalItem();
								}}
								className='newcss-cta-button newcss-cta-button-left'>
								Update
							</button>
							<button
								onClick={(e) => {
									e.preventDefault();
									removeCustomEvent(selectedItem.index);
									deselectModalItem();
								}}
								className='newcss-cta-button newcss-cta-button-left'>
								Delete
							</button>
						</>
					) : (
						<button className='newcss-cta-button newcss-cta-button-left'>
							Add
						</button>
					)}
				</div>
			</form>
		</>
	);
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, {
	editModalForm,
	addCustomEvent,
	updateCustom,
	removeCustomEvent,
})(CustomEventForm);
