import React from 'react';

const PreferenceProgressBar = ({
	props,
	setCurrentPreference,
	preferenceData,
}) => {
	return (
		<div className='newcss-preferences-progress-bar'>
			<section
				onClick={() => setCurrentPreference('flight', 0)}
				className={
					preferenceData.name === 'flight'
						? 'newcss-preferences-selected'
						: null
				}>
				<span>01 Flight</span>
				<hr />
			</section>
			<section
				onClick={() => setCurrentPreference('lodging', 0)}
				className={
					preferenceData.name === 'lodging'
						? 'newcss-preferences-selected'
						: null
				}>
				<span>02 Lodging</span>
				<hr />
			</section>
			<section
				onClick={() => setCurrentPreference('transportation', 0)}
				className={
					preferenceData.name === 'transportation'
						? 'newcss-preferences-selected'
						: null
				}>
				<span>03 Transportation</span>
				<hr />
			</section>
			<section
				onClick={() => setCurrentPreference('activity', 0)}
				className={
					preferenceData.name === 'activity'
						? 'newcss-preferences-selected'
						: null
				}>
				<span>04 Activity</span>
				<hr />
			</section>
		</div>
	);
};

export default PreferenceProgressBar;
