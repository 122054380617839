import React from 'react';
import { handleMultiday } from '../../Utilities/helperFunctions';
import EventContent from './EventContent';
const CustomEventWrapper = ({ children, event }) => {
	const handleInCart = () => {
		return event.inCart ? ' in-cart' : '';
	};
	const handlePaid = () => {
		return event.paid || event.authorized ? ' paid' : '';
	};
	const handleInCityRange = () => {
		if (!event.cityRange) {
			return ' event-item';
		} else {
			const now = new Date();
			if (now <= new Date(event.start) && now >= new Date(event.end)) {
				return ' city-range in-city-range';
			} else return ' city-range out-city-range';
		}
	};

	const handleHourAmount = () => {
		const startHour = event.start.getHours();
		const endHour = event.end.getHours();
		const hourDif = endHour - startHour;
		return hourDif < 2 ? ' small-event' : '';
	};

	const customEventDiv = <EventContent event={event} />;

	const eventDiv = React.cloneElement(children, {}, customEventDiv);
	const wrapper = React.cloneElement(children, {}, eventDiv);

	const dayRangeData = handleMultiday(event);
	return (
		<div
			className={`event${handleInCart()}${handlePaid()}${handleInCityRange()}${
				dayRangeData && dayRangeData.className
			}${handleHourAmount()}`}>
			{eventDiv}
		</div>
	);
};

export default CustomEventWrapper;
