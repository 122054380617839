import React, { useState } from 'react';
import { post } from '../../Utilities/api';

const DestinationPickerForm = ({
	destinationSearchInfo,
	setDestinationSearchInfo,
	setLoading,
	setResults,
	setSubmitted,
}) => {
	const [currentWindow, setCurrentWindow] = useState(0);
	const windows = [
		{
			dataName: 'relativeLocation',
			header: 'Where are you trying to go?',
			description: '',
			options: ['Domestic', 'International'],
			selectMany: false,
		},
		{
			dataName: 'groupType',
			header: 'Who is coming with you?',
			description: '',
			options: ['Family Friendly', 'Adults Only'],
			selectMany: false,
		},
		{
			dataName: 'geographicalType',
			header: 'Where are you trying to go?',
			description: '',
			options: ['Beach', 'City', 'Mountains', 'Countryside/Wineries'],
			selectMany: true,
		},
		{
			dataName: 'activityTypes',
			header: 'Which of these activities are you looking for out of a trip?',
			description: '',
			options: [
				'Spa',
				'Skiing/Snowsports',
				'Shopping',
				'Nature',
				'History',
				'Art',
			],
			selectMany: true,
		},
	];

	const submit = async (e) => {
		e.preventDefault();
		if (hasValue()) {
			setLoading(true);
			const res = await post(
				'/api/v1/data/private/suggested-destination/suggested',
				destinationSearchInfo,
				true
			);
			if (res.status === 200) {
				setResults({
					suggestedDestinations: res.data.suggestedDestinations,
					suggestedTestimonials: res.data.suggestedTestimonials,
				});
				setSubmitted(true);
				setLoading(false);
			}
		}
	};

	const handleSelected = (selected, currentValue) => {
		if (Array.isArray(selected) && selected.includes(currentValue)) {
			return true;
		}
		if (selected === currentValue) {
			return true;
		}
		return false;
	};

	const handleSelectClick = (currentField, currentValue) => {
		const selected = destinationSearchInfo[currentField];
		if (Array.isArray(selected)) {
			if (!selected.includes(currentValue)) {
				setDestinationSearchInfo({
					...destinationSearchInfo,
					[currentField]: [...selected, currentValue],
				});
			} else {
				setDestinationSearchInfo({
					...destinationSearchInfo,
					[currentField]: selected.filter((value) => currentValue !== value),
				});
			}
		} else {
			setDestinationSearchInfo({
				...destinationSearchInfo,
				[currentField]: currentValue,
			});
		}
	};

	const hasValue = () => {
		const currentValue = destinationSearchInfo[windows[currentWindow].dataName];
		if (
			(Array.isArray(currentValue) && currentValue.length) ||
			(!Array.isArray(currentValue) && currentValue)
		) {
			return true;
		} else return false;
	};

	return (
		<>
			<h3 className='destination-picker-title'>
				{windows[currentWindow].header}
			</h3>
			<section className='destination-picker-card'>
				{windows[currentWindow].options.map((option) => {
					const isSelected = handleSelected(
						destinationSearchInfo[windows[currentWindow].dataName],
						option
					);
					return (
						<button
							onClick={() =>
								handleSelectClick(windows[currentWindow].dataName, option)
							}
							className={`destination-picker-button${
								windows[currentWindow].options.length > 4 ? ' width-27p' : ''
							}${isSelected ? ' selected' : ''}`}>
							{option}
						</button>
					);
				})}
			</section>
			<div className='buttons'>
				<button
					onClick={() =>
						setCurrentWindow((curr) =>
							curr > 0 && hasValue() ? curr - 1 : curr
						)
					}>
					Prev
				</button>
				{currentWindow === windows.length - 1 ? (
					<button onClick={submit}>Submit</button>
				) : (
					<button
						onClick={() =>
							setCurrentWindow((curr) =>
								curr < windows.length - 1 && hasValue() ? curr + 1 : curr
							)
						}>
						Next
					</button>
				)}
			</div>
		</>
	);
};

export default DestinationPickerForm;
