import React from 'react';

const FlightTravelTimeInfo = ({ departure, arrival, flightDuration }) => {
	return (
		<>
			<p>
				{departure} - {arrival} <br /> <span>({flightDuration})</span>
			</p>
		</>
	);
};

export default FlightTravelTimeInfo;
