import React from 'react';
import { Fragment } from 'react';
import remove from '../../images/noun_Shopping Cart_remove-white.svg';
import add from '../../images/noun_Shopping Cart_plus-black.svg';
import paidGreen from '../../images/noun_paid_green_1820672.svg';

const CartButton = ({
	inCart,
	multiDay,
	setButtonAction,
	paid,
	handleRemoveItem,
}) => {
	return (
		<div className='event-nav'>
			{paid ? (
				<img src={paidGreen} alt='paid' title='Paid' />
			) : (
				<Fragment>
					<button
						onClick={setButtonAction}
						className={`add-to-cart-button ${
							multiDay() ? 'multiday' : 'singleday'
						}`}>
						{inCart ? (
							<img
								src={remove}
								alt='remove from cart'
								title='Remove From Cart'
							/>
						) : (
							<img src={add} alt='add to cart' title='Add To Cart' />
						)}
					</button>
					<button
						className='remove-item'
						onClick={handleRemoveItem}
						title='Remove From Itinerary'>
						+
					</button>
				</Fragment>
			)}
		</div>
	);
};

CartButton.propTypes = {};

export default CartButton;
