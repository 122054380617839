import React from 'react';
import Leg from './Leg';

const Legs = ({ legs }) => {
	const returnLegs = () => {
		return legs && legs.length
			? legs.map((leg, index) => {
					const detailEndIndex = leg.flight_details.length - 1;
					return (
						<Leg
							key={`flight-leg-${index}`}
							leg={leg}
							detailEndIndex={detailEndIndex}
						/>
					);
			  })
			: null;
	};
	return <div className='legs__mobile-wrapper'>{returnLegs()}</div>;
};

export default Legs;
