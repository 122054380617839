import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import profile from '../../images/profile.png';
import questionMark from '../../images/noun-question-670406-656565.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProfileDropDown from '../Layout/ProfileDropDown';
import { setRedirect } from '../../redux/actions/redirect';
import { setLoading, submitUpdate } from '../../redux/actions/trip';
import AttachmentPopup from '../Layout/AttachmentPopup';

const PlanTripNavbar = ({
	cart,
	category,
	trip,
	pathname,
	submitUpdate,
	setRedirect,
	setLoading,
	tripLoading,
	openToolTip,
	showToolTips,
}) => {
	const [dropdown, toggleDropdown] = useState(false);
	const returnCartLength = () => {
		const { flights, lodging, transport, activities, amadeusCars } = cart;
		return (
			flights.length +
			lodging.length +
			transport.length +
			activities.length +
			amadeusCars.length
		);
	};

	const handleSubmit = async (redirectPath) => {
		if (!tripLoading) {
			setLoading(true);

			try {
				const res = await submitUpdate({ ...trip, showToolTips });
				if (res === true) {
					setRedirect(redirectPath);
				}
			} catch (error) {
				setLoading(false);
			}
		}
	};

	if (pathname) {
		return <Redirect to={pathname} />;
	}
	return (
		<div className='newcss-plan-trip-navbar'>
			<section className='newcss-item-buttons-container-black'>
				<button onClick={() => handleSubmit('/itinerary')} className='logo'>
					ROVE
				</button>
			</section>
			<h2 className='capitalize'>{category}</h2>
			<ul>
				<li>
					<AttachmentPopup trip_id={trip._id} />
				</li>
				<li>
					<button className='tool-tip__button' onClick={openToolTip}>
						<img src={questionMark} alt='' />
					</button>
				</li>
				<li
					className='profile-dropdown__container'
					onMouseEnter={() => toggleDropdown(true)}
					onMouseLeave={() => toggleDropdown(false)}>
					<button href='#' className='nav-links-link'>
						<img className='newcss-profile-image' src={profile} />
					</button>
					{dropdown && (
						<ProfileDropDown
							toggleDropdown={toggleDropdown}
							submitUpdate={() => setRedirect('/itinerary')}
						/>
					)}
				</li>
				<li>
					<div
						onClick={() => handleSubmit(`/cart/${trip._id}`)}
						className='newcss-cart-total-container'>
						<HiOutlineShoppingCart style={{ width: 28, height: 28 }} />
						{cart ? (
							<span className='newcss-cart-total'>
								{cart && returnCartLength()}
							</span>
						) : null}
					</div>
				</li>
			</ul>
		</div>
	);
};

PlanTripNavbar.propTypes = {
	cart: PropTypes.object,
};

const mapStateToProps = (state) => ({
	cart: state.trip.cart,
	trip: state.trip,
	pathname: state.redirect.pathname,
	tripLoading: state.trip.loading,
	showToolTips: state.auth.user.showToolTips,
});

export default connect(mapStateToProps, {
	setRedirect,
	submitUpdate,
	setLoading,
})(PlanTripNavbar);
