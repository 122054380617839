import React, { useState } from 'react';
import { FadeLoader } from 'react-spinners';

import { css } from '@emotion/core';
const override = css`
	display: block;
	margin: 3rem auto;
`;

const Agreement = ({
	firstName,
	lastName,
	handleAgreementClick,
	closeAgreement,
}) => {
	const [loading, setLoading] = useState(false);
	return (
		<div className='agreement'>
			<div className='container'>
				{loading ? (
					<FadeLoader css={override} />
				) : (
					<>
						<button className='close' onClick={closeAgreement}>
							+
						</button>
						<h4>User Agreement</h4>
						<p>
							I, {firstName} {lastName}, authorize Travel with Rove LLC to apply
							charges to my card held on file. The charge is for the purchase of
							travel or any travel-related services for myself and the travelers
							I have indicated. In the event of any dispute arising from or
							charge back from my credit card, I shall be fully and solely
							responsible for the settlement of payment. Travel with Rove LLC
							shall not be held liable in any manner. All the disputed and
							charge back amounts must be paid back to Travel with Rove LLC
							timely.
						</p>
						<p>
							I understand the amounts listed here are estimates and may very
							slightly based on daily currency rates. I do not expect Travel
							with Rove LLC to cover any differences related to currency changes
							and agree to pay the amount at time of purchase.
						</p>
						<p>
							If changes need to be made to the card on file, it is my
							responsibility to reach out to Travel with Rove LLC for inform
							them of any edits. They can be reached at
							support@travelwithrove.com for any credit card related details.
						</p>
						<div className='actions'>
							<button
								onClick={() => {
									setLoading(true);
									handleAgreementClick();
								}}>
								Agree
							</button>
							<div className='check-box'>
								<span htmlFor='agreementShow'> Do not show me this again</span>
								<input
									className='agreement-show'
									type='checkbox'
									name='agreementShow'
									value={true}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Agreement;
