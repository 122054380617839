import React, { Fragment } from 'react';

const ToggleButton = ({ text, buttonAction, centered }) => {
	return (
		<Fragment>
			<button
				onClick={buttonAction}
				className={`toggle-button newcss-cta-button ${centered && 'centered'}`}>
				{text}
			</button>
		</Fragment>
	);
};

export default ToggleButton;
