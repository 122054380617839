import { get, post, put, run_ip_check } from '../../Utilities/api';
import { setLoginError } from './errors';
import { clearPreferences, loadPreferences } from './preferences';
import { clearProfile } from './profile';
import { setRedirect } from './redirect';
import { clearTrip } from './trip';

export const loadAuthUser = (user) => async (dispatch) => {
	dispatch({
		type: 'USER_LOADED',
		payload: user,
	});
};

export const registerUser = (registerFields) => async (dispatch) => {
	try {
		const authRequired = false;
		const res = await post(
			'/api/v1/auth/register',
			registerFields,
			authRequired
		).catch((err) => {
			throw { message: 'Bad Request' };
		});
		await localStorage.setItem('authToken', res.data.token);
		console.log('authToken', localStorage.getItem('authToken'));
		run_ip_check();

		return {
			success: true,
			message:
				'Thanks for signing up! Please check your email to be redirected to your account.',
		};
	} catch (err) {
		dispatch({
			type: 'REGISTER_DENIED',
		});
		return { success: false, message: err.message };
	}
};

export const newsletterSubscribe = async (formData) => {
	const authRequired = false;
	const res = await post('/api/v1/newsletter/create', formData, authRequired);
};

export const addTrip = (trip) => (dispatch) => {
	dispatch({
		type: 'ADD_USER_TRIP',
		payload: trip,
	});
	return true;
};

export const handleTravelDocument = (key, value) => (dispatch) => {
	dispatch({
		type: 'HANDLE_TRAVEL_DOCUMENT',
		payload: { key, value },
	});
};

export const submitTrip = (trip) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			`/api/v1/data/private/trip/submit-trip-form`,
			trip,
			authRequired
		);
		dispatch({
			type: 'UPDATE_USER_TRIP',
			payload: res.data.trip,
		});
		return true;
	} catch (err) {
		console.log(err);
	}
};

export const getAuthUser = () => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get(
			'/api/v1/data/private/user/me',
			{},
			authRequired
		).catch((err) => {
			throw { message: 'Authorization Failed' };
		});
		dispatch(loadAuthUser(res.data.user));
		dispatch(loadPreferences(res.data.preferences));
		return { success: true, authUser: res.data.user };
	} catch (err) {
		dispatch(logout());
		console.log('error', err.message);
		return false;
	}
};

export const resetPassword = async (password) => {
	try {
		const res = await post('/api/v1/auth/reset-password', {
			password,
			resetPasswordToken: localStorage.getItem('resetPasswordToken'),
		}).catch((err) => {
			throw {
				message: err.message,
			};
		});

		return res.data;
	} catch (error) {
		console.log(error.message);
	}
};

export const supportEmail = (body) => async (dispatch) => {
	try {
		const res = await post(
			'/api/v1/data/private/user/support-email',
			body,
			true
		);
		return res;
	} catch (error) {
		return error;
	}
};
export const updatePassword = async (formData) => {
	try {
		const res = await post(
			'/api/v1/data/private/user/update-password',
			formData,
			true
		).catch((err) => {
			throw {
				message: err.message,
			};
		});

		return res;
	} catch (error) {
		console.log(error.message);
		return false;
	}
};

export const verifyMe = (verificationToken, userId) => async (dispatch) => {
	console.log('verify me');
	try {
		const authRequired = false;
		let res = await post(
			'/api/v1/auth/verify-user',
			{ verificationToken, userId },
			authRequired
		);

		console.log('res', res);

		localStorage.setItem('authToken', res.data.token);

		run_ip_check();
		await dispatch(loadAuthUser(res.data.user));
		// await dispatch(setRedirect('/my-preferences/'));
		return true;
	} catch (err) {
		dispatch(logout());
		console.log('in verifyme');
		console.log(err.message);
		return false;
	}
};

export const resendVerifyToken = async (email) => {
	try {
		const res = await post('/api/data/resend-verify-token', { email }, false);
		if (res.status === 200) {
			return true;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const login = (loginFields) => async (dispatch) => {
	const data = JSON.stringify(loginFields);
	try {
		const authRequired = false;

		const res = await post('/api/v1/auth/sign-in', data, authRequired).catch(
			(err) => {
				throw { message: 'Authorization Failed' };
			}
		);

		localStorage.setItem('authToken', res.data.token);
		// run_ip_check();
		const { token, user } = res.data;

		localStorage.setItem('user_id', res.data.user._id);
		dispatch(loadAuthUser(res.data.user));
		window.location.replace('/itinerary/');
		//dispatch(setRedirect('/itinerary/'));
		dispatch(loadPreferences(res.data.preferences));
	} catch (err) {
		const message = err.message;
		dispatch({
			type: 'LOGIN_DENIED',
		});
		dispatch(setLoginError('Invalid email or password'));
	}
};

export const logout = () => async (dispatch) => {
	localStorage.removeItem('authToken');
	dispatch({ type: 'LOGOUT' });
	localStorage.clear();
	dispatch(clearPreferences());
	dispatch(clearTrip());

	dispatch(clearProfile());
};

export const setRegisterFalse = () => (dispatch) => {
	dispatch({ type: 'SET_REGISTER_FALSE' });
};

export const updateUser = (payload) => async (dispatch) => {
	dispatch({ type: 'UPDATE_USER', payload });
	return true;
};

export const updateUserTrips = (items) => (dispatch) => {
	dispatch({
		type: 'UPDATE_USER_TRIPS',
		payload: items,
	});
};

export const updateContactInfo = (body) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			'/api/v1/data/private/user/update-contact',
			body,
			authRequired
		);
		dispatch({
			type: 'UPDATE_CONTACT',
			payload: res.data,
		});
	} catch (err) {
		console.log(err.message);
	}
};

export const handleUserText = (key, value) => (dispatch) => {
	dispatch({
		type: 'HANDLE_USER_TEXT',
		payload: { key, value },
	});
};
