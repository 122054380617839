import React from 'react';
import { Fragment } from 'react';

const ModalImage = ({ src, itemType }) => {
	return (
		<Fragment>
			<img
				className={`itinerary-modal-image${
					itemType === 'amadeus-car' ? ' car' : ''
				}`}
				src={src}
				alt=''
			/>
		</Fragment>
	);
};

export default ModalImage;
