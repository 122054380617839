import React from 'react';
import Popup from 'reactjs-popup';
import AttachmentLinks from './AttachmentLinks';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	queryAttachments,
	removeAttachment,
	updateAttachments,
} from '../../redux/actions/trip';

const AttachmentPopup = ({
	trip_id,
	queryAttachments,
	attachments,
	removeAttachment,
	updateAttachments,
}) => {
	useEffect(() => {
		queryAttachments(trip_id);
	}, [trip_id]);
	return (
		<>
			<Popup
				trigger={
					<button
						style={{
							backgroundColor: 'none',
							padding: '.5rem 1.25rem',
							border: 'none',
						}}>
						Trip Documents
					</button>
				}
				modal>
				{(close) => (
					<div className='popup' id='popup'>
						<div className='popup__content'>
							<div className='popup__header'>
								<h3 className='popup__header--text'>Trip Documents</h3>
								<button
									className='popup__close'
									onClick={async (e) => {
										e.preventDefault();
										await updateAttachments();
										close();
									}}>
									<i className='fa fa-times'></i>
								</button>
							</div>
							{attachments && (
								<AttachmentLinks
									removeAttachment={removeAttachment}
									attachments={attachments}
								/>
							)}
						</div>
					</div>
				)}
			</Popup>
		</>
	);
};

const mapStateToProps = (state) => ({
	attachments: state.trip.attachments,
});

export default connect(mapStateToProps, {
	queryAttachments,
	removeAttachment,
	updateAttachments,
})(AttachmentPopup);
