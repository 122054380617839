import { get, post, put } from '../../Utilities/api';
import { setRedirect } from './redirect';

export const addSingleOptionPreference =
	(preference, value) => async (dispatch) => {
		dispatch({
			type: 'ADD_SINGLE_OPTION_PREFERENCE',
			payload: {
				preference,
				value,
			},
		});
	};

export const addPartyInfo = (info) => (dispatch) => {
	delete info.id;
	dispatch({
		type: 'ADD_PARTY_INFO',
		payload: info,
	});
};

export const addMultiOptionPreference =
	(preference, value) => async (dispatch) => {
		dispatch({
			type: 'ADD_MULTI_OPTION_PREFERENCE',
			payload: {
				preference,
				value,
			},
		});
	};

export const removeMultiOptionPreference =
	(preference, value) => async (dispatch) => {
		dispatch({
			type: 'REMOVE_MULTI_OPTION_PREFERENCE',
			payload: {
				preference,
				value,
			},
		});
	};

export const loadPreferences = (preferences) => (dispatch) => {
	dispatch({
		type: 'LOAD_PREFERENCES',
		payload: preferences,
	});
};

export const addPreferences = (data) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/data/private/user/add-user-preferences',
			data,
			authRequired
		).catch((err) => {
			throw { message: 'Bad Request' };
		});
		dispatch({
			type: 'LOAD_PREFERENCES',
			payload: res.data.user.preferences,
		});
		return true;
		// dispatch(setRedirect('/itinerary/'));
	} catch (err) {
		const message = err.message;
		console.log(message);
		return false;
	}
};

export const updatePreferences = (data) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			'/api/v1/data/private/user/update-user-preferences',
			data,
			authRequired
		);
		dispatch({
			type: 'LOAD_PREFERENCES',
			payload: res.data.preferences,
		});

		// dispatch(setRedirect('/itinerary/'));
		return true;
	} catch (err) {
		const message = err.message;
		console.log(message);
		return false;
	}
};

export const getUserPreferences = (data) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get(
			'/api/v1/data/private/user/find-user-preferences',
			data,
			authRequired
		);
		dispatch({
			type: 'LOAD_PREFERENCES',
			payload: res.data.preferences,
		});
	} catch (err) {
		const message = err.message;
		console.log(message);
	}
};

export const clearPreferences = () => (dispatch) => {
	dispatch({ type: 'CLEAR_PREFERENCES' });
};
