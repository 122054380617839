import React, { useState } from 'react';
import PropTypes from 'prop-types';
import infoIcon from '../../images/noun-information-1281661-142350-small.svg';

const InfoBubble = ({ hoverItem }) => {
	const [hovered, setHovered] = useState(false);
	return hoverItem ? (
		<>
			<span style={{ position: 'relative' }}>
				<img
					onMouseOver={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					src={infoIcon}
					alt='information icon'
					className='info-icon'
				/>
				{hovered && hoverItem}
			</span>
		</>
	) : null;
};

InfoBubble.propTypes = {};

export default InfoBubble;
