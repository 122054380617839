import React from 'react';
import { clearTrip, submitUpdate } from '../../redux/actions/trip';
import { setRedirect } from '../../redux/actions/redirect';
import { connect } from 'react-redux';

const SaveTripAlert = ({
	submitUpdate,
	redirect,
	trip,
	setRedirect,
	clearTrip,
}) => {
	const handleButtonClick = async (e) => {
		const { value } = e.target;
		const yes = value === 'Yes';
		const no = value === 'No';
		if (yes) {
			const savedTrip = await submitUpdate(trip);
			if (savedTrip) {
				setRedirect(redirect);
			}
		}
		if (no) {
			const res = await clearTrip();
			res && setRedirect(redirect);
		}
	};
	return (
		<div className='save-trip-alert'>
			<div className='container'>
				<h3>
					You are about to leave the page! <br /> Do you want to save what you
					have before leaving?
				</h3>
				<div className='buttons'>
					<button value='Yes' onClick={handleButtonClick}>
						Yes
					</button>
					<button value='No' onClick={handleButtonClick}>
						No
					</button>
				</div>
			</div>
		</div>
	);
};

export default connect(null, { submitUpdate, setRedirect, clearTrip })(
	SaveTripAlert
);
