import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DayTripInput from './DayTripInput';
import { removeDayTrip, addEmptyDaytrip } from '../../../redux/actions/trip';
import { mergeSortedItems } from '../../../Utilities/helperFunctions';
import { addDayTripErrorObject } from '../../../redux/actions/errors';
import { AnimatePresence } from 'framer-motion';

const DayTripSection = ({
	dayTrips,
	loading,
	removeDayTrip,
	addEmptyDaytrip,
	flights,
	hotels,
	returnDate,
	dayTripErrors,
	addDayTripErrorObject,
}) => {
	const returnMinMax = () => {
		const sortedFlights = flights.sort((a, b) => {
			return new Date(a.departureDate) - new Date(b.departureDate);
		});
		const sortedHotels = hotels
			.sort((a, b) => {
				return new Date(a.startDate) - new Date(b.startDate);
			})
			.filter((item) => {
				return item.startDate && item.endDate;
			});
		const merged = mergeSortedItems(sortedFlights, sortedHotels);
		const minDate =
			merged.length && new Date(merged[0].departureDate || merged[0].startDate);
		let maxDate =
			merged.length &&
			new Date(
				merged[merged.length - 1].departureDate ||
					merged[merged.length - 1].endDate
			);
		maxDate =
			!returnDate || maxDate > new Date(returnDate)
				? maxDate
				: new Date(returnDate);
		return {
			minDate,
			maxDate,
		};
	};

	const returnTrips = () => {
		const { minDate, maxDate } = returnMinMax();
		if (dayTrips.length === 3) {
			return (
				!loading &&
				dayTrips.map((trip, itemIndex) => {
					if (itemIndex < dayTrips.length - 1) {
						return (
							<DayTripInput
								minDate={minDate}
								maxDate={maxDate}
								key={`dayTripItem-${itemIndex}`}
								itemIndex={itemIndex}
								currentId={trip._id}
								currentCity={trip.city}
								currentDate={trip.date}
								addTrip={() => null}
								removeTrip={() => removeDayTrip(itemIndex)}
								minus={true}
								errors={dayTripErrors[itemIndex]}
							/>
						);
					} else {
						return (
							<DayTripInput
								minDate={minDate}
								maxDate={maxDate}
								key={`dayTripItem-${itemIndex}`}
								itemIndex={itemIndex}
								currentId={trip._id}
								addTrip={() => null}
								currentCity={trip.city}
								currentDate={trip.date}
								removeTrip={() => removeDayTrip(itemIndex)}
								minus={false}
								errors={dayTripErrors[itemIndex]}
							/>
						);
					}
				})
			);
		}
		if (dayTrips.length < 3) {
			return (
				!loading &&
				dayTrips.map((trip, itemIndex) => {
					if (dayTripErrors.length < dayTrips.length) {
						addDayTripErrorObject({ city: '', date: '' });
					}
					if (itemIndex < dayTrips.length - 1) {
						return (
							<DayTripInput
								minDate={minDate}
								maxDate={maxDate}
								key={`dayTripItem-${itemIndex}`}
								itemIndex={itemIndex}
								currentId={trip._id}
								currentCity={trip.city}
								addTrip={addEmptyDaytrip}
								removeTrip={() => removeDayTrip(itemIndex)}
								minus={true}
								errors={dayTripErrors[itemIndex]}
							/>
						);
					} else {
						return (
							<DayTripInput
								minDate={minDate}
								maxDate={maxDate}
								key={`dayTripItem-${itemIndex}`}
								itemIndex={itemIndex}
								currentId={trip._id}
								currentCity={trip.city}
								currentDate={trip.date}
								addTrip={addEmptyDaytrip}
								removeTrip={() => removeDayTrip(itemIndex)}
								minus={false}
								errors={dayTripErrors[itemIndex]}
							/>
						);
					}
				})
			);
		}
	};

	useEffect(() => {
		if (!dayTrips.length) {
			addEmptyDaytrip();
		}
	}, [dayTrips]);

	return (
		<Fragment>
			<section className='trip__form-card'>
				<h2 className='trip__form-card-header'>DAY TRIPS </h2>
				<p className='trip__form-card-description'>
					A day trip is a single destination you would like to spend{' '}
					<strong>no more than one day</strong> visiting. An example would be
					Pisa from Rome or Versaille from Paris.
				</p>
				<div className='newcss-grid-2-with-button'>
					<label>List each city/town</label>
					<label>Trip Date (optional)</label>
				</div>

				<AnimatePresence>{returnTrips()}</AnimatePresence>
				{/* {!dayTrips.length && (
					<DayTripInput
						currentId={uuid()}
						addTrip={addEmptyDaytrip}
						minus={false}
					/>
				)} */}
			</section>
		</Fragment>
	);
};

DayTripSection.propTypes = {
	dayTrips: PropTypes.array,
	loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	dayTrips: state.trip.dayTrips,
	loading: state.trip.loading,
	flights: state.trip.flightDetails,
	hotels: state.trip.hotelDetails,
	returnDate: state.trip.returnDate,
	dayTripErrors: state.errors.dayTripErrors,
});

export default connect(mapStateToProps, {
	removeDayTrip,
	addEmptyDaytrip,
	addDayTripErrorObject,
})(DayTripSection);
