import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Trip from '../components/pages/Trip';
import CreateTrip from '../components/pages/CreateTrip';
import Wait from '../components/CreateTrip/Wait';
import PlanTrip from '../components/pages/PlanTrip';
import Checkout from '../components/pages/Checkout';
import Preferences from '../components/pages/Preferences';
import HomePage from '../components/pages/HomePage';
import Contact from '../components/Contact';
import CreateAccount from '../components/Auth/CreateAccount';

import Navbar from '../components/Layout/Navbar';
import EditPreferences from '../components/pages/EditPreferences';
import Settings from '../components/pages/Settings';

import config from '../config/config';
import Testimonials from '../components/pages/Testimonials';
const RouteWithNavbar = ({
	exact,
	path,
	component: Component,
	render: Render,

	...rest
}) => {
	const adminToken =
		rest &&
		rest.computedMatch &&
		rest.computedMatch.params &&
		rest.computedMatch.params.adminToken
			? rest.computedMatch.params.adminToken
			: null;
	if (adminToken) localStorage.setItem('adminToken', adminToken);

	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(routeProps) => {
				return (
					<>
						<Navbar {...routeProps} />
						{Component ? (
							<Component {...routeProps} />
						) : (
							<Render {...routeProps} />
						)}{' '}
					</>
				);
			}}
		/>
	);
};

const Routes = (props) => {
	return (
		<Switch>
			<RouteWithNavbar
				path='/'
				exact={true}
				render={(props) => {
					return <HomePage {...props} start='sign-up' />;
				}}
			/>
			<RouteWithNavbar
				path='/reset-password/:token'
				exact={false}
				render={(props) => <HomePage {...props} start='reset-password' />}
			/>

			<RouteWithNavbar
				exact
				path={`/${config.SignedInLinks.itinerary}`}
				component={Trip}
			/>
			<RouteWithNavbar exact path={`/testimonials`} component={Testimonials} />
			<RouteWithNavbar
				exact
				path={`/admin-${config.SignedInLinks.itinerary}/:userId`}
				component={Trip}
			/>
			<RouteWithNavbar
				path='/preferences'
				render={(props) => <Preferences {...props} />}
			/>
			<RouteWithNavbar
				path='/verify-email/:userId/:verificationToken'
				render={(props) => <Trip {...props} verificationWindow={true} />}
			/>
			<RouteWithNavbar
				path={config.SignedInLinks.contact}
				component={Contact}
			/>
			<RouteWithNavbar path={config.SignedInLinks.wait} component={Wait} />
			<RouteWithNavbar path={config.SignedInLinks.cart} component={Checkout} />
			<RouteWithNavbar
				path={config.SignedInLinks.settings}
				component={Settings}
			/>
			<Route path={`/${config.SignedInLinks.planTrip}`} component={PlanTrip} />
			<Route
				path={`/admin-${config.SignedInLinks.planTrip}`}
				component={PlanTrip}
			/>
			<RouteWithNavbar
				path={config.SignedOutLinks.signup}
				component={CreateAccount}
			/>
			<RouteWithNavbar
				path={config.SignedInLinks.preferences}
				component={Preferences}
			/>
			<RouteWithNavbar
				path={config.SignedInLinks.editPreferences}
				component={EditPreferences}
			/>

			<RouteWithNavbar
				exact={true}
				path={`${config.SignedInLinks.createTrip}`}
				component={CreateTrip}
			/>

			<RouteWithNavbar
				exact={false}
				path={`/admin-edit-trip/:tripId`}
				component={CreateTrip}
			/>
			{/* <Route render={() => <Redirect to='/' />} /> */}
		</Switch>
	);
};

export default Routes;
