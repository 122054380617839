import React from 'react';
import SettingsSideNavButton from './SettingsSideNavButton';

const SettingsSideNav = ({ setCurrentWindow }) => {
	const handleClick = (e) => {
		setCurrentWindow(e.target.value);
	};
	return (
		<div className='settings__sidenav'>
			<SettingsSideNavButton value='Password' handleClick={handleClick} />
		</div>
	);
};

export default SettingsSideNav;
