import React, { useState } from 'react';

import plus from '../../images/noun-plus.svg';
import minus from '../../images/noun-minus.svg';
const FaqItem = ({ item, refs, index }) => {
	const [open, setOpen] = useState(false);
	return (
		<section className='faq__item'>
			<button
				ref={(el) => (refs.current[index] = el)}
				onClick={(e) => {
					e.preventDefault();
					setOpen((curr) => {
						return !curr;
					});
				}}
				className='faq__button'>
				<span>{item.question}</span>
				<img src={open ? minus : plus} alt='' />
			</button>
			{open ? <p className='faq__info'>{item.answer}</p> : null}
		</section>
	);
};

export default FaqItem;
