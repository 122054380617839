import React from 'react';
import { connect } from 'react-redux';
import { selectModalItem } from '../../redux/actions/trip';
import Activity from '../Activities/Activity';
const CustomItems = ({ custom, selectModalItem }) => {
	const newFormData = {
		type: 'custom-event',
		new: true,
		title: '',
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		description: '',
	};

	const mapItems = () => {
		return custom && custom.length
			? custom.map((item, index) => {
					return (
						<div className='newcss-item-preview' key={`custom-item-${index}`}>
							<Activity index={index} custom={true} data={item} />
						</div>
					);
			  })
			: null;
	};
	return (
		<div>
			<button
				className='add-custom-item'
				onClick={() => selectModalItem(newFormData)}>
				Add new item
			</button>
			<div className='newcss-itinerary'>{mapItems()}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	custom: state.trip.itineraryItems.custom,
});
export default connect(mapStateToProps, { selectModalItem })(CustomItems);
