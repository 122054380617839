export const selectModalItem = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_MODAL_ITEM',
		payload: item,
	});
};

export const editModalForm = (key, value) => (dispatch) => {
	dispatch({
		type: 'EDIT_MODAL_FORM',
		payload: { key, value },
	});
};

export const deselectModalItem = (item) => (dispatch) => {
	dispatch({
		type: 'DESELECT_MODAL_ITEM',
	});
};

export const setCustomStartEnd = (start, end) => (dispatch) => {
	dispatch({
		type: 'SET_CUSTOM_START_END',
		payload: { start, end },
	});
};
