import React from 'react';
import { handleDurationString } from '../../Utilities/handleDurationString';
import { handleTime } from '../../Utilities/helperFunctions';
import FlightTravelTimeInfo from './FlightTravelTimeInfo';

const Leg = ({ detailEndIndex, leg }) => {
	const detailsExist = leg && leg.flight_details && leg.flight_details.length;
	const endDetail = detailsExist && leg.flight_details[detailEndIndex];

	return detailsExist ? (
		<div className='leg'>
			<p className='from-to'>
				{leg.flight_details[0].departure.location} to{' '}
				{endDetail && endDetail.arrival.location}
			</p>
			<FlightTravelTimeInfo
				departure={`${handleTime(
					detailsExist ? leg.flight_details[0].departure.time : null
				)}
             `}
				arrival={`${handleTime(endDetail ? endDetail.arrival.time : null)}`}
				flightDuration={handleDurationString(leg.flight_duration)}
			/>
			<p>
				{detailEndIndex === 0
					? 'Nonstop'
					: detailEndIndex > 1
					? `${detailEndIndex} stops`
					: `${detailEndIndex} stop`}
			</p>{' '}
		</div>
	) : null;
};

export default Leg;
