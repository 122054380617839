import React, { Fragment } from 'react';
import AddTravelers from '../Profile/AddTravelers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { handleTravelDocument, handleUserText } from '../../redux/actions/auth';

const PartyForms = ({
	user,
	toggleReview,
	setSecondaryParty,
	secondaryParty,
	handleUserText,
	handleTravelDocument,
	partyErrors,
	setUserErrors,
	setPartyErrors,
	userErrors,
}) => {
	const drawSecondaryTraveler = (traveler, index) => {
		const handleChange = (e) => {
			e.preventDefault();
			let text = e.target.value;
			setSecondaryParty(
				secondaryParty.map((traveler, travelerIndex) =>
					travelerIndex === index
						? { ...traveler, [e.target.name]: text }
						: traveler
				)
			);
			console.log(partyErrors);
			setPartyErrors(
				partyErrors.map((errors, errorsIndex) => {
					if (errors[e.target.name] && text && errorsIndex === index) {
						return {
							...errors,
							[e.target.name]: '',
						};
					} else return errors;
				})
			);
		};
		return (
			<Fragment key={`secondary-traveler-${index}`}>
				<AddTravelers
					errors={partyErrors[index]}
					userId={user && user._id}
					handleChange={handleChange}
					index={index}
					traveler={traveler}
				/>
			</Fragment>
		);
	};

	const handleUserChange = (e) => {
		// e.preventDefault();
		let key = e.target.name;
		let { value } = e.target;
		handleUserText(key, value);

		if (userErrors[key] && value) {
			setUserErrors({
				...userErrors,
				[key]: '',
			});
		}
	};

	const travelerDocumentEvent = (e) => {
		e.preventDefault();
		handleTravelDocument(e.target.name, e.target.value);
	};

	return (
		<Fragment>
			{user && (
				<Fragment>
					<AddTravelers
						errors={userErrors}
						id={user._id}
						primary={true}
						traveler={user}
						travelerDocumentEvent={travelerDocumentEvent}
						handleChange={handleUserChange}
					/>
				</Fragment>
			)}

			{secondaryParty &&
				secondaryParty.map((traveler, index) =>
					drawSecondaryTraveler(traveler, index)
				)}
			<button onClick={toggleReview}>Review</button>
		</Fragment>
	);
};

PartyForms.propTypes = {
	user: PropTypes.object,
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

export default connect(mapStateToProps, {
	handleUserText,
	handleTravelDocument,
})(PartyForms);
