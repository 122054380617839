import React from 'react';
import InfoBubble from '../Layout/InfoBubble';

const FormInput = ({
	alert,
	handleChange,
	id,
	label,
	status,
	type,
	name,
	cName,
	value,
	hoverItem,
}) => {
	return (id || name) && label && type && handleChange ? (
		<>
			<div className={cName || 'form-group'}>
				<label htmlFor={id || name}>
					{label} {hoverItem && <InfoBubble hoverItem={hoverItem} />}
				</label>

				<input
					value={value}
					onChange={handleChange}
					type={type}
					id={id}
					name={name}
				/>
			</div>
			{status && status}
			{alert && alert}
		</>
	) : (
		<div className='missing-param'>Something important is missing!!</div>
	);
};

export default FormInput;
