import React from 'react';

const AuthFooter = ({ setCurrentForm, disabled, altForm, currentForm }) => {
	const returnButtonText = () => {
		if (currentForm === 'forgot-password') {
			return 'Reset';
		}
		if (currentForm === 'login') {
			return 'Sign In';
		}
		if (currentForm === 'sign-up') {
			return 'Sign Up';
		}
	};
	return (
		<div className='auth__footer'>
			<button
				disabled={disabled}
				className='newcss-cta-button newcss-cta-button-left'>
				{returnButtonText()}
			</button>
			<div>
				<span>
					{currentForm === 'sign-up'
						? 'Already have an account?'
						: `Don't have an account?`}
					<br />
					<button
						className='toggle'
						onClick={(e) => {
							e.preventDefault();
							setCurrentForm(
								currentForm === 'forgot-password' ? 'sign-up' : altForm
							);
						}}>
						{currentForm === 'sign-up' ? 'Sign In!' : `Sign Up!`}
					</button>
				</span>
				<span>
					{currentForm === 'forgot-password'
						? 'Already have an account?'
						: 'Forgot Password?'}
					<br />
					<button
						className='toggle'
						onClick={(e) => {
							e.preventDefault();
							setCurrentForm(
								currentForm === 'forgot-password' ? 'login' : 'forgot-password'
							);
						}}>
						{currentForm === 'forgot-password' ? 'Sign In!' : 'Reset Password!'}
					</button>
				</span>
			</div>
		</div>
	);
};

export default AuthFooter;
