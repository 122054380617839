import React from 'react';
import PricingHeader from './PricingHeader';
import LandingPopup from './LandingPopup';
import ReactGA from 'react-ga';

const PricingCard = ({
	tripForm,
	closePricing,
	moveStage,
	landing,
	bookingCheckout,
}) => {
	return (
		<div className={`service-section__pricing ${tripForm && 'trip-form'}`}>
			<div className='service-section__pricing__card'>
				<div className='top-overlay'></div>
				<PricingHeader
					landing={landing && true}
					booking={moveStage && true}
					curation={!moveStage && true}
				/>
				{bookingCheckout ? (
					<dl>
						<dt>✓</dt>
						<dd>
							$75 booking fee supports securing all items approved and
							authorized through the check out process.
						</dd>
						<dt>✓</dt>
						<dd>Support in coordinating and confirming with various vendors</dd>
						<dt>✓</dt>
						<dd>
							Liaison with questions and messages with various contacts
							domestically and internationally
						</dd>
					</dl>
				) : (
					<dl>
						<dt>✓</dt>
						<dd>
							$75 curation fee launches a travel advisor to create your
							customized trip based on your preferences and trip profile.
						</dd>
						<dt>✓</dt>
						<dd>
							You will receive hand selected hotels, flights, activities, and
							transportation options based on your preferences.
						</dd>
						<dt>✓</dt>
						<dd>
							Access to our user friendly calendar allows you to view and plan
							for an amazing adventure by only selecting the items of interest.
						</dd>
					</dl>
				)}

				{tripForm ? (
					<button
						style={{
							shape: 'pill',
							label: 'pay',
							layout: 'horizontal',
							tagline: false,
						}}
						onClick={() => {

							closePricing && closePricing();
							moveStage && moveStage(1);
						}}>
						Agree
					</button>
				) : (
					<LandingPopup name='get-started-button' start='sign-up' />
				)}
			</div>
		</div>
	);
};

export default PricingCard;
