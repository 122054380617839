import React from 'react';
import TripCard from './TripCard';

const TripCards = ({ trips }) => {
	return (
		<>
			{trips.length > 0
				? trips.map((savedTrip, index) => {
						if (
							savedTrip &&
							savedTrip.submitted &&
							savedTrip.submitted.success
						) {
							return (
								<TripCard
									key={`trip-card-${index}`}
									trip={savedTrip}
									index={index}
								/>
							);
						} else return null;
				  })
				: null}
		</>
	);
};

export default TripCards;
