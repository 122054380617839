import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { removeRedirect } from '../../redux/actions/redirect';

const redirectHOC = (WrappedComponent) => {
	const RedirectHOC = ({
		pathname,
		// setRedirect,
		removeRedirect,
		// location,
		...props
	}) => {
		const params = useParams();
		const returnDocumentTitle = (pathname, params) => {
			if (pathname) {
				let prefix = 'Rove ';
				let preferences = pathname.includes('-preferences')
					? ' - Preferences'
					: '';
				let createTrip = pathname.includes('create-trip')
					? ' - Trip Profile'
					: '';
				let editTrip = pathname.includes('edit-trip/')
					? ' - Edit Trip Profile'
					: '';
				let planTrip = pathname.includes('plan-trip/') ? ' - Itinerary' : '';
				let itinerary = pathname.includes('itinerary') ? ' - Dashboard' : '';
				let cart = pathname.includes('/cart/') ? 'Checkout' : '';
				const isAdmin = pathname.includes('admin') ? 'Admin' : '';

				let home = pathname === '/' ? 'Homepage' : null;
				let result =
					prefix +
					isAdmin +
					(preferences ||
						createTrip ||
						home ||
						editTrip ||
						planTrip ||
						cart ||
						itinerary);

				return result;
			} else {
				return false;
			}
		};

		useEffect(() => {
			if (window.location.pathname === pathname) removeRedirect();
		}, [window.location.pathname, pathname, removeRedirect]);

		const currentTitle = returnDocumentTitle(window.location.pathname, params);
		document.title = currentTitle || document.title;
		if (pathname) {
			return <Redirect to={pathname} />;
		}

		return <WrappedComponent {...props} />;
	};

	const mapStateToProps = (state) => ({
		pathname: state.redirect.pathname,
	});

	return connect(mapStateToProps, {
		// setRedirect,
		removeRedirect,
	})(RedirectHOC);
};

export default redirectHOC;
