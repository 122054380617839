import React from 'react';
import { Router } from 'react-router-dom';
import Footer from './components/Layout/Footer';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from 'react-redux';
import Routes from './config/Routes';
import { store, persistor } from './redux/store';
import dotenv from 'dotenv';
import { PersistGate } from 'redux-persist/integration/react';
import { run_ip_check } from './Utilities/api';
import { post } from './Utilities/api';
import ErrorPage from './components/pages/ErrorPage';
import { createBrowserHistory } from 'history';

dotenv.config();

if (localStorage.getItem('authToken')) {
	run_ip_check();
}

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };

		// Add name of the function to error logging
		Function.prototype.create = function () {
			this.displayName = this.name;
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	//log the error to an error reporting service
	componentDidCatch(error, errorInfo) {
		let last_error_time = localStorage.getItem('last_error_time');
		let now_time = new Date();

		localStorage.setItem('last_error_time', now_time.toISOString());

		let error_string = error.message;
		let error_info_string = JSON.stringify(errorInfo);

		//let error_details = `error message: "${error.message}".\n\r stack: ${error.stack}.\n\r component stack: ${errorInfo.componentStack}`;

		let error_details = `${error_string}, ERROR STACK: ${error_info_string}`;

		// Mitigate the issue of snowballing error saving - only save an error every 5s
		let is_over_5_seconds;
		if (last_error_time) {
			var startDate = new Date(last_error_time);
			var endDate = now_time;
			var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

			is_over_5_seconds = seconds >= 5;
		} else {
			is_over_5_seconds = true;
		}

		if (is_over_5_seconds) {
			post(
				'/api/v1/auth/save_error',
				{
					location: 'front-end-client',
					error_details: {
						error_details,
						number_of_occurances: 1,
					},
					endpoint: window.location.href,
				},
				true
			);
		}

		return <ErrorPage />;
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage />;
		} else {
			return this.props.children;
		}
	}
}

const WrappedBoundary = ErrorBoundary;

const App = (props) => {
	const newHistory = createBrowserHistory();
	try {
		return (
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<WrappedBoundary>
						<Router history={props.history ? props.history : newHistory}>
							<ScrollToTop>
								<div className='App'>
									<Routes />
									<Footer />
								</div>
							</ScrollToTop>
						</Router>
					</WrappedBoundary>
				</PersistGate>
			</Provider>
		);
	} catch (err) {
		return <ErrorPage />;
	}
};

export default App;
