import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CustomDateCellWrapper = ({
	children,
	event,
	returnDate,
	flightDetails,
	hotelDetails,
}) => {
	const returnTrueDate = (dateString) => {
		const dateArray = dateString.split('-');
		const year = dateArray[0];
		const month = parseInt(dateArray[1], 10) - 1;
		const date = dateArray[2];
		return new Date(year, month, date);
	};

	const currentDate = children._owner.memoizedProps.date;
	let minDate;
	let maxDate;
	let sortedflightDetails, sortedHotelDetails;
	if (flightDetails.length) {
		sortedflightDetails = flightDetails.sort((a, b) => {
			return new Date(a.departureDate) - new Date(b.departureDate);
		});
	}

	if (hotelDetails.length) {
		sortedHotelDetails = hotelDetails.sort((a, b) => {
			return new Date(a.startDate) - new Date(b.startDate);
		});
	}

	minDate =
		returnTrueDate(sortedflightDetails[0].departureDate) >
		returnTrueDate(sortedHotelDetails[0].startDate)
			? returnTrueDate(sortedHotelDetails[0].startDate)
			: returnTrueDate(sortedflightDetails[0].departureDate);

	if (returnDate) {
		maxDate = returnTrueDate(returnDate);
	} else {
		maxDate =
			returnTrueDate(
				sortedHotelDetails[sortedHotelDetails.length - 1].endDate
			) >
			returnTrueDate(
				sortedflightDetails[sortedflightDetails.length - 1].departureDate.slice(
					0,
					10
				)
			)
				? returnTrueDate(
						sortedHotelDetails[sortedHotelDetails.length - 1].endDate.slice(
							0,
							10
						)
				  )
				: returnTrueDate(
						sortedflightDetails[sortedflightDetails.length - 1].departureDate
				  );
	}
	maxDate.setDate(maxDate.getDate() + 1);
	minDate.setDate(minDate.getDate() - 1);

	const disableAtRange =
		currentDate > maxDate || currentDate < minDate ? 'disabled' : '';

	const customEventDiv = <div></div>;

	const eventDiv = React.cloneElement(children, {}, customEventDiv);
	const wrapper = React.cloneElement(children, {}, eventDiv);

	return <div className={`custom-date-cell ${disableAtRange}`}>{children}</div>;
};

/* CustomDateCellWrapper.propTypes = {
	flightDetails: PropTypes.array,
	returnDate: PropTypes.instanceOf(Date),
}; */

// const mapStateToProps = (state) => ({
// 	flightDetails: state.trip.flightDetails,
// 	hotelDetails: state.trip.hotelDetails,
// 	returnDate: state.trip.returnDate,
// });
export default CustomDateCellWrapper;
// connect(mapStateToProps, null)(CustomDateCellWrapper);
