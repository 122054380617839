import { findOneItem, setAddedItem } from './helperFunctions';
import _ from 'lodash';

const initialState = {
	_id: '',
	user: '',
	returnDate: '',
	flightType: '',
	flightDetails: [],
	hotelDetails: [],
	itineraryItems: null,
	dayTrips: [],
	multiDayTrips: [],
	adults: 1,
	children: 0,
	infants: 0,
	secondaryTravelers: [],
	activityThemes: [],
	travelType: '',
	purchase: {
		authorizedItems: {
			flights: [],
			lodging: [],
			transport: [],
			activities: [],
			amadeusCars: [],
		},
		tripCuration: {
			paid: false,
			paymentDate: null,
			orderConfirmation: null,
		},
		tripBooking: {
			paid: false,
			paymentDate: null,
			orderConfirmation: null,
		},
	},
	authorized: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	selected: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	paid: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	cart: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	support: null,
	comments: '',
	loading: false,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	let foundItem;
	let modifiedItem;
	switch (type) {
		case 'CLEAR_TRIP':
			return {
				_id: '',
				user: '',
				returnDate: '',
				flightType: '',
				flightDetails: [],
				hotelDetails: [],
				itineraryItems: null,
				dayTrips: [],
				multiDayTrips: [],
				adults: 1,
				children: 0,
				infants: 0,
				secondaryTravelers: [],
				activityThemes: [],
				travelType: '',
				purchase: {
					authorizedItems: {
						flights: [],
						lodging: [],
						transport: [],
						activities: [],
						amadeusCars: [],
					},
					tripCuration: {
						paid: false,
						paymentDate: null,
						orderConfirmation: null,
					},
					tripBooking: {
						paid: false,
						paymentDate: null,
						orderConfirmation: null,
					},
				},
				authorized: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				selected: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				paid: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				cart: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				support: null,
				comments: '',
				itineraryModalOpen: false,
				selectedItem: null,
				loading: false,
			};
		case 'SET_TRIP':
			payload.hotelDetails = payload?.hotelDetails
				.sort((a, b) => {
					if (a.startDate < b.startDate) return -1;
					else return 1;
				})
				.map((a, index) => {
					return { ...a, index };
				});

			return {
				...state,
				...payload,
				itineraryItems: {
					...state.itineraryItems,
					...payload.itineraryItems,
				},
				authorized: {
					...state.authorized,
					...payload.authorized,
				},
				selected: {
					...state.selected,
					...payload.selected,
				},
				paid: {
					...state.paid,
					...payload.paid,
				},
				cart: {
					...state.cart,
					...payload.cart,
				},
				flightDetails: payload.flightDetails.map((item, index) => {
					return { ...item, index };
				}),
				hotelDetails: payload.hotelDetails,
				dayTrips:
					payload.dayTrips?.map((item, index) => {
						return { ...item, index };
					}) || [],
				loading: false,
			};
		case 'ADD_EMPTY_DAYTRIP':
			return {
				...state,
				dayTrips: [...state.dayTrips, payload],
			};

		case 'REMOVE_DAYTRIP':
			return {
				...state,
				dayTrips: state.dayTrips.filter((item, index) => {
					return index !== payload;
				}),
			};
		case 'FILTER_EMPTY_ITEMS': {
			return {
				...state,
				hotelDetails: state.hotelDetails.filter((item) => {
					return item.city || item.startDate || item.endDate;
				}),
				dayTrips: state.dayTrips.filter((item) => {
					return item.city || item.date;
				}),
				flightDetails: state.flightDetails
					.filter((item) => {
						return item.departure || item.destination || item.departureDate;
					})
					.map((item, index) => {
						return { ...item, index };
					}),
			};
		}
		case 'MOVE_ITEM_TO':
			return {
				...state,
				[payload.currentGroup]: {
					...state[payload.currentGroup],
					[payload.itemKey]: state[payload.currentGroup][
						payload.itemKey
					].filter((item) => item._id !== payload.item._id),
				},
				[payload.nextGroup]: {
					...state[payload.nextGroup],
					[payload.itemKey]: [
						...state[payload.nextGroup][payload.itemKey],
						payload.item,
					],
				},
			};

		case 'ADD_ITEM_TO':
			return {
				...state,
				[payload.group]: {
					...state[payload.group],
					[payload.itemKey]: [
						...state[payload.group][payload.itemKey],
						payload.item,
					],
				},
			};

		case 'REMOVE_ITEM_FROM':
			return {
				...state,
				[payload.currentGroup]: {
					...state[payload.currentGroup],
					[payload.itemKey]: state[payload.currentGroup][
						payload.itemKey
					].filter((item) => item._id !== payload.item._id),
				},
			};
		case 'SET_SUPPORT':
			return {
				...state,
				support: payload,
			};
		case 'SET_FLIGHT_TYPE':
			return {
				...state,
				flightType: payload,
			};
		case 'ADD_EMPTY_FLIGHT':
			return {
				...state,
				flightDetails: [
					...state.flightDetails,
					{ ...payload, index: state.flightDetails.length },
				],
			};
		case 'EDIT_FLIGHT':
			return {
				...state,
				flightDetails: state.flightDetails.map((detail, index) =>
					detail.index === payload.index
						? { ...payload.data, index }
						: { ...detail, index }
				),
			};
		case 'REMOVE_HOTEL_BY_FLIGHT_INDEX':
			return {
				...state,
				hotelDetails: state.hotelDetails
					.filter((detail) => !payload[detail.flightIndex])
					.map((item) => {
						if (item.flightIndex > payload) {
							return {
								...item,
								flightIndex: item.flightIndex - 1,
							};
						} else {
							return item;
						}
					}),
			};

		case 'ADD_NEW_HOTEL':
			/* let updatedDetails = [
				...state.hotelDetails,
				{ ...payload, index: state.hotelDetails.length },
			]; */

			let updatedDetails = _.cloneDeep(state.hotelDetails);
			updatedDetails.push({ ...payload, index: state.hotelDetails.length });

			updatedDetails = updatedDetails.sort((a, b) => {
				if (a.index < b.index) return -1;
				else return 1;
			});

			return {
				...state,
				hotelDetails: updatedDetails,
			};
		case 'UPDATE_CUSTOM':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					custom: state.itineraryItems.custom.map((item, index) => {
						if (index === payload.index) {
							return {
								...payload,
								index: null,
							};
						}
						return item;
					}),
				},
			};
		case 'REMOVE_CUSTOM':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					custom: state.itineraryItems.custom.filter(
						(item, index) => index !== payload
					),
				},
			};
		case 'EDIT_SUGGESTED_HOTEL_INFO':
			return {
				...state,
				flightDetails: state.flightDetails.sort((a, b) => {
					if (a.index < b.index) return -1;
					else return 1;
				}),
				hotelDetails: state.hotelDetails
					.map((detail) => {
						if (detail.flightIndex === payload.hotel.flightIndex) {
							return { ...detail, ...payload.hotel };
						} else {
							return { ...detail };
						}
					})
					.sort((a, b) => {
						if (a.index < b.index) return -1;
						else return 1;
					}),
			};

		case 'SPLICE_NEW_ITEM':
			let sortedItems = state[payload.key].sort((a, b) => {
				if (a.index < b.index) return -1;
				else return 1;
			});
			sortedItems.splice(payload.index, 0, payload.item);
			sortedItems = sortedItems.map((item, index) => {
				return { ...item, index };
			});
			return {
				...state,
				[payload.key]: sortedItems,
			};

		case 'EDIT_HOTEL':
			return {
				...state,
				hotelDetails: state.hotelDetails
					.sort((a, b) => {
						if (a.index < b.index) return -1;
						else return 1;
					})
					.map((detail) =>
						detail.index === payload.index
							? { ...detail, [payload.key]: payload.value }
							: { ...detail }
					),
			};

		case 'REMOVE_HOTEL':
			return {
				...state,
				hotelDetails: state.hotelDetails
					.filter((detail) => detail.index !== payload)
					.sort((a, b) => {
						if (a.index < b.index) return -1;
						else return 1;
					})
					.map((a, index) => {
						return { ...a, index };
					}),
			};
		// case "REMOVE_FROM_ITINERARY":
		// 	return {
		// 		...state,
		// 		itineraryItems: {
		// 			...state.itineraryItems,
		// 			[payload.key]: state.itineraryItems[payload.key].map((item) => {
		// 				if (item._id === payload._id) {
		// 					return {
		// 						...item,
		// 						selected: false,
		// 						inCart: false,
		// 					};
		// 				}
		// 				return item;
		// 			}),
		// 		},
		// 	cart: {
		// 		...state.cart,
		// 		[payload.key]: state.cart[payload.key].filter(
		// 			(item) => item._id !== payload._id
		// 		),
		// 	},
		// };
		case 'SET_TRIP_TYPE':
			return {
				...state,
				travelType: action.payload,
				errors: {
					...state.errors,
					travelType: false,
				},
			};
		case 'DECREMENT':
			return {
				...state,
				[payload.range]: payload.amount,
				loading: false,
			};

		case 'SET_ITEM_UNITS':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].map((item) => {
						if (item._id === payload.id) {
							return {
								...item,
								units: payload.amount,
							};
						} else {
							return item;
						}
					}),
				},
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: payload,
			};

		case 'INCREMENT':
			return {
				...state,
				[payload.range]: payload.amount,
				loading: false,
			};

		// case 'SET_CUSTOM_START_END':
		// 	let foundStartEndActivity = state.itineraryItems[payload.key].find(
		// 		(item) => item._id === payload.id
		// 	);
		// 	let modifiedStartEndActivity = {
		// 		...foundStartEndActivity,
		// 		start: payload.start,
		// 		end: payload.end,
		// 	};
		// 	return {
		// 		...state,
		// 		itineraryItems: {
		// 			...state.itineraryItems,
		// 			[payload.key]: state.itineraryItems[payload.key].map((item) => {
		// 				if (item._id === payload.id) {
		// 					return modifiedStartEndActivity;
		// 				} else {
		// 					return item;
		// 				}
		// 			}),
		// 		},
		// 	};
		// case 'SELECT_ITEM':
		// 	return {
		// 		...state,
		// 		selectedItem: state[payload.key].find((item) => item.id === payload.id),
		// 		itineraryModalOpen: true,
		// 	};

		// ///////////////////////////////
		// ///////////////////////////////
		// ///////////////////////////////
		// ///////////////////////////////
		case 'ADD_FLIGHT_DETAIL':
			return {
				...state,
				flightDetails: [...state.flightDetails, payload],
				loading: false,
			};
		case 'EDIT_FLIGHT_DETAIL':
			let new_flights = state.flightDetails.map((flight, index) => {
				if (flight.index === payload.index) {
					return { ...flight, [payload.key]: payload.value };
				} else {
					return flight;
				}
			});

			new_flights = new_flights.sort((a, b) => {
				if (a.index < b.index) return -1;
				else return 1;
			});

			let data = {
				...state,
				flightDetails: new_flights,
				loading: false,
			};

			return data;

		case 'UPDATE_HOTEL_DETAILS':
			return {
				...state,
				hotelDetails: payload,
			};
		case 'UPDATE_FLIGHT_DETAILS':
			return {
				...state,
				flightDetails: payload,
			};
		case 'REMOVE_FLIGHT':
			return {
				...state,
				flightDetails: state.flightDetails
					.filter((flight) => flight.index !== payload)
					.map((item, index) => {
						return { ...item, index };
					}),
			};
		case 'REMOVE_ALL_FLIGHTS':
			return {
				...state,
				flightDetails: [],
			};
		case 'QUERY_ATTACHMENTS':
			return {
				...state,
				attachments: payload,
			};
		case 'ADD_ACTIVITY_THEME':
			return {
				...state,
				activityThemes: [...state.activityThemes, payload],
				errors: {
					...state.errors,
					activities: false,
				},
				loading: false,
			};
		case 'APPEND_HOTEL_DETAIL':
			return {
				...state,
				hotelDetails: state.hotelDetails.map((item) =>
					item.id !== payload.id ? payload : item
				),
				loading: false,
			};
		case 'ADD_DAY_TRIP':
			return {
				...state,
				dayTrips: [
					...state.dayTrips.filter((trip) => trip.id !== payload.id),
					payload,
				],
				loading: false,
			};

		case 'EDIT_DAYTRIP':
			return {
				...state,
				dayTrips: state.dayTrips.map((item, index) => {
					if (index === payload.index) {
						return {
							...item,
							[payload.key]: payload.value,
						};
					}
					return item;
				}),
			};
		case 'REMOVE_MULTIDAY':
			return {
				...state,
				multiDayTrips: state.multiDayTrips.filter(
					(trip) => trip.id !== payload
				),
				loading: false,
			};
		case 'REMOVE_DAY_TRIP':
			return {
				...state,
				dayTrips: state.dayTrips.filter((trip, index) => index !== payload),
				loading: false,
			};

		case 'REMOVE_ACTIVITY_THEME':
			return {
				...state,
				activityThemes: [
					...state.activityThemes.filter((activity) => activity !== payload),
				],
				loading: false,
			};
		case 'HANDLE_TEXT':
			return {
				...state,
				[payload.name]: payload.text,
			};
		case 'ADD_DATE':
			return {
				...state,
				[payload.property]: payload.date,
			};
		case 'SET_DEPARTURE':
			return state.filter((alert) => alert.id !== action.payload);

		case 'ADD_TO_CART':
			foundItem = findOneItem(state.itineraryItems[payload.key], payload._id);

			return {
				...state,
				cart: {
					...state.cart,
					[payload.key]: [...state.cart[payload.key], foundItem],
				},
				selected: {
					...state.selected,
					[payload.key]: [
						...state.selected[payload.key].filter(
							(item) => item._id !== payload._id
						),
					],
				},
			};
		case 'REMOVE_ATTACHMENT':
			return {
				...state,
				attachments: state.attachments.filter(
					(item, index) => index !== payload.index
				),
			};
		case 'REMOVE_FROM_CART':
			foundItem = findOneItem(state.itineraryItems[payload.key], payload._id);
			modifiedItem =
				payload.key === 'transport' || payload.key === 'activities'
					? {
							...foundItem,
							start: null,
							end: null,
					  }
					: foundItem;
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].map((item) =>
						item._id === payload._id ? modifiedItem : item
					),
				},
				cart: {
					...state.cart,
					[payload.key]: state.cart[payload.key].filter(
						(item) => item._id !== payload._id
					),
				},
				selected: {
					...state.selected,
					[payload.key]: state.selected[payload.key].filter(
						(item) => item._id !== payload._id
					),
				},
			};
		case 'ADD_CUSTOM_EVENT':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					custom: state.itineraryItems.custom
						? [...state.itineraryItems.custom, payload]
						: [payload],
				},
			};

		case 'REMOVE_CUSTOM_EVENT':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					custom: state.itineraryItems.custom.filter(
						(item, index) => index !== payload
					),
				},
			};

		case 'SET_ERROR':
			return {
				...state,
				errors: {
					...state.errors,
					[payload]: true,
				},
			};

		case 'UPDATE_CART_ITEM':
			return {
				...state,
				cart: {
					...state.cart,
					[payload.key]: state.cart[payload.key].map((item) => {
						if (payload.item._id === item._id) return payload.item;
						return item;
					}),
				},
			};
		default:
			return state;
	}
}
