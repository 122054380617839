import React from 'react';
import CategoryButton from './CategoryButton';

import flight from '../../images/flight.svg';
import hotel from '../../images/hotel.svg';
import activity from '../../images/activity.svg';
import transportation from '../../images/transportation.svg';
import { connect } from 'react-redux';

const CategoryButtons = ({ handleToggle, handleSelectedClass }) => {
	return (
		<>
			<CategoryButton
				onClick={handleToggle}
				value='Flights'
				className={handleSelectedClass('Flights')}
				image={flight}
			/>

			<CategoryButton
				onClick={handleToggle}
				value='Hotels'
				className={handleSelectedClass('Hotels')}
				image={hotel}
			/>

			<CategoryButton
				onClick={handleToggle}
				value='Activities'
				className={handleSelectedClass('Activities')}
				image={activity}
			/>

			<CategoryButton
				onClick={handleToggle}
				value='Transportation'
				className={handleSelectedClass('Transportation')}
				image={transportation}
			/>
			<section
				className={handleSelectedClass('Add Sticker')}
				onClick={() => {
					handleToggle('Add Your Own Item');
				}}>
				<span>+ </span>
			</section>
		</>
	);
};

export default connect(null, null)(CategoryButtons);
