import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useRef } from 'react';
import { useEffect } from 'react';
import config from '../../config/config';
import CardInfoIFrame from './CardInfoIFrame';
// const { emailMeURL } = require('../../config/config');

const CardInfo = ({ setIframeSubmitSuccess, trip_id, user_id, iframeSubmitSuccess }) => {
	const [source, setSource] = useState('');
	useEffect(() => {
		var eventMethod = window.addEventListener
			? 'addEventListener'
			: 'attachEvent';
		var eventer = window[eventMethod];
		var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

		eventer(messageEvent, function (e) {
			if (e.data === 'form submitted' || e.message === 'form submitted') {
				setIframeSubmitSuccess(true);
			}
		});
	});

	useEffect(() => {
		if (user_id) {
			setSource(`${config.emailMeURL}${user_id}&trip_id=${trip_id}`);
		}
		// console.log('ref.current', emailMeOuterRef.current);
		// setIframeHeight(emailMeOuterRef.current.offsetHeight);
	}, [user_id]);

	return (
		<>
			<div
				className={`payment-details__section email-me${
					iframeSubmitSuccess ? ' submitted' : ''
				}`}>
				<iframe
					id='emailmeform_card'
					width='100%'
					allowtransparency='true'
					frameBorder='0'
					scrolling='no'
					style={{ border: 'none', overflow: 'visible' }}
					src={source}
					allow='camera'>
					<a href={source}>Fill out form.</a>
				</iframe>

				{/* <CardInfoIFrame source={source} /> */}
			</div>
		</>
	);
};

CardInfo.propTypes = {};

export default CardInfo;
