import React from 'react';

const CategoryButton = ({ onClick, className, title, value, image }) => {
	return (
		<section className={className} value={value} onClick={() => onClick(value)}>
			{!!image && <img src={image} alt={`${title} category icon`} />}
		</section>
	);
};

export default CategoryButton;
