const initialState = {
	userProfile: null,
	loading: true,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case 'SET_USER_PROFILE':
			return {
				...state,
				userProfile: payload,
			};
		case 'CLEAR_PROFILE':
			return {
				...state,
				userProfile: null,
				loading: false,
			};

		default:
			return state;
	}
}
