import React from 'react';
import LandingPopup from './LandingPopup';

const LandingSubheader = (props) => {
	return (
		<div className='landing-subheader'>
			<h1 className='landing-subheader__text'>Travel planning reimagined.</h1>
			<LandingPopup name='get-started-button' start='sign-up' />
		</div>
	);
};

export default LandingSubheader;
