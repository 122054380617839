import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Amenities from './selectedModal/Amenities';
import RoomRateDescription from './selectedModal/RoomRateDescription';
import Guarantee from './selectedModal/Guarantee';
import LodgingDates from './selectedModal/LodgingDates';
import CancelPenalties from './selectedModal/CancelPenalties';

const LodgingInfo = ({ selectedItem }) => {
	let amenities;
	let features =
		selectedItem &&
		selectedItem.data &&
		selectedItem.data['RoomRates'] &&
		selectedItem.data['RoomRates'].length &&
		selectedItem.data['RoomRates'][0]['RoomRate'] &&
		selectedItem.data['RoomRates'][0]['RoomRate'].length &&
		selectedItem.data['RoomRates'][0]['RoomRate'][0]['Features'];

	let roomAmenities = features
		? features[0]['Feature'].map((item) => {
				return {
					fromRoom: true,
					...item,
				};
		  })
		: [];

	const services =
		selectedItem.data.descriptive_info &&
		selectedItem.data.descriptive_info['HotelInfo'][0]['Services'];
	const hotelAmenities = services
		? services[0]['Service']
				.filter((item) => {
					const info = item && item.info;
					const hasAmenityCode = info && info['Code'];
					const hasNoProximity = info && !info['ProximityCode'];
					const isOnsite =
						info && info['ProximityCode'] && info['ProximityCode'] === '1';
					const handleProximity = hasNoProximity || isOnsite;
					return hasAmenityCode && handleProximity;
				})
				.map((item) => {
					return {
						hotelAmenity: true,
						...item,
					};
				})
		: [];
	const GuestRooms =
		selectedItem &&
		selectedItem.data &&
		selectedItem.data.descriptive_info &&
		selectedItem.data.descriptive_info['FacilityInfo'] &&
		selectedItem.data.descriptive_info['FacilityInfo'].length &&
		selectedItem.data.descriptive_info['FacilityInfo'][0]['GuestRooms']
			? selectedItem.data.descriptive_info['FacilityInfo'][0]['GuestRooms']
			: null;
	const amenityGuestRoom =
		GuestRooms && GuestRooms.length && GuestRooms[0]['GuestRoom']
			? GuestRooms[0]['GuestRoom'].find((item) => item['Amenities'])
			: null;

	amenities =
		amenityGuestRoom &&
		amenityGuestRoom['Amenities'] &&
		amenityGuestRoom['Amenities'].length &&
		amenityGuestRoom['Amenities'][0]['Amenity']
			? amenityGuestRoom['Amenities'][0]['Amenity'].map((item) => {
					return {
						roomAmenity: true,
						...item,
					};
			  })
			: [];
	amenities = [...amenities, ...roomAmenities, ...hotelAmenities];

	const returnRatePlanData = () => {
		const ratePlan =
			selectedItem &&
			selectedItem.data &&
			selectedItem.data['RatePlans'] &&
			selectedItem.data['RatePlans'].length &&
			selectedItem.data['RatePlans'][0]['RatePlan'] &&
			selectedItem.data['RatePlans'][0]['RatePlan'].length
				? selectedItem.data['RatePlans'][0]['RatePlan'][0]
				: null;
		let guarantee = ratePlan && ratePlan['Guarantee'];
		let cancelPenalties =
			ratePlan &&
			ratePlan['CancelPenalties'] &&
			ratePlan['CancelPenalties'].length &&
			ratePlan['CancelPenalties'][0]['CancelPenalty']
				? ratePlan['CancelPenalties'][0]['CancelPenalty']
				: null;
		let guaranteeType =
			guarantee &&
			guarantee.length &&
			guarantee[0].info &&
			guarantee[0].info['GuaranteeType']
				? guarantee[0].info['GuaranteeType']
				: null;
		let guaranteesAccepted =
			guarantee &&
			guarantee.length &&
			guarantee[0]['GuaranteesAccepted'] &&
			guarantee[0]['GuaranteesAccepted'].length &&
			guarantee[0]['GuaranteesAccepted'][0]['GuaranteeAccepted']
				? guarantee[0]['GuaranteesAccepted'][0]['GuaranteeAccepted']
				: null;
		return { guaranteeType, guaranteesAccepted, cancelPenalties };
	};

	const returnLodgingInfo = () => {
		const { guaranteeType, guaranteesAccepted, cancelPenalties } =
			returnRatePlanData();
		return selectedItem &&
			selectedItem.data &&
			selectedItem.data.descriptive_info ? (
			<Fragment>
				<Amenities amenities={amenities} />
				<RoomRateDescription selectedItem={selectedItem} />
				<Guarantee
					guaranteeType={guaranteeType}
					guaranteesAccepted={guaranteesAccepted}
				/>
				<LodgingDates descriptive_info={selectedItem.data.descriptive_info} />
				{cancelPenalties && (
					<CancelPenalties cancelPenalties={cancelPenalties} />
				)}
			</Fragment>
		) : null;
	};

	return returnLodgingInfo();
};

LodgingInfo.propTypes = {};

export default LodgingInfo;
