import React from 'react';
import moment from 'moment';

const SingleTimeInfo = ({ startTime, endTime }) => {
	return startTime && endTime ? (
		<div>
			<span>Start: {moment(new Date(startTime)).format('LLL')}</span>
			<span>End: {moment(new Date(endTime)).format('LLL')}</span>
		</div>
	) : null;
};

export default SingleTimeInfo;
