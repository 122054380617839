import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	addMultiOptionPreference,
	removeMultiOptionPreference,
} from '../../redux/actions/preferences';
import ToggleButton from './ToggleButton';
import SingleOptionButton from './SingleOptionButton';
import { motion } from 'framer-motion';

const MultiOptionPreference = ({
	name,
	title,
	next,
	back,
	children,
	addMultiOptionPreference,
	removeMultiOptionPreference,
	preferences,
	cname,
	submit,
	edit,
	direction,
}) => {
	return (
		<>
			<motion.section
				key={name + title}
				initial={{ transform: `translateX(${150 * direction}%)` }}
				animate={{ transform: 'translateX(0%)' }}
				exit={{ transform: `translateX(${-150 * direction}%)` }}
				transition={{ duration: 0.4 }}>
				<h3 className='preference-title'>{title}</h3>
				<p className='preference-subtitle'>Select all that apply</p>
				<div className='preference-card'>
					{children &&
						children.map((child, index) => {
							const selected =
								preferences[name].includes(child.title) ||
								preferences[name] === child.title;
							return (
								<SingleOptionButton
									key={`${title}-${index}`}
									onClick={() =>
										!preferences[name].includes(child.title)
											? addMultiOptionPreference(name, child.title)
											: removeMultiOptionPreference(name, child.title)
									}
									title={child.title}
									subtitle={child.subtitle}
									cname={selected ? `newcss-selected-button` : null}
								/>
							);
						})}
				</div>

				<div className='button-section'>
					{edit ? null : (
						<>
							{back && <ToggleButton text='Back' buttonAction={back} />}
							{next && <ToggleButton text='Next' buttonAction={next} />}
						</>
					)}
					{submit && (
						<ToggleButton
							centered={edit && true}
							text='Submit'
							buttonAction={submit}
						/>
					)}
				</div>
			</motion.section>
		</>
	);
};

MultiOptionPreference.propTypes = {
	addMultiOptionPreference: PropTypes.func.isRequired,
	removeMultiOptionPreference: PropTypes.func.isRequired,
	preferences: PropTypes.object,
};

const mapStateToProps = (state) => ({
	preferences: state.preferences,
});

export default connect(mapStateToProps, {
	addMultiOptionPreference,
	removeMultiOptionPreference,
})(MultiOptionPreference);
