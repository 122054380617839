import React from 'react';

const Note = ({ note }) => {
	return (
		<div className='admin-note'>
			<span>Note From Admin:</span>
			<p>{note}</p>
		</div>
	);
};

export default Note;
