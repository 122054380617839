import moment from 'moment';
import React, { Fragment } from 'react';
import { returnTrueDate } from '../../../Utilities/helperFunctions';
import DateButtonSection from './DateButtonSection';
import SingleTimeInfo from './SingleTimeInfo';

const DayTable = ({ selectedItem, selectedDay, setSelectedDay }) => {
	if (selectedItem.dateRange && !selectedItem.singleTime) {
		const { start, end } = selectedItem.dateRange;
		let results = [];
		let trueStart = returnTrueDate(start);
		let trueEnd = returnTrueDate(end);

		for (
			let date = trueStart;
			date <= trueEnd;
			date.setDate(date.getDate() + 1)
		) {
			const newDate = new Date(date);
			if (
				selectedItem.dayOptions &&
				newDate &&
				selectedItem.dayOptions[moment(newDate).format('dddd')]
			) {
				results.push(newDate);
			} else {
				results.push(newDate);
			}
		}
		return (
			<Fragment>
				<DateButtonSection
					dayOptions={selectedItem.dayOptions}
					dates={results}
					selectedDay={selectedDay}
					setSelectedDay={setSelectedDay}
				/>
			</Fragment>
		);
	} else if (selectedItem.dateRange && selectedItem.singleTime) {
		const { start, end } = selectedItem;
		return <SingleTimeInfo startTime={start} endTime={end} />;
	} else return null;
};

export default DayTable;
