import React from 'react';

const CartToggler = ({ currentView, setCurrentView }) => {
	return (
		<div className='cart-toggler'>
			<button
				onClick={() => {
					setCurrentView('My Cart');
				}}
				className={`${currentView === 'My Cart' && 'active'}`}>
				My Cart
			</button>
			<button
				onClick={() => {
					setCurrentView('Saved For Later');
				}}
				className={`${currentView === 'Saved For Later' && 'active'} right`}>
				Saved for Later
			</button>
		</div>
	);
};

export default CartToggler;
