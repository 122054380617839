import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PartyForms from './PartyForms';
import PartyReview from './PartyReview';
import { updateParty } from '../../redux/actions/trip';

const PartyInfo = ({
	moveStage,
	trip,
	user,
	updateParty,
	adults,
	children,
	infants,
	secondaryCheckout,
	partyReview,
	setPartyReview,
}) => {
	const { secondaryTravelers } = trip;

	const [secondaryParty, setSecondaryParty] = useState([]);
	const [partyErrors, setPartyErrors] = useState([]);
	const [userErrors, setUserErrors] = useState({
		firstName: '',
		lastName: '',
		dob: '',
	});

	const handleSave = async () => {
		try {
			const success = await updateParty({
				tripId: trip._id,
				secondaryTravelers: secondaryParty,
				dob: user.dob,
				phoneNumber: user.phoneNumber,
				middleNameOrInitial: user.middleNameOrInitial,
				gender: user.gender,
				travelerDocument: user.travelerDocument,
			});
			if (success) moveStage(1);
		} catch (err) {
			const message = err.message;
			console.log(message);
		}
	};
	const handleToggle = () => {
		localStorage.removeItem('editTravelers');

		let valid = true;
		valid = !user.firstName || !user.lastName || !user.dob ? false : valid;
		setUserErrors({
			firstName: !user.firstName ? `Please fill out party's first name.` : '',
			lastName: !user.lastName ? `Please fill out party's last name.` : '',
			dob: !user.dob ? `Please fill out party's date of birth.` : '',
		});
		setPartyErrors(
			secondaryParty.map((party) => {
				const errorResult = {
					firstName: '',
					lastName: '',
					dob: '',
				};
				if (!party.firstName) {
					errorResult.firstName = `Please fill out party's first name.`;
					valid = false;
				}
				if (!party.lastName) {
					errorResult.lastName = `Please fill out party's last name.`;
					valid = false;
				}
				if (!party.dob) {
					errorResult.dob = `Please fill out party's date of birth.`;
					valid = false;
				}
				return errorResult;
			})
		);
		if (valid === true) {
			setPartyReview(true);
		}
	};

	useEffect(() => {
		const nums = [...secondaryTravelers];
		const errors = [];
		for (
			let num = secondaryTravelers.length;
			num < adults + children + infants - 1;
			num++
		) {
			nums.push({
				title: '',
				firstName: '',
				user: user._id,
				lastName: '',
				middleName: '',
				suffix: '',
				dob: '',
				gender: 'female',
				default: false,
			});
		}
		if (nums.length !== secondaryParty.length) {
			setSecondaryParty(nums);
		}
		nums.forEach((num) => {
			errors.push({ firstName: '', lastName: '', dob: '' });
		});
		setPartyErrors(errors);

		if (
			secondaryTravelers.length &&
			secondaryParty.length &&
			secondaryTravelers.length === secondaryParty.length
		) {
			if (localStorage.getItem('editTravelers')) setPartyReview(false);
			else setPartyReview(true);
		}
	}, [adults, children, infants, secondaryTravelers, user._id]);

	return (
		<Fragment>
			<div className='newcss-traveler-information-container'>
				<h1>Traveler Information</h1>
				<p>
					To comply with the TSA Secure Flight program, the traveler information
					listed here must exactly match the information on the
					government-issued photo ID that the traveler presents at the airport.
				</p>
				{!partyReview ? (
					trip && secondaryTravelers ? (
						<PartyForms
							toggleReview={handleToggle}
							setSecondaryParty={setSecondaryParty}
							secondaryParty={secondaryParty}
							userErrors={userErrors}
							setUserErrors={setUserErrors}
							partyErrors={partyErrors}
							setPartyErrors={setPartyErrors}
						/>
					) : null
				) : (
					<Fragment>
						<PartyReview secondaryParty={secondaryParty} />
						<button
							onClick={() => {
								localStorage.setItem('editTravelers', true);
								setPartyReview(false);
							}}>
							Edit
						</button>
						<button onClick={handleSave}>Save and Continue</button>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};
PartyInfo.propTypes = {
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
	user: PropTypes.object,
};

const mapStateToProps = (state) => ({
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
	user: state.auth.user,
	trip: state.trip,
});

export default connect(mapStateToProps, { updateParty })(PartyInfo);
