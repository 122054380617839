import React from 'react';
import TransportationPreferences from './TransportationPreferences';
import ActivityPreferences from './ActivityPreferences';
import LodgingPreferences from './LodgingPreferences';
import FlightPreferences from './FlightPreferences';
import PreferenceKickOff from './PreferenceKickOff';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';

const CurrentPreference = ({
	preferenceData,
	setCurrentPreference,
	changePreferenceIndex,
	setLoading,
	submitPreferences,
}) => {
	const [direction, setDirection] = useState(1);

	const handleDirection = (direction) => {
		setDirection(direction);
		setTimeout(() => {
			changePreferenceIndex(direction);
		}, 50);
	};
	const windows = [
		{
			name: 'kickoff',
			component: (
				<PreferenceKickOff
					nextComponent={() => setCurrentPreference('flight', 0)}
				/>
			),
		},
		{
			name: 'flight',
			component: (
				<FlightPreferences
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={null}
					nextComponent={() => setCurrentPreference('lodging', 0)}
				/>
			),
		},

		{
			name: 'lodging',
			component: (
				<LodgingPreferences
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => setCurrentPreference('flight', 4)}
					nextComponent={() => setCurrentPreference('transportation', 0)}
				/>
			),
		},

		{
			name: 'transportation',
			component: (
				<TransportationPreferences
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => setCurrentPreference('lodging', 2)}
					nextComponent={() => setCurrentPreference('activity', 0)}
				/>
			),
		},

		{
			name: 'activity',
			component: (
				<ActivityPreferences
					direction={direction}
					setDirection={setDirection}
					submitPreferences={submitPreferences}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => setCurrentPreference('transportation', 2)}
				/>
			),
		},
	];

	const handleDirectionChange = (newDirection, fn, fnParams) => {
		setDirection(newDirection);
		setTimeout(() => {
			fn(...fnParams);
		}, 50);
	};
	const handleComponentChange = () => {
		if (preferenceData.name === 'kickoff') {
			return (
				<PreferenceKickOff
					nextComponent={() => {
						handleDirectionChange(1, setCurrentPreference, ['flight', 0]);
					}}
				/>
			);
		}
		if (preferenceData.name === 'flight') {
			return (
				<FlightPreferences
					handleDirection={handleDirection}
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={null}
					nextComponent={() => {
						handleDirectionChange(1, setCurrentPreference, ['lodging', 0]);
					}}
				/>
			);
		}

		if (preferenceData.name === 'lodging') {
			return (
				<LodgingPreferences
					handleDirection={handleDirection}
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => {
						handleDirectionChange(-1, setCurrentPreference, ['flight', 4]);
					}}
					nextComponent={() => {
						handleDirectionChange(1, setCurrentPreference, [
							'transportation',
							0,
						]);
					}}
				/>
			);
		}
		if (preferenceData.name === 'transportation') {
			return (
				<TransportationPreferences
					handleDirection={handleDirection}
					direction={direction}
					setDirection={setDirection}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => {
						handleDirectionChange(-1, setCurrentPreference, ['lodging', 2]);
					}}
					nextComponent={() => {
						handleDirectionChange(1, setCurrentPreference, ['activity', 0]);
					}}
				/>
			);
		}
		if (preferenceData.name === 'activity') {
			return (
				<ActivityPreferences
					handleDirection={handleDirection}
					direction={direction}
					setDirection={setDirection}
					submitPreferences={submitPreferences}
					index={preferenceData.index}
					changePreferenceIndex={changePreferenceIndex}
					previousComponent={() => {
						handleDirectionChange(-1, setCurrentPreference, [
							'transportation',
							2,
						]);
					}}
				/>
			);
		}
	};

	return handleComponentChange();
};

export default CurrentPreference;
