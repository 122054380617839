import React, { useEffect, useState } from 'react';
import Activity from '../Activities/Activity';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CityStateSelector from './CityStateSelector';

const ActivityOptions = ({
	activities,
	adults,
	children,
	infants,
	selectedItem,
}) => {
	const [activityGroups, setActivityGroups] = useState(null);

	const [selectedCity, setSelectedCity] = useState('All Cities');

	useEffect(() => {
		let groups = { 'All Cities': activities };
		if (activities && activities.length) {
			activities.forEach((option, index) => {
				const key = `${option.cityName}, ${option.countryName}`;
				if (groups[key]) {
					groups[key] = [...groups[key], option];
				} else {
					groups[key] = [option];
				}
			});
		}
		setActivityGroups(groups);
	}, [activities]);

	const groupAmount = adults + children + infants;

	const returnActivityGroup = () => {
		return (
			activityGroups &&
			activityGroups[selectedCity] &&
			activityGroups[selectedCity].length &&
			activityGroups[selectedCity].map((data) => {
				return (
					<div
						key={data._id}
						className={`newcss-item-preview${
							selectedItem && selectedItem._id === data._id
								? ' selected-item'
								: ''
						}`}
						style={{
							display: 'block',
						}}>
						<section className='inner'>
							<Activity data={data} groupAmount={groupAmount} />
						</section>
					</div>
				);
			})
		);
	};

	return (
		<>
			<CityStateSelector
				cities={activityGroups && Object.keys(activityGroups)}
				setSelectedCity={setSelectedCity}
				selectedCity={selectedCity}
			/>

			{activityGroups ? (
				<div className='newcss-itinerary'>{returnActivityGroup()}</div>
			) : null}
		</>
	);
};

ActivityOptions.propTypes = {
	activities: PropTypes.array,
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
};

const mapStateToProps = (state) => ({
	activities: state.trip.itineraryItems.activities,
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, null)(ActivityOptions);
