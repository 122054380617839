import React from 'react';
import foodTruck from '../../images/foodTruck.png';
import hotel2 from '../../images/hotel2.png';
import CalSample from './CalSample';

const WhyImageDiv = () => {
	return (
		<div className='why-section__images' id='why-image-div'>
			<img src={hotel2} alt='' className='why-section__image1' />
			<img src={foodTruck} alt='' className='why-section__image2' />
			<CalSample />
		</div>
	);
};

export default WhyImageDiv;
