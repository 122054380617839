import React, { useEffect, useRef, useState } from 'react';
import {
	addNewHotel,
	appendHotelDetail,
	editHotel,
	removeHotel,
	removeMultiday,
	spliceNewItem,
} from '../../../redux/actions/trip';
import { connect } from 'react-redux';
import {
	addHotelErrorObject,
	setHotelError,
} from '../../../redux/actions/errors';
import ErrMessage from '../../Layout/ErrMessage';
import { returnCitySearch } from '../../../Utilities/findCityCode';
import { capitalize, returnTrueDate } from '../../../Utilities/helperFunctions';
import { motion } from 'framer-motion';
import AutofillSection from '../../Inputs/AutofillSection';

const MultiDayTripInputs = ({
	spliceNewItem,
	currentCity,
	currentStart,
	currentEnd,
	suggestedStart,
	suggestedEnd,
	currentRadius,
	index,
	editHotel,
	removeHotel,
	currentPriceRange,
	currentRooms,
	idealLocation,
	lodgingPrice,
	addHotelErrorObject,
	errors,
	setHotelError,
	hotelInfo,
	prevHotel,
	nextHotel,
	flightDetails,
	hotelDetails,
	returnDate,
}) => {
	const heightRefs = useRef([]);
	const [tripInputs, setTripInputs] = useState({
		city: '',
		startDate: '',
		endDate: '',
		radius: idealLocation || '',
		priceRange: lodgingPrice || '',
	});
	const [searchResults, setSearchResults] = useState([]);
	const handleCitySearch = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const searchValue = value.toLowerCase();
		const searchLength = searchValue.length;
		setTripInputs({
			...tripInputs,
			[name]: value,
		});
		if (!searchValue) {
			setSearchResults([]);
		} else if (searchLength > 2) {
			const results = returnCitySearch(searchValue);
			setSearchResults(results);
		}
	};

	const handleAddHotel = () => {
		if (currentCity && currentStart && currentEnd) {
			const newHotelSection = {
				city: '',
				startDate: currentEnd,
				endDate: '',
				radius: idealLocation || '',
				priceRange: lodgingPrice || '',
				rooms: 1,
			};
			spliceNewItem(index + 1, newHotelSection, 'hotelDetails');
			addHotelErrorObject();
		} else {
			const addError = (value, key, message) => {
				if (!value) {
					setHotelError(index, key, message);
				}
			};
			addError(currentCity, 'city', 'Please add valid location');
			addError(currentStart, 'startDate', 'Please add valid location');
			addError(currentEnd, 'endDate', 'Please add valid location');
		}
	};

	const handleReduxChange = (e) => {
		e.preventDefault();
		let value;
		if (e.target.name === 'radius' || e.target.name === 'priceRange') {
			value = JSON.parse(e.target.value);
		} else value = e.target.value;

		const { name } = e.target;
		editHotel(index, name, value);
	};
	const handleDate = (date) => {
		return typeof date === 'string' ? date : date.toDateString();
	};

	const returnSearchResults = () => {
		return searchResults.length
			? searchResults.map((result, resultIndex) => {
					const { cityCode, countryCode, cityName, stateCode, countryName } =
						result;
					return (
						<div
							ref={(el) =>
								resultIndex < 5 ? (heightRefs.current[resultIndex] = el) : null
							}
							key={`multicity-search-result-${index}-${resultIndex}`}
							className='autofill-option'
							onClick={() => {
								editHotel(index, 'city', {
									cityCode,
									countryCode,
									cityName,
									stateCode,
								});
								setSearchResults([]);
							}}>
							<p className='autofill-airport-name capitalize'>
								{cityName},{' '}
								{stateCode && stateCode !== 'null'
									? `${stateCode.toUpperCase()}, `
									: ''}
								{countryName}
							</p>
						</div>
					);
			  })
			: null;
	};

	useEffect(() => {
		setTripInputs({
			city: currentCity && capitalize(currentCity.cityName),
			startDate: currentStart ? handleDate(currentStart).slice(0, 10) : '',
			endDate: currentEnd ? handleDate(currentEnd).slice(0, 10) : '',
			radius: currentRadius || idealLocation,
			priceRange: currentPriceRange || lodgingPrice,
			rooms: currentRooms,
		});
		if (
			currentStart &&
			currentEnd &&
			returnTrueDate(currentStart) > returnTrueDate(currentEnd)
		) {
			setHotelError(
				index,
				'startDate',
				'Start Date must be before the end date'
			);
			setHotelError(index, 'endDate', 'End date must be after start date.');
		}
	}, [hotelInfo]);

	useEffect(() => {
		if (!errors) {
			addHotelErrorObject();
		} else {
			if (currentCity && errors.city) {
				setHotelError(index, 'city', '');
			}
			let sortedFlightDetails = flightDetails.sort((a, b) => {
				if (a.index < b.index) return -1;
				else return 1;
			});
			const isClosed =
				sortedFlightDetails[0].departure ===
				sortedFlightDetails[sortedFlightDetails.length - 1].destination;

			let formattedStart = currentStart && returnTrueDate(currentStart);
			let formattedEnd = currentEnd && returnTrueDate(currentEnd);
			const prevHotelEnd =
				prevHotel && prevHotel.endDate && returnTrueDate(prevHotel.endDate);
			const nextHotelStart =
				nextHotel && nextHotel.startDate && returnTrueDate(nextHotel.startDate);
			let returnFlightDate =
				returnDate ||
				(flightDetails.length &&
					flightDetails[flightDetails.length - 1].departureDate) ||
				null;
			returnFlightDate =
				typeof returnFlightDate === 'string'
					? returnFlightDate
					: returnFlightDate.toISOString();
			returnFlightDate = returnTrueDate(returnFlightDate);

			let departFlightDate =
				(flightDetails.length && flightDetails[0].departureDate) || null;
			departFlightDate =
				typeof departFlightDate === 'string'
					? departFlightDate
					: departFlightDate.toISOString();
			departFlightDate = returnTrueDate(departFlightDate);

			const error = 'Please add valid date';
			let startDateError = '';
			let endDateError = '';
			const startGreaterThanEnd =
				formattedStart && formattedEnd
					? formattedStart > formattedEnd && error
					: '';
			const greaterThanError = (currentDate, hasError, vars) => {
				if (hasError) return hasError;
				for (let item of vars) {
					hasError = item ? currentDate > item && error : hasError;
					if (hasError) {
						console.log('greater than break point');
						console.log('currentDate', currentDate);
						console.log('item', item);
						break;
					}
				}
				return hasError;
			};

			const lessThanError = (currentDate, hasError, vars) => {
				if (hasError) return hasError;
				for (let item of vars) {
					hasError = item ? currentDate < item && error : hasError;

					if (hasError) {
						console.log('less than break point');
						console.log('currentDate', currentDate);
						console.log('item', item);
						break;
					}
				}
				return hasError;
			};

			if (formattedStart) {
				startDateError = startGreaterThanEnd;
				startDateError = greaterThanError(formattedStart, startDateError, [
					isClosed && suggestedEnd,
					nextHotelStart,
					isClosed && returnFlightDate,
				]);
				startDateError = lessThanError(formattedStart, startDateError, [
					suggestedStart,
					prevHotelEnd,
					departFlightDate,
				]);
			}
			if (formattedEnd) {
				endDateError = startGreaterThanEnd;

				endDateError = greaterThanError(formattedEnd, endDateError, [
					isClosed && suggestedEnd,
					nextHotelStart,
					isClosed && returnFlightDate,
				]);
				endDateError = lessThanError(formattedEnd, endDateError, [
					suggestedStart,
					prevHotelEnd,
					departFlightDate,
				]);
			}

			setHotelError(index, 'startDate', startDateError);

			setHotelError(index, 'endDate', endDateError);
		}
	}, [
		currentCity,
		currentStart,
		currentEnd,
		suggestedEnd,
		suggestedStart,
		prevHotel,
		nextHotel,
	]);

	return (
		<motion.div
			key={index}
			style={{
				position: 'relative',
				zIndex: hotelDetails.length - index,
			}}
			initial={{ y: '-15rem', opacity: 0, height: 0 }}
			animate={{ y: '0%', opacity: 1, height: 'auto' }}
			exit={{ y: '-15rem', opacity: 0, height: 0, paddingTop: '0px' }}
			transition={{ height: 0.3, opacity: 0.1, y: 0.4, paddingTop: 0.3 }}
			className='hotel-input__main'>
			<div className='hotel-input__section'>
				<div className='local-trip__multi-input left'>
					<label>Location</label>
					<input
						// onBlur={handleReduxChange}
						type='text'
						value={tripInputs.city || ''}
						name='city'
						onChange={handleCitySearch}
						autoComplete='off'
					/>
					<AutofillSection
						returnSearchResults={returnSearchResults}
						searchResults={searchResults}
						heightRefs={heightRefs}
					/>
					{errors && errors.city ? <ErrMessage text={errors.city} /> : null}
				</div>

				<div className='local-trip__multi-input middle'>
					<label>Start Date</label>
					<input
						name='startDate'
						placeholder='yyyy-mm-dd'
						type='date'
						value={tripInputs.startDate}
						onChange={handleReduxChange}
						// onChange={handleFormChange}
					/>
					{errors && errors.startDate ? (
						<ErrMessage text={errors.startDate} />
					) : null}
				</div>
				<div className='local-trip__multi-input right'>
					<label>End Date </label>
					<input
						name='endDate'
						placeholder='yyyy-mm-dd'
						type='date'
						value={tripInputs.endDate}
						onChange={handleReduxChange}
						// onChange={handleFormChange}
					/>
					{errors && errors.endDate ? (
						<ErrMessage text={errors.endDate} />
					) : null}
				</div>

				<div className='local-trip__multi-input left'>
					<label>Distance To City Center</label>
					<select
						name='radius'
						value={JSON.stringify(tripInputs.radius)}
						onChange={handleReduxChange}>
						<option value='5'>Under 5 miles</option>
						<option value='10'>5 to 10 miles</option>
						<option value='null'>Greater than 10 miles</option>
					</select>
				</div>
				<div className='local-trip__multi-input middle'>
					<label>Price Range (optional)</label>
					<select
						name='priceRange'
						value={JSON.stringify(tripInputs.priceRange)}
						onChange={handleReduxChange}>
						<option value=''>----------</option>
						<option value='{"min":"0.00","max":"150.00"}'>{'<'} $150</option>
						<option value='{"min":"150.00","max":"250.00"}'>$150 - $250</option>
						<option value='{"min":"250.00","max":"0.00"}'>$250+</option>
					</select>
				</div>
				<div className='local-trip__multi-input right'>
					<label>Number Of Rooms</label>
					<select
						name='rooms'
						value={tripInputs.rooms}
						onChange={handleReduxChange}>
						<option value='1'>1</option>
						<option value='2'>2</option>
						<option value='3'>3</option>
					</select>
				</div>
			</div>
			<div className='button__container'>
				<button
					onClick={() => removeHotel(index)}
					className='newcss-plus-button'>
					-
				</button>
				<button onClick={handleAddHotel} className='newcss-plus-button'>
					+
				</button>
			</div>
		</motion.div>
	);
};

const mapStateToProps = (state) => ({
	multiDayTrips: state.trip.multiDayTrips,
	flightDetails: state.trip.flightDetails,
	hotelDetails: state.trip.hotelDetails,
	returnDate: state.trip.returnDate,
	idealLocation: state.preferences.idealLocation,
	lodgingPrice: state.preferences.lodgingPrice,
});

export default connect(mapStateToProps, {
	appendHotelDetail,
	removeMultiday,
	editHotel,
	addNewHotel,
	removeHotel,
	addHotelErrorObject,
	setHotelError,
	spliceNewItem,
})(MultiDayTripInputs);
