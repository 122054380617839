import React, { Fragment } from 'react';
const CartTitle = ({ text }) => {
	return (
		<Fragment>
			<h2 className='cart-title'>{text}</h2>
		</Fragment>
	);
};

export default CartTitle;
