import React, { useEffect, useState } from 'react';
import arrow from '../../images/arrow.svg';
import { Fragment } from 'react';
import moment from 'moment';
import { mergeSortedItems } from '../../Utilities/helperFunctions';
const airportData = require('../../Utilities/new_airport_codes.json');

const NewTripTitle = ({ returnDate, flights, pending, hotels, index }) => {
	const [results, setResults] = useState(null);
	const returnTrueDate = (dateString) => {
		const dateArray = dateString.split('-');
		const year = dateArray[0];
		const month = parseInt(dateArray[1], 10) - 1;
		const date = dateArray[2];
		return new Date(year, month, date);
	};
	const handleCityName = (iata) => {
		let airportLocation = airportData.find(
			(item) => item.iata === iata.toLowerCase()
		);
		airportLocation = airportLocation && airportLocation.locationServed;

		const splitString = airportLocation && airportLocation.split(', ');
		const cityName = splitString ? splitString[0] : '';
		const result =
			cityName &&
			cityName
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase();
		return result;
	};

	const handleDate = (items) => {
		if (items && items.length) {
			let result = '';

			const departureDateString =
				items[0] && items[0].departureDate
					? items[0].departureDate.slice(0, 10)
					: null;

			const startDateString =
				items[0] && items[0].startDate ? items[0].startDate.slice(0, 10) : null;
			let start = departureDateString || startDateString;

			let returnDateString = returnDate && returnDate.slice(0, 10);
			const itemDateString = items[items.length - 1].departureDate
				? items[items.length - 1].departureDate.slice(0, 10)
				: items[items.length - 1].startDate.slice(0, 10);
			let end = returnDateString || itemDateString;

			result =
				start &&
				end &&
				`${moment(returnTrueDate(start)).format('MMMM Do')} - ${moment(
					returnTrueDate(end)
				).format('MMMM Do')}`;

			return result;
		}
	};

	useEffect(() => {
		const flightMap = {};
		const sortedFlights = flights.sort((a, b) => {
			flightMap[a.destination] = true;
			flightMap[b.destination] = true;
			return new Date(a.departureDate) - new Date(b.departureDate);
		});
		const sortedHotels = hotels.sort((a, b) => {
			return new Date(a.startDate) - new Date(b.startDate);
		});

		const mergedItems = mergeSortedItems(sortedFlights, sortedHotels);

		let filteredMerged = mergedItems.map((item, mergedIndex) => {
			if (mergedIndex === 0) {
				return item;
			} else {
				const currentItemLocation =
					item && item.destination
						? handleCityName(item.destination)
						: handleCityName(item && item.city && item.city.cityCode);
				const prevItemLocation =
					mergedItems[mergedIndex - 1] &&
					mergedItems[mergedIndex - 1].destination
						? handleCityName(mergedItems[mergedIndex - 1].destination)
						: handleCityName(
								mergedItems[mergedIndex - 1] &&
									mergedItems[mergedIndex - 1].city &&
									mergedItems[mergedIndex - 1].city.cityCode
						  );

				if (currentItemLocation !== prevItemLocation) {
					return item;
				} else {
					return false;
				}
			}
		});
		filteredMerged = filteredMerged.filter((item) => item !== false);

		setResults(filteredMerged);
	}, [flights, hotels]);

	return (
		<div className='trip__title'>
			<h3>
				{results &&
					results.map((item, itemIndex) => {
						return itemIndex !== results.length - 1 ? (
							<Fragment key={`trip-title-${index}-${itemIndex}`}>
								<span className='capitalize'>
									{item.city
										? item.city.cityName.toLowerCase()
										: handleCityName(item.destination)}
								</span>
								{itemIndex === results.length - 2 &&
								results[0].departure ===
									(results[results.length - 1].destination ||
										results[results.length - 1].city.cityCode) ? null : (
									<img src={arrow} alt='' />
								)}
							</Fragment>
						) : (
							<>
								{results[0].departure !==
								(item.destination || item.city.cityCode) ? (
									<span
										className='capitalize'
										key={`trip-title-${index}-${itemIndex}`}>
										{item.city
											? item.city.cityName.toLowerCase()
											: handleCityName(item.destination)}
									</span>
								) : null}
							</>
						);
					})}
			</h3>
			<p>{handleDate(results)}</p>
			{pending ? (
				<button style={{ backgroundColor: 'grey', color: '#fff' }}>
					Pending
				</button>
			) : (
				<button>View Trip</button>
			)}
		</div>
	);
};

NewTripTitle.propTypes = {};

export default NewTripTitle;
