import React, { useEffect, useRef, useState } from 'react';
import FaqItem from './FaqItem';

const FaqMain = () => {
	const refs = useRef([]);
	const ref = useRef(null);
	const [mainHeight, setMainHeight] = useState('');
	const content = [
		{
			question: 'What does Rove do?',
			answer:
				'We create custom travel itineraries based on your preferences and travel requests!',
		},
		{
			question: 'What is the cost?',
			answer:
				'$150 broken up into two payments. The first is when we build out your itinerary options and the second is when you are ready to book.',
		},
		{
			question: 'What is included in your fee?',
			answer:
				'The service of pulling together an incredible adventure based on years of travel experience and research. See our ‘What You Get’ section for details across flights, hotels, activities and transportation. We also support the booking and communication of all trip related information.',
		},
		{
			question: 'How many places can I visit in a single trip?',
			answer:
				'Rove is set up to support 4 unique lodging locations (i.e. cities). This does not include your home city. For example, if you want to island hop in Greece, we would love to support providing lodging options in 4 different islands.',
		},
		{
			question: 'How many flights can I book?',
			answer:
				'Rove is set up to support providing up to 3 legs. This does not mean in and out of a city as you could opt to travel from one city to another without taking a flight.',
		},
		{
			question:
				'What kinds of lodging options do you offer (i.e. hotel, home rental, apartments)?',
			answer:
				'We only offer hotels at the moment. If you were passionate about home rental, we could offer options offline (as in these items will not be loaded to Rove’s website). Send us an email at support@travelwithrove.com to discuss more details.',
		},
		{
			question: 'What kinds of activities can I expect?',
			answer:
				'Mostly items that require you to have a ticket or prebook. Sometimes we might load guides such as the best beaches in the area or hiking trails. However, the vast majority will focus around tours, adventures, etc. The ad hoc or free items are best experienced at your leisure and allow for the magic of traveling to unfold real time during your trip.',
		},
		{
			question: 'What kinds of transportation can I expect?',
			answer:
				'You can expect private transportation to and from your hotel. We love providing this option as not having to worry about getting to your hotel can make starting and ending your travels easy and enjoyable. Taxis/ubers are always an excellent option as well, however we will not offer these up as they can be secured promptly upon your arrival.',
		},

		{
			question: 'What if I need help?',
			answer:
				'Feel free to contact us at support@travelwithrove.com. We will provide a contact number upon request.',
		},
		{
			question: 'Is this a person or a robot I’m dealing with?',
			answer:
				'You are dealing with an actual person! We look forward to working with you on planning your next dream trip!',
		},
		{
			question:
				'Can I have you only do part of my trip? For example, just activities or just hotels.',
			answer:
				'We would love to help in any way we can. It will still be the $150 fee broken up across two payments.',
		},
		{
			question: 'What kind of budget is typical?',
			answer:
				'What we typically hear from our clients is around $5,000 all in. This would typically be for a 7 day international trip for two people. This is a lot of money so we do our best to make it go as far as possible. We highly recommend booking earlier to secure cheaper airfare to allow more wiggle room with hotels and activities.',
		},
		{
			question: 'What if I need help building out the Trip Profile?',
			answer:
				'We are happy to help! Email us at support@travelwithrove.com and we would love to set up time to walk you through it or provide guidance.',
		},
		{
			question: 'What is the cancellation policy?',
			answer:
				'There is a $75 cancellation fee to support the cancellation of all bookings that were made. We do our best to get as much of your money back as possible.',
		},
		{
			question: 'What if I am not happy with the options provided?',
			answer:
				'We sincerely apologize. Our goal is to meet or exceed expectations related to pulling together the perfect options for your upcoming trip. If we have failed in any way, please send a note to support@travelwithrove.com and we would be happy to try again. See our “How many iterations are included” question for more details.',
		},
		{
			question: 'How many iterations are included?',
			answer:
				'One iteration is included in the fee. An iteration could be you were unhappy with the first round or there were additional options you may want to specifically see. If more options of flights, hotels, activities or transportations are desired, feel free to reach us at support@travelwithrove.com. If after we provide the updates or iterate on the options provided, and you still feel like more changes need to be made then a $75 fee will be charged. We typically only see one iteration needed so this would be a rare case.',
		},
		{
			question: 'What if I need to change my travel plans?',
			answer:
				'This could mean the dates or the destination. We are happy to help change your travel plans! If it is the dates you are looking to change, we can make that change for you for free. This would count towards your one iteration or change that is included in the original fee. If it is a destination change, we will need to charge another $75 given it is a whole new location. Email us on these changes and we will help you navigate the process.',
		},
	];

	useEffect(() => {
		if (refs.current.length) {
			let calculatedHeight = 0;
			let rems = 0;

			for (let i = 0; i < 6; i++) {
				console.log('refs.current[i]', refs.current[i]);
				calculatedHeight = calculatedHeight + refs.current[i].clientHeight;
				rems += 2.5;
			}
			console.log('calculatedHeight', calculatedHeight);
			console.log('rems', rems);
			setMainHeight(`calc(${calculatedHeight}px + ${rems}rem)`);
		}
	}, [refs]);
	return (
		<main
			ref={(el) => (ref.current = el)}
			// style={{ height: mainHeight || 'auto' }}
			className='faq__main'>
			{content.map((item, index) => {
				return (
					<FaqItem
						key={'faq-item-' + index}
						refs={refs}
						index={index}
						item={item}
					/>
				);
			})}
		</main>
	);
};

export default FaqMain;
