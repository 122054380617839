import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectModalItem } from '../../redux/actions/itineraryModal';
import PropTypes from 'prop-types';
import HotelOfferContent from './HotelOfferContent';
import moment from 'moment';
import { returnItemFields } from '../../Utilities/helperFunctions';
import { handleImage } from '../../redux/reducers/helperFunctions';

const SmallHotelOffer = ({ hotel, selectModalItem, selectedItem }) => {
	const { RoomRates, HotelCode, descriptive_info, HotelName } =
		returnItemFields(hotel.data, [
			'HotelCode',
			'RoomRates',
			'descriptive_info',
			'HotelName',
		]);

	const { Total } = returnItemFields(RoomRates, ['Total']);
	const { HotelInfo } = returnItemFields(descriptive_info, ['HotelInfo']);
	const { Descriptions } = returnItemFields(HotelInfo, ['Descriptions']);
	const { MultimediaDescription } = returnItemFields(Descriptions, [
		'MultimediaDescription',
	]);
	const { AmountAfterTax, converted_total } = returnItemFields(Total, [
		'AmountAfterTax',
		'converted_total',
	]);

	let name = HotelName && HotelName;

	const { Rates, info } =
		RoomRates &&
		RoomRates.length &&
		RoomRates[0].RoomRate &&
		RoomRates[0].RoomRate.length &&
		RoomRates[0].RoomRate[0];
	const { Rate } = Rates && Rates.length && Rates[0];
	const rooms = info && info['NumberOfUnits'];

	const startDate = moment(new Date(Rate[0]['info'].EffectiveDate));
	const endDate = moment(
		new Date(
			Rate[Rate.length - 1]['Total']
				? Rate[Rate.length - 2]['info'].ExpireDate
				: Rate[Rate.length - 1]['info'].ExpireDate
		)
	);
	const timeLength = endDate.diff(startDate, 'days');

	// const { Info } = hotel.data.hotel_info;

	return (
		<>
			<HotelOfferContent
				selectedItem={selectedItem}
				hotel={hotel}
				image={handleImage(MultimediaDescription, 250, 300)}
				total={converted_total ? converted_total : parseInt(AmountAfterTax)}
				timeLength={timeLength}
				rooms={rooms}
				selectModalItem={selectModalItem}
				name={name}
			/>
		</>
	);
};

SmallHotelOffer.propTypes = {
	selectLodgingItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	selectedItem: state.itineraryModal.selectedItem,
});

export default connect(mapStateToProps, { selectModalItem })(SmallHotelOffer);
