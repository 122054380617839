import React from 'react';

const Guarantee = ({ guaranteeType, guaranteesAccepted }) => {
	// const cardImageMap = {
	// 	AX: (
	// 		<img
	// 			alt='Credit Card Logos'
	// 			title='Credit Card Logos'
	// 			src='http://www.credit-card-logos.com/images/american_express_credit-card-logos/american_express_logo_9.gif'
	// 			width='60'
	// 			height='38'
	// 			border='0'
	// 		/>
	// 	),
	// 	VI: (
	// 		<img
	// 			alt='Credit Card Logos'
	// 			title='Credit Card Logos'
	// 			src='http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_logo_3.gif'
	// 			width='35'
	// 			height='22'
	// 			border='0'
	// 		/>
	// 	),
	// 	CA: null,
	// 	DC: (
	// 		<img
	// 			alt='Credit Card Logos'
	// 			title='Credit Card Logos'
	// 			src='http://www.credit-card-logos.com/images/discover_credit-card-logos/discover_network1.jpg'
	// 			border='0'
	// 		/>
	// 	),
	// 	CB: null,
	// 	JC: null,
	// 	DS: null,
	// 	SO: null,
	// 	CU: null,
	// 	TP: null,
	// 	VE: null,
	// 	MA: null,
	// 	JC: null,
	// 	EC: null,
	// 	DN: null,
	// 	BL: null,
	// 	BC: null,
	// 	MC: (
	// 		<img
	// 			alt='Credit Card Logos'
	// 			title='Credit Card Logos'
	// 			src='http://www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_7.jpg'
	// 			width='60'
	// 			height='38'
	// 			border='0'
	// 		/>
	// 	),
	// };

	return (
		<div className='guarantee'>
			<span>Payment Details:</span>
			<span>
				-{' '}
				{guaranteeType === 'Guarantee Required'
					? 'Guarantee Required'
					: 'Deposit Needed'}
			</span>
		</div>
	);
};

export default Guarantee;
