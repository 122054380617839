import React from 'react';

const AirportAutofill = ({
	name,
	iataCode,
	onClick,
	stateCode,
	heightRefs,
	index,
}) => {
	return (
		<div
			ref={(el) => (index < 5 ? (heightRefs.current[index] = el) : null)}
			className='autofill-option'
			onClick={onClick}>
			<p className='autofill-airport-name capitalize'>
				{iataCode} - {name}
				{/* {stateCode && `, ${stateCode}`} */}
			</p>
		</div>
	);
};

export default AirportAutofill;
