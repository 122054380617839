import React from 'react';
import TotalSection from './TotalSection';
import ReactGA from 'react-ga';

const CurrentTotal = ({
	text,
	headerText,
	curation,
	purchase,
	hoverItem,
	iframeSubmitSuccess,
	totalPer,
	bookingInfoList,
	mobileCurrent,
	mobileCurrentValue,
	isMobile,
	total,
	setAgreementOpen,
}) => {
	return isMobile && mobileCurrent !== mobileCurrentValue ? null : (
		<section className={`cart-total__container`}>
			<h2>{headerText}</h2>
			<section className={`cart-total`}>
				<TotalSection
					text={text}
					currencyCode='USD'
					price={total}
					cname='big'
					hoverItem={hoverItem}
				/>
				{!curation && (
					<>
						{!purchase.booking ||
						(purchase.booking &&
							!purchase.booking.authorizedOn &&
							!purchase.booking.paidOn) ? (
							<>
								<TotalSection
									text='Booking Fee'
									currencyCode='USD'
									price='75'
									cname='big'
									hoverItem={bookingInfoList || null}
								/>
							</>
						) : null}
						<TotalSection
							text='Price per person(avg.)'
							currencyCode='USD'
							price={totalPer || 0}
							cname='big'
						/>
					</>
				)} 
				<button
					className={`${iframeSubmitSuccess ? '' : 'disabled'}`}
					disabled={!iframeSubmitSuccess}
					onClick={() => {
						if (iframeSubmitSuccess) {
							if (!curation) {
								const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
								ReactGA.initialize(TRACKING_ID);
								ReactGA.event({category: "User_Action", action: "checkout_pay_booking", label: "checkout_trip_page"});	
							}

							setAgreementOpen(true);
						}
					}}>
					{'Authorize Payment'}
				</button>
			</section>
		</section>
	);
};

export default CurrentTotal;
