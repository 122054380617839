import uuid from 'react-uuid';
import { get, post, put, submitItineraryUpdate } from '../../Utilities/api';
import { addTrip, loadAuthUser } from './auth';
import { addDayTripErrorObject } from './errors';
import ReactGA from 'react-ga';
import { setUserProfile } from './profile';

export const setTrip = (data) => (dispatch) => {
	dispatch({
		type: 'SET_TRIP',
		payload: data,
	});
};

export const addCustomEvent = (event) => (dispatch) => {
	dispatch({
		type: 'ADD_CUSTOM_EVENT',
		payload: event,
	});
};

export const queryAttachments = (trip_id) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/data/private/trip/get-documents',
			{ trip_id },
			authRequired
		);

		dispatch({
			type: 'QUERY_ATTACHMENTS',
			payload: res.data.trip_files,
		});
		// setAttachments(res.data.trip_files);
	} catch (err) {
		console.log(err.message);
	}
};

export const setSupport = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_SUPPORT',
		payload,
	});
};

export const removeCustomEvent = (index) => (dispatch) => {
	dispatch({
		type: 'REMOVE_CUSTOM_EVENT',
		payload: index,
	});
};

export const getTrip = (id) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get(
			`/api/v1/data/private/trip/get-trip/${id}`,
			{},
			authRequired
		);

		dispatch(setTrip(res.data.trip));
		return res.data.trip;
		// dispatch(setUserProfile(res.data.trip.user));
	} catch (err) {
		console.log(err);
	}
};

export const moveItemTo =
	({ item, currentGroup, nextGroup, itemKey }) =>
	(dispatch) => {
		// if nextGroup === 'cart' then item is being moved to cart.

		/* const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
		ReactGA.initialize(TRACKING_ID);
		ReactGA.event({category: "User_Events", action: "add_to_cart", label: "add_item_to_cart"});	 */

		console.log(
			'moving item, currentGroup, nextGroup, itemKey',
			item,
			currentGroup,
			nextGroup,
			itemKey
		);

		dispatch({
			type: `MOVE_ITEM_TO`,
			payload: { item, currentGroup, nextGroup, itemKey },
		});
	};
export const removeItemFrom =
	({ item, currentGroup, itemKey }) =>
	(dispatch) => {
		dispatch({
			type: `REMOVE_ITEM_FROM`,
			payload: { item, currentGroup, itemKey },
		});
	};

export const addItemTo =
	({ item, group, itemKey }) =>
	(dispatch) => {
		const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
		ReactGA.initialize(TRACKING_ID);
		ReactGA.event({
			category: 'User_Events',
			action: 'add_to_cart',
			label: 'add_item_to_cart',
		});

		dispatch({
			type: `ADD_ITEM_TO`,
			payload: { item, group, itemKey },
		});
	};

export const updateCartItem = (item, key) => (dispatch) => {
	dispatch({ type: 'UPDATE_CART_ITEM', payload: { item, key } });
};

export const submitUpdate = (trip, clear) => async (dispatch) => {
	try {
		const res = await submitItineraryUpdate(trip);
		if (res.status === 200) {
			if (clear) {
				dispatch({
					type: 'CLEAR_TRIP',
				});
			} else {
				dispatch({
					type: 'SET_TRIP',
					payload: res.data.trip,
				});
			}
			return true;
		}
	} catch (err) {
		console.log(err);
		dispatch(setLoading(false));
		return false;
	}
};

export const selectModalItem = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_MODAL_ITEM',
		payload: item,
	});
};

// Trip profile form actions

export const removeDayTrip = (index) => (dispatch) => {
	dispatch({
		type: 'REMOVE_DAYTRIP',
		payload: index,
	});
};

export const editDayTrip =
	({ index, key, value }) =>
	(dispatch) => {
		dispatch({
			type: 'EDIT_DAYTRIP',
			payload: { index, key, value },
		});
	};
export const addEmptyDaytrip = () => (dispatch) => {
	const payload = {
		id: uuid(),
		city: '',
	};
	const errorPayload = { city: '', date: '' };
	dispatch(addDayTripErrorObject(errorPayload));
	dispatch({
		type: 'ADD_EMPTY_DAYTRIP',
		payload,
	});
};

export const filterEmptyItems = () => (dispatch) => {
	dispatch({
		type: 'FILTER_EMPTY_ITEMS',
	});
};

export const liveHotelSearch = (trip_id) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/get_top_10_available',
			{ trip_id },
			authRequired
		);
		if (res.data.success) {
			const descriptiveMap = {};
			res.data.descriptive_info.forEach((item) => {
				descriptiveMap[item['info']['HotelCode']] = item;
			});
			dispatch(setTrip(res.data.trip));
			dispatch({
				type: 'HOTEL_DESCRIPTIVE_INFO',
				payload: descriptiveMap,
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const updateParty = (body) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			'/api/v1/data/private/trip/update-party',
			body,
			authRequired
		);
		dispatch({ type: 'GET_TRIP', payload: res.data.trip });
		dispatch(loadAuthUser(res.data.user));
		return true;
	} catch (err) {
		console.log(err.message);
		return false;
	}
};

export const addToCart = (_id, key) => (dispatch) => {
	dispatch({
		type: 'ADD_TO_CART',
		payload: { _id, key },
	});
};

// export const addCartByObject = (item, key) => (dispatch) => {
// 	dispatch({
// 		type: 'ADD_CART_BY_OBJECT',
// 		payload: { item, key },
// 	});
// };

export const removeFromCart = (_id, key) => (dispatch) => {
	dispatch({
		type: 'REMOVE_FROM_CART',
		payload: { _id, key },
	});
};

// CHECK BELOW TO SEE IF THEY ARE USEFUL

export const selectTransportItem = (id, key) => (dispatch) => {
	dispatch({
		type: 'SELECT_ITEM',
		payload: { id, key },
	});
};

export const selectLodgingItem = (id, key) => (dispatch) => {
	dispatch({
		type: 'SELECT_ITEM',
		payload: { id, key },
	});
};

export const selectFlightItem = (id, key) => (dispatch) => {
	dispatch({
		type: 'SELECT_ITINERARY_ITEM',
		payload: { id, key },
	});
};

export const setTripType = (type) => (dispatch) => {
	dispatch({
		type: 'SET_TRIP_TYPE',
		payload: type,
	});
};

export const increment = (range, amount) => (dispatch) => {
	amount++;
	dispatch({
		type: 'INCREMENT',
		payload: {
			range,
			amount,
		},
	});
};

export const addActivity = (activity) => (dispatch) => {
	dispatch({
		type: 'ADD_ACTIVITY_THEME',
		payload: activity,
	});
};

export const removeActivity = (activity) => (dispatch) => {
	dispatch({
		type: 'REMOVE_ACTIVITY_THEME',
		payload: activity,
	});
};

export const decrement = (range, amount) => (dispatch) => {
	const childOrInfant = range === 'children' || range === 'infants';
	const adultGreaterThanOne = range === 'adults' && amount > 1;
	if (amount > 0 && childOrInfant) {
		amount--;
	}
	if (adultGreaterThanOne) {
		amount--;
	}
	dispatch({
		type: 'DECREMENT',
		payload: {
			range,
			amount,
		},
	});
};

export const handleText = (name, text) => (dispatch) => {
	dispatch({
		type: 'HANDLE_TEXT',
		payload: {
			name,
			text,
		},
	});
};

export const appendHotelDetail =
	(radius, city, startDate, endDate, id = uuid()) =>
	(dispatch) => {
		const valid = radius && city && startDate && endDate && id;
		if (valid) {
			dispatch({
				type: 'APPEND_MULTI_DAY',
				payload: {
					id,
					city,
					startDate,
					endDate,
				},
			});
		}
	};

export const removeMultiday = (id) => (dispatch) => {
	dispatch({
		type: 'REMOVE_MULTIDAY',
		payload: id,
	});
};

export const setFlightType = (type) => (dispatch) => {
	dispatch({
		type: 'SET_FLIGHT_TYPE',
		payload: type,
	});
};

export const addDate = (property, date) => (dispatch) => {
	if (date) {
		dispatch({
			type: 'ADD_DATE',
			payload: {
				property,
				date,
			},
		});
	}
};

export const addDayTrip =
	(trip, id = uuid()) =>
	(dispatch) => {
		const { city, date } = trip;
		dispatch({
			type: 'ADD_DAY_TRIP',
			payload: {
				id,
				city,
				date,
			},
		});
		return true;
	};

// export const removeDayTrip = (id) => (dispatch) => {
// 	dispatch({
// 		type: 'REMOVE_DAY_TRIP',
// 		payload: id,
// 	});
// };

export const setLoading = (boolean) => (dispatch) => {
	dispatch({
		type: 'SET_LOADING',
		payload: boolean,
	});
};

export const addFlight = (data, type) => (dispatch) => {
	dispatch({
		type: 'ADD_FLIGHT_DETAIL',
		payload: data,
	});
};

export const removeFlight = (index) => (dispatch) => {
	dispatch(removeHotelByFlightIndex({ [index]: true }));
	dispatch({
		type: 'REMOVE_FLIGHT',
		payload: index,
	});
};

export const updateCustom = (item) => (dispatch) => {
	dispatch({
		type: 'UPDATE_CUSTOM',
		payload: item,
	});
};

export const removeCustom = (index) => (dispatch) => {
	dispatch({
		type: 'REMOVE_CUSTOM',
		payload: index,
	});
};

export const saveItinerary = () => (dispatch) => {
	dispatch({
		type: 'SAVE_ITINERARY',
	});
};
export const editFlightDetail = (index, key, value) => async (dispatch) => {
	try {
		const res = await dispatch({
			type: 'EDIT_FLIGHT_DETAIL',
			payload: { index, key, value },
		});
		if (res) return true;
	} catch (error) {
		console.log(error);
	}
};

export const removeAllFlights = () => (dispatch) => {
	dispatch({
		type: 'REMOVE_ALL_FLIGHTS',
	});
};

export const updateHotelDetails = (details) => (dispatch) => {
	dispatch({
		type: 'UPDATE_HOTEL_DETAILS',
		payload: details,
	});
};

export const updateFlightDetails = (details) => (dispatch) => {
	dispatch({
		type: 'UPDATE_FLIGHT_DETAILS',
		payload: details,
	});
};

export const addSuggestedHotel = (hotel) => async (dispatch) => {
	try {
		const res = await dispatch({
			type: 'ADD_NEW_HOTEL',
			payload: hotel,
		});
	} catch (error) {
		console.log(error);
	}
};

export const editSuggestedHotel = (hotel) => async (dispatch) => {
	try {
		const res = await dispatch({
			type: 'EDIT_SUGGESTED_HOTEL_INFO',
			payload: { hotel },
		});
		if (res) return true;
	} catch (error) {
		console.log(error);
	}
};

export const spliceNewItem = (index, item, key) => (dispatch) => {
	dispatch({
		type: 'SPLICE_NEW_ITEM',
		payload: { index, item, key },
	});
};

export const editHotel = (index, key, value) => async (dispatch) => {
	await dispatch({
		type: 'EDIT_HOTEL',
		payload: { index, key, value },
	});
};

export const addNewHotel = (hotel) => async (dispatch) => {
	await dispatch({
		type: 'ADD_NEW_HOTEL',
		payload: hotel,
	});
};

export const removeHotelByFlightIndex = (indeces) => (dispatch) => {
	dispatch({ type: 'REMOVE_HOTEL_BY_FLIGHT_INDEX', payload: indeces });
};
export const removeHotel = (index) => (dispatch) => {
	dispatch({ type: 'REMOVE_HOTEL', payload: index });
};

export const addEmptyFlight =
	(departure = '', destination = '', departureDate = null) =>
	async (dispatch) => {
		if (!departureDate) {
			departureDate = new Date();
			departureDate.setDate(departureDate.getDate() + 1);
		}
		await dispatch({
			type: 'ADD_EMPTY_FLIGHT',
			payload: { departure, destination, departureDate },
		});
	};

export const setItemUnits = (amount, id, key) => (dispatch) => {
	dispatch({
		type: 'SET_ITEM_UNITS',
		payload: { id, amount, key },
	});
};

// export const setCustomStartEnd = (start, end, id, key) => (dispatch) => {
// 	dispatch({
// 		type: 'SET_CUSTOM_START_END',
// 		payload: { start, end, id, key },
// 	});
// };

export const clearTrip = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_TRIP',
	});
};

export const setTripError = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_ERROR',
		payload,
	});
};

export const createNewTrip = () => async (dispatch) => {
	const authRequired = true;
	const res = await post(
		'/api/v1/data/private/trip/create',
		{},
		authRequired
	).catch((err) => {
		throw { message: 'Could Not Create Empty Trip' };
	});
	dispatch(addTrip(res.data.trip));
	dispatch(setTrip(res.data.trip));
};

export const removeAttachment = (index) => (dispatch) => {
	dispatch({
		type: 'REMOVE_ATTACHMENT',
		payload: { index },
	});
};

export const updateAttachments = (trip_id, attachments) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/data/private/trip/update-documents',
			{ trip_id, attachments },
			authRequired
		);
		dispatch({
			type: 'UPDATE_ATTACHMENTS',
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
