import React, { Fragment, useEffect, useState } from 'react';
import SingleOptionPreference from './SingleOptionPreference';
import config from '../../config/config';
import { AnimatePresence } from 'framer-motion';

const LodgingPreferences = ({
	previousComponent,
	nextComponent,
	edit,
	changePreferenceIndex,
	index,
	handleDirection,
	direction,
}) => {
	const windows = [
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='lodgingPrice'
			title='What is your preferred lodging price point?'
			back={previousComponent}
			next={() => handleDirection(1)}
			children={config.LODGINGPREFERENCES.lodgingPrice.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='idealLocation'
			title='What is your ideal lodging location?'
			back={() => handleDirection(-1)}
			next={() => handleDirection(1)}
			children={config.LODGINGPREFERENCES.idealLocation.children}
		/>,
		<SingleOptionPreference
			edit={edit}
			direction={direction}
			name='lodgingType'
			title='What is your ideal type of lodging?'
			back={() => handleDirection(-1)}
			next={nextComponent}
			children={config.LODGINGPREFERENCES.lodgingType.children}
		/>,
	];

	return (
		<Fragment>
			{' '}
			<AnimatePresence>
				{windows.map((window, windowIndex) => {
					if (windowIndex === index || edit) return window;
					else return null;
				})}
			</AnimatePresence>
		</Fragment>
	);
};

LodgingPreferences.propTypes = {};

export default LodgingPreferences;
