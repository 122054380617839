import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import CreateAccountInputs from './CreateAccountInputs';
import AuthFooter from './AuthFooter';
import authFormHOC from '../hoc/AuthForm';
import { registerUser } from '../../redux/actions/auth';
import { connect } from 'react-redux';
import LoadingOverlay from '../Layout/LoadingOverlay';
import ReactGA from 'react-ga';
import { setRedirect } from '../../redux/actions/redirect';

const CreateAccount = ({
	handleChange,
	handlePasswordStatus,
	handleSamePassword,
	returnError,
	formData,
	setFormData,
	setCurrentForm,
	setErrorFields,
	canBeSubmitted,
	currentForm,
	registerUser,
	setRedirect,
	close,
}) => {
	// const message =
	// 	'Thanks for signing up! Please check your email to be redirected to your account.';
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [message, setMessage] = useState('');
	const registerInputs = {
		type: 'register',
		firstName: '',
		lastName: '',
		email: '',
		password1: '',
		password2: '',
		newsletter: true,
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (canBeSubmitted()) {
			setSubmitted(true);
			setLoading(true);
			const submit = await registerUser({
				email: formData.email,
				password: formData.password1,
				firstName: formData.firstName,
				lastName: formData.lastName,
				newsletter: formData.newsletter,
			});
			// setMessage(submit.message);

			if (submit.success) {
				const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
				ReactGA.initialize(TRACKING_ID);
				ReactGA.event({
					category: 'User_Action',
					action: 'sign_up',
					label: 'home_page',
				});
				setRedirect('/preferences');
			} else {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		setErrorFields(registerInputs);
		setFormData(registerInputs);
	}, []);

	// useEffect(() => {
	// 	if (success) {
	// 		setTimeout(() => {
	// 			close();
	// 		}, 2000);
	// 	}
	// }, [success]);

	return (
		<div className='auth-form__main'>
			<form className='auth-container register-form' onSubmit={handleSubmit}>
				{submitted ? (
					<LoadingOverlay
						close={close}
						loading={loading}
						success={success}
						message={message}
					/>
				) : null}
				<div className='auth-form'>
					{formData && (
						<CreateAccountInputs
							newsletter={formData.newsletter}
							handleChange={handleChange}
							returnError={returnError}
							handlePasswordStatus={handlePasswordStatus}
							handleSamePassword={handleSamePassword}
							password1={formData.password1}
							password2={formData.password2}
						/>
					)}

					<AuthFooter
						currentForm={currentForm}
						setCurrentForm={setCurrentForm}
						altForm='login'
					/>
				</div>
			</form>
		</div>
	);
};

export default connect(null, { registerUser, setRedirect })(
	authFormHOC(CreateAccount)
);
