import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import FaqMain from './FaqMain';
import buttonIcon from '../../images/noun-faq.svg';

const FaqModal = () => {
	const [open, setOpen] = useState(false);
	return (
		<Popup
			open={open}
			trigger={
				<button
					className='homepage__subnav-button'
					onClick={() => setOpen(true)}>
					{' '}
					FAQ
					<img src={buttonIcon} alt='' />
				</button>
			}
			modal>
			{(close) => (
				<div className='popup wyg-popup faq-popup' id='popup'>
					<div className='popup__content'>
						<button className='popup__close' onClick={close}>
							<i className='fa fa-times'></i>
						</button>
						<div className='popup__header'>
							<h3 className='popup__header--text'>FAQ</h3>
						</div>
						<FaqMain />
					</div>
				</div>
			)}
		</Popup>
	);
};

export default FaqModal;
