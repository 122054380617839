import React from 'react';

import { ImPlus } from 'react-icons/im';
import { NavLink } from 'react-router-dom';
import config from '../../config/config';
import WhatYouGet from '../landing/WhatYouGet';

const WYGCard = () => {
	return (
		<>
			<WhatYouGet />
		</>
	);
};

export default WYGCard;
