export const authorizeMapNested = (nestedObject) => {
	return {
		preferences: nestedObject.preferences,
		itineraryItems: nestedObject.itineraryItems.map((item) => {
			if (item.inCart) {
				return {
					...item,
					authorized: true,
				};
			}
			return item;
		}),
	};
};

export const authorizeMapUnnested = (itemArray) => {
	return itemArray.map((item) => {
		if (item.inCart) {
			return {
				...item,
				authorized: true,
			};
		}
		return item;
	});
};

export const handleImage = (media, min, max) => {
	const imageMap = {};
	// find the array of image uris of variable sizing
	const imageURIs =
		media && media.length
			? media.find(
					(item) =>
						item['ImageItems'] &&
						(item.info['InfoCode'] === '1' ||
							item.info['AdditionalDetailCode'] === '17')
			  )
			: null;

	// Store url in image map object with the width of the image as the field

	imageURIs &&
		imageURIs['ImageItems'] &&
		imageURIs['ImageItems'].length &&
		imageURIs['ImageItems'][0]['ImageItem'] &&
		imageURIs['ImageItems'][0]['ImageItem'].length &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'] &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].length &&
		imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].forEach(
			(item) => {
				const width = item.info['Width'];
				if (item.info['Format'] === '5') {
					imageMap[width] = item['URL'][0];
				}
			}
		);

	const imageMapKeys = Object.keys(imageMap).sort(
		(a, b) => parseInt(a) < parseInt(b)
	);

	const recursiveImageFind = (currentMin, currentMax) => {
		const foundKey = imageMapKeys.find((key) => {
			const numKey = parseInt(key);
			return numKey >= currentMin && numKey <= currentMax;
		});
		if (foundKey) {
			const imageURL = imageMap[foundKey + ''];

			return imageURL;
		} else if (currentMin === 0) {
			return null;
		} else {
			return recursiveImageFind(currentMin - 5, currentMax - 5);
		}
	};

	let image = recursiveImageFind(min, max);

	// 	imageURIs &&
	// 	imageURIs['ImageItems'] &&
	// 	imageURIs['ImageItems'].length &&
	// 	imageURIs['ImageItems'][0]['ImageItem'] &&
	// 	imageURIs['ImageItems'][0]['ImageItem'].length &&
	// 	imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'] &&
	// 	imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].length &&
	// 	imageURIs['ImageItems'][0]['ImageItem'][0]['ImageFormat'].find(
	// 		(item) =>
	// 			parseInt(item.info['Width']) <= max &&
	// 			parseInt(item.info['Width']) >= min &&
	// 			item.info['Format'] === '5'
	// 	);
	// image = image && image['URL'][0];
	return image;
};

export const removeItemById = (array, id) => {
	return array.filter((item) => item.id !== id);
};

export const editArrayItem = (array, id, newItem) => {
	return array.map((item) => {
		if (item.id === id) {
			return newItem;
		}
		return item;
	});
};

export const findOneItem = (array, _id) => {
	return array.find((item) => item._id === _id);
};

export const setRemovedItem = (item) => {
	return {
		...item,
		inCart: false,
		selected: false,
	};
};

export const setAddedItem = (item) => {
	return {
		...item,
		inCart: true,
		selected: true,
	};
};

export const removeCartHelper = (item) => {
	return {
		...item,
		inCart: false,
		selected: true,
	};
};

export const filterStatusFields = (trip, key, id, status) => {
	const filterStatusField = (field) => {
		return {
			...field,
			[key]: field[key].filter((item) => item._id !== id),
		};
	};
	const { itineraryItems, selected, paid, authorized } = trip;

	return {
		itineraryItems: filterStatusField(itineraryItems),
		selected: filterStatusField(selected),
		paid: filterStatusField(paid),
		authorized: filterStatusField(authorized),
	};
};
