import React from 'react';

const RoomRateDescription = ({ selectedItem }) => {
	const roomRate = selectedItem.data['RoomRates'][0]['RoomRate'][0];
	const roomRateDescription =
		roomRate &&
		roomRate['RoomRateDescription'] &&
		roomRate['RoomRateDescription'].length
			? roomRate['RoomRateDescription'][0]
			: null;
	const descriptionText =
		roomRateDescription && roomRateDescription['Text']
			? roomRateDescription['Text']
			: [];

	return (
		<section className='room-description'>
			<span>Room Description:</span>
			{descriptionText.length &&
				descriptionText.map((item, index) => (
					<span key={`selected-item-description-${index}`}>- {item._}</span>
				))}
		</section>
	);
};

RoomRateDescription.propTypes = {};

export default RoomRateDescription;
