import React from 'react';
import { useState } from 'react';
import LoadingOverlay from './Layout/LoadingOverlay';
import { useEffect } from 'react';
import { setRedirect } from '../redux/actions/redirect';
import redirectHOC from './hoc/RedirectHOC';
import { connect } from 'react-redux';
import { supportEmail } from '../redux/actions/auth';

const Contact = ({ setRedirect, supportEmail }) => {
	const [formData, setFormData] = useState({
		subject: '',
		message: '',
	});

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { message, subject } = formData;
		setSubmitted(true);
		setLoading(true);
		try {
			const res = await supportEmail({ message, subject });

			if (res.data.success) {
				setSuccess(true);
				setLoading(false);
			}
		} catch (err) {
			setSuccess(false);
			setLoading(false);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setRedirect('/itinerary');
			}, 1000);
		}
	}, [success]);

	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Contact Rove</h1>
			<form onSubmit={handleSubmit} className='form'>
				{submitted ? (
					<LoadingOverlay loading={loading} success={success} />
				) : null}
				<div>
					<label htmlFor='subject'>Subject</label>
					<input
						type='text'
						name='subject'
						onChange={handleChange}
						className='form-control'
						value={formData.subject}
					/>
				</div>
				<div>
					<label htmlFor='message'>Message</label>

					<textarea
						name='message'
						id='message'
						rows='10'
						onChange={handleChange}
						className='form-control'
						value={formData.message}
					/>
				</div>
				<div>
					<button type='submit' className='newcss-cta-button'>
						Give Feedback
					</button>
				</div>
			</form>
		</div>
	);
};

export default connect(null, { setRedirect, supportEmail })(
	redirectHOC(Contact)
);
