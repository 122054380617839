import React, { Fragment } from 'react';

const WhyList = () => {
	return (
		<div>
			<h4 className='why-section__list-subheader'>WHY GO WITH ROVE?</h4>
			<h3 className='why-section__list-header'>
				Visualize and create your dream trip with our fun interactive website
			</h3>
			<dl className='why-section__list'>
				<dt>✓</dt>
				<dd>Individualized trip based on your travel preferences</dd>
				<dt>✓</dt>
				<dd>
					Eliminates the hassle of researching, booking and stitching an entire
					trip together
				</dd>
				<dt>✓</dt>
				<dd>Streamlined options makes choosing easier</dd>
				<dt>✓</dt>
				<dd>Simplified single source payment</dd>
				<dt>✓</dt>
				<dd>Unbiased and non-sponsored personalized options'</dd>
			</dl>
		</div>
	);
};

export default WhyList;
