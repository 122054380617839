import React from 'react';
import CartButton from './CartButton';
import plane from '../../images/noun_Flight_black_3406429.svg';
import whitePlane from '../../images/noun_Flight_white_3406429.svg';
import {
	moveItemTo,
	removeItemFrom,
	selectModalItem,
} from '../../redux/actions/trip';

import { connect } from 'react-redux';
import { Fragment } from 'react';
import moment from 'moment';

import {
	returnKey,
	isMultiDay,
	useCheckType,
	useCheckItemStatus,
} from '../../Utilities/helperFunctions';
const EventContent = ({
	event,
	selectModalItem,
	trip,
	moveItemTo,
	removeItemFrom,
}) => {
	const { activity, flight, lodging, transportation, custom, amadeusCars } =
		useCheckType(event.uneditedItem.type || event.uneditedItem.data.type);
	const { itemPaid, itemAuthorized, itemSelected, itemInCart } =
		useCheckItemStatus(trip, event.uneditedItem);

	const currentGroup = itemPaid || itemAuthorized || itemSelected || itemInCart;

	const itemKey = returnKey({
		activity,
		flight,
		lodging,
		transportation,
		custom,
		amadeusCars,
	});

	const multiday = isMultiDay(event.start, event.end);

	const handleRemoveItem = () => {
		let cleanedEvent = event.uneditedItem;

		if (activity || transportation) {
			cleanedEvent = {
				...cleanedEvent,
				start: null,
				end: null,
			};
		}
		removeItemFrom({ item: cleanedEvent, itemKey, currentGroup });
	};

	const handleClick = () => {
		const nextGroup = itemSelected ? 'cart' : 'selected';
		moveItemTo({ item: event.uneditedItem, itemKey, currentGroup, nextGroup });
	};

	const handleCartButtonShow = () => {
		if (!event.cityRange) {
			let type = itemKey;

			return (
				<Fragment>
					<CartButton
						handleRemoveItem={handleRemoveItem}
						selected={itemSelected}
						inCart={event.inCart}
						multiDay={isMultiDay}
						setButtonAction={handleClick}
						_id={event.uneditedItem._id}
						type={type}
						paid={itemPaid || itemAuthorized}
					/>
				</Fragment>
			);
		}
	};

	const handleMultidayText = (timeFormat) => {
		const start = moment(event.start).format(timeFormat);
		const end = moment(event.end).format(timeFormat);
		return `${start} - ${end}`;
	};

	const handleAllCapsName = () => {
		const results = event.name.split(' ');
		return flight
			? results.map((string, index) => {
					if (string === 'to') {
						return (
							<Fragment key={`event-name-string-${index}`}>
								<span>
									<img
										className='plane-icon'
										src={
											event.paid || event.authorized || event.inCart
												? whitePlane
												: plane
										}
										alt='plane icon'
									/>
								</span>
							</Fragment>
						);
					} else {
						return (
							<Fragment key={`event-name-string-${index}`}>{string}</Fragment>
						);
					}
			  })
			: results.map((string, index) => {
					if (index === results.length - 1) {
						return (
							<Fragment key={`event-name-string-${index}`}>{string}</Fragment>
						);
					} else {
						return (
							<Fragment key={`event-name-string-${index}`}>{string} </Fragment>
						);
					}
			  });
	};

	const handleTimeStamp = (multiday) => {
		const { type } = event;
		if (type === 'hotel-offers') {
			return handleMultidayText('MMMM Do');
		} else {
			if (multiday) {
				return handleMultidayText('MMMM Do, h:mm a');
			} else {
				return handleMultidayText('LT');
			}
		}
	};

	return (
		<div className={`event-content ${multiday ? 'multiday' : ''}`}>
			{multiday && !event.cityRange && (
				<div className='main'>
					<h3
						className='capitalize'
						onClick={() => event && selectModalItem(event.uneditedItem)}>
						{handleAllCapsName()}
					</h3>
					<div className='time-stamp'>{handleTimeStamp(multiday)}</div>
				</div>
			)}
			{event.cityRange && (
				<div className='main'>
					<h3
						className='capitalize'
						onClick={() => event && selectModalItem(event.uneditedItem)}>
						{handleAllCapsName()}
					</h3>
					<div className='time-stamp'>{handleTimeStamp(multiday)}</div>
				</div>
			)}
			{!multiday && (
				<div className='main'>
					<h3
						className='single-day capitalize'
						onClick={() => event && selectModalItem(event.uneditedItem)}>
						{handleAllCapsName()}
					</h3>
					<div className='time-stamp'>{handleTimeStamp(multiday)}</div>
				</div>
			)}

			{handleCartButtonShow()}
		</div>
	);
};

const mapStateToProps = (state) => ({
	trip: state.trip,
});

export default connect(mapStateToProps, {
	selectModalItem,
	moveItemTo,
	removeItemFrom,
})(EventContent);
