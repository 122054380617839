import React from 'react';

const SegmentTitle = ({ date, direction, image, daySpan }) => {
	const returnTimeLapse = () => {
		const { days } = daySpan;
		return days ? <span className='red'>+{days}</span> : null;
	};
	return (
		<div className='segment__title'>
			<img src={image} alt='' />
			<h3>
				{direction} Flight | {date}{' '}
				<span className='flight__sub-info'>{returnTimeLapse()} </span>
			</h3>
		</div>
	);
};

export default SegmentTitle;
