import React, { Fragment } from 'react';

const SingleOptionButton = ({ onClick, title, subtitle, cname }) => {
	return (
		<Fragment>
			<button onClick={onClick} className={`preference-button ${cname}`}>
				{title} {subtitle && <span className='subtitle'>{subtitle}</span>}{' '}
			</button>
		</Fragment>
	);
};

export default SingleOptionButton;
