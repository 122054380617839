import React from 'react';

import customerService1 from '../../images/customerService1.png';
import customerService2 from '../../images/customerService2.png';

const SupportSection = () => {
	return (
		<div className='service-section__support'>
			<img
				src={customerService1}
				alt=''
				className='service-section__support-image1'
			/>
			<img
				src={customerService2}
				alt=''
				className='service-section__support-image2'
			/>
			<div className='service-section__support-text'>
				<div className='service-section__support-text-container'>
					<h3 className='service-section__support-text__header'>
						Always at your service
					</h3>
					<p className='service-section__support-text__main'>
						We’re here when you need us. Email us with any questions at any time
						at <span>support@travelwithrove.com</span> .
					</p>
				</div>
			</div>
		</div>
	);
};

export default SupportSection;
