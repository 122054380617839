import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class CardInfoIFrame extends Component {
	constructor() {
		super();
		this.state = {
			iFrameHeight: '0px',
		};
	}
	render() {
		return (
			<>
				<iframe
					onLoad={() => {
						const obj = ReactDOM.findDOMNode(this);
						this.setState({
							iFrameHeight: obj.contentWindow.document.body.scrollHeight + 'px',
						});
					}}
					id='emailmeform_card'
					width='100%'
					height={this.state.iFrameHeight}
					allowtransparency='true'
					frameBorder='0'
					scrolling='no'
					style={{
						border: 'none',
						overflow: 'visible',
						height: this.state.iFrameHeight,
					}}
					src={this.props.source}
					allow='camera'>
					<a href={this.props.source}>Fill out form.</a>
				</iframe>
			</>
		);
	}
}

export default CardInfoIFrame;
