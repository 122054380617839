import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	addEmptyFlight,
	clearTrip,
	createNewTrip,
	filterEmptyItems,
	getTrip,
	removeFlight,
	removeHotel,
	setTrip,
	setTripError,
} from '../../redux/actions/trip';

import { submitTrip } from '../../redux/actions/auth';
import requireAuth from '../hoc/AuthComponent';
import CreateTripShowContainer from '../CreateTrip/CreateTripShowContainer';
import {
	setActivityThemeError,
	setFlightError,
	setHotelError,
	setReturnDateError,
	setTravelTypeError,
} from '../../redux/actions/errors';
import { useLocation, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import uuid from 'react-uuid';

toast.configure();

const CreateTrip = ({
	trip,
	setTrip,
	createNewTrip,
	setFlightError,
	setHotelError,
	setReturnDateError,
	setTravelTypeError,
	setActivityThemeError,
	filterEmptyItems,
	getTrip,
	isAdmin,
	clearTrip,
	userProfile,
}) => {
	const [stage, setStage] = useState(0);
	const [errorSection, setErrorSection] = useState({
		flight: false,
		hotel: false,
		tripDetail: false,
	});
	const location = useLocation();
	console.log('location', location);

	const { tripId } = useParams();

	const addTrip = async (handleAdminSave) => {
		const {
			flightDetails,
			activityThemes,
			returnDate,
			travelType,
			hotelDetails,
		} = trip;

		let valid = true;
		filterEmptyItems();

		let hotelValid = true;
		let flightValid = true;
		let tripDetailValid = true;
		flightDetails.forEach((flight, index) => {
			if (!flight.departure) {
				setFlightError(index, 'departure', 'Please Add Valid Location');
				valid = false;
				flightValid = false;
			}
			if (!flight.destination) {
				setFlightError(index, 'destination', 'Please Add Valid Location');
				valid = false;
				flightValid = false;
			}
			if (!flight.departureDate) {
				setFlightError(
					index,
					'departureDate',
					'Please select a valid date from the drop down menu.'
				);
				valid = false;
				flightValid = false;
			}
		});

		if (flightDetails.length === 1 && !returnDate) {
			setReturnDateError('Please add valid return date');
			flightValid = false;
		}

		// if (!flightValid) setErrorSection({ ...errorSection, flight: true });

		hotelDetails.forEach((hotel, index) => {
			if (!hotel.city) {
				setHotelError(index, 'city', 'Please Add Valid Location');
				valid = false;
				hotelValid = false;
			}
			if (!hotel.startDate) {
				setHotelError(
					index,
					'startDate',
					'Please select a valid date from the drop down menu.'
				);
				valid = false;
				hotelValid = false;
			}
			if (!hotel.endDate) {
				setHotelError(
					index,
					'endDate',
					'Please select a valid date from the drop down menu.'
				);
				hotelValid = false;
				valid = false;
			}
			if (hotel.radius < 0) {
				setHotelError(index, 'radius', 'Please Add Valid Radius');
				hotelValid = false;
				valid = false;
			}

			// Fix end dates
			// if (index !== 0 && index !== hotelDetails.length - 1) {
			// 	hotel.endDate = hotelDetails[index + 1].startDate;
			// }
		});

		// if (!hotelValid) setErrorSection({ ...errorSection, hotel: true });

		if (!travelType) {
			valid = false;
			setTravelTypeError('Please select Travel Type');
			tripDetailValid = false;
		}
		if (!activityThemes.length) {
			valid = false;
			tripDetailValid = false;
			setActivityThemeError('Please select at least one Activitiy Theme');
		}
		// if (!tripDetailValid)
		// 	setErrorSection({ ...errorSection, tripDetail: true });

		setErrorSection({
			...errorSection,
			flight: !flightValid,
			hotel: !hotelValid,
			tripDetail: !tripDetailValid,
		});
		if (valid) {
			if (isAdmin) {
				handleAdminSave();
			} else {
				const TRACKING_ID = process.env.REACT_APP_GA_PROPERTY_ID;
				ReactGA.initialize(TRACKING_ID);
				ReactGA.event({
					category: 'User_Action',
					action: 'submit_trip_profile',
					label: 'create_trip_page',
				});

				setStage(2);
			}
		} else {
			toast('Please fill out missing information');
		}
	};

	const handleStage = (index) => {
		if (index !== 2) setStage(index);
	};

	useEffect(() => {
		if (isAdmin && tripId) {
			getTrip(tripId);

			setStage(1);
		} else if (location.state?.destinations) {
			console.log('location.state', location.state);
			const earliestDate = new Date();
			const nextDate = new Date();
			earliestDate.setDate(earliestDate.getDate() + 2);
			nextDate.setDate(nextDate.getDate() + 4);
			const flightDetails = [];
			const hotelDetails = [];
			location.state.destinations.forEach((destination, index) => {
				const flightDetail = {
					departure: '',
					destination: destination.location.cityCode,
					departureDate: earliestDate.toISOString(),
				};

				const hotelDetail = {
					city: destination.location,
					startDate: earliestDate.toISOString(),
					endDate: nextDate.toISOString(),
					rooms: 1,
					flightIndex: index,
				};

				if (index !== 0) {
					flightDetail.departure =
						location.state.destinations[index - 1].location.cityCode;
					flightDetail.departureDate = nextDate.toISOString();
					hotelDetail.startDate = nextDate.toISOString().slice(0, 10);
					nextDate.setDate(nextDate.getDate() + 3);
					hotelDetail.endDate = nextDate.toISOString().slice(0, 10);
				}
				flightDetails.push(flightDetail);
				hotelDetails.push(hotelDetail);
			});
			setTrip({
				_id: uuid(),
				flightDetails,
				hotelDetails,
			});
			setStage(1);
		} else {
			const { trips } = userProfile || {};
			let foundEmptyTrip = trips?.find((trip) => !trip.submitted.success);
			if (foundEmptyTrip) {
				getTrip(foundEmptyTrip._id);

				setStage(1);
				// setTrip(foundEmptyTrip);
			}
		}

		return () => clearTrip();
	}, [createNewTrip, setTrip]);

	return (
		<>
			<CreateTripShowContainer
				stage={stage}
				handleStage={handleStage}
				errorSection={errorSection}
				addTrip={addTrip}
				setStage={setStage}
				setErrorSection={setErrorSection}
				isAdmin={isAdmin}
			/>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => ({
	errors: state.errors,
	trip: state.trip,
});

export default connect(mapStateToProps, {
	setTrip,
	setTripError,
	createNewTrip,
	submitTrip,
	addEmptyFlight,
	removeFlight,
	removeHotel,
	setFlightError,
	setHotelError,
	setReturnDateError,
	setTravelTypeError,
	setActivityThemeError,
	filterEmptyItems,
	getTrip,
	clearTrip,
})(
	// CreateTrip
	requireAuth(CreateTrip)
);
