import React, { useState, useEffect } from 'react';
import AirportInputUnlocked from '../../Inputs/AirportInputUnlocked';
import TripDate from './TripDate';
import { connect } from 'react-redux';
import moment, { duration } from 'moment';
import {
	addSuggestedHotel,
	editFlightDetail,
	editHotel,
	editSuggestedHotel,
	removeFlight,
} from '../../../redux/actions/trip';
import {
	addFlightErrorObject,
	setFlightError,
} from '../../../redux/actions/errors';
import { motion } from 'framer-motion';
import {
	capitalize,
	returnAirportCityData,
	returnTrueDate,
} from '../../../Utilities/helperFunctions';
const airports = require('../../../Utilities/new_airport_codes.json');

const MultiFlightSection = ({
	id,
	endIndex,
	destination,
	departure,
	departureDate,
	removeFlight,
	onAddClick,
	index,
	editFlightDetail,
	editSuggestedHotel,
	addSuggestedHotel,
	flight,
	hotelDetails,
	idealLocation,
	lodgingPrice,
	errors,
	setFlightError,
	flightDetailKey,
	sortedFlightDetails,
	addFlightErrorObject,
	editHotel,
}) => {
	const [tripInputs, setTripInputs] = useState({
		departure: '',
		destination: '',
		departureDate: '',
	});

	const errorStrings = {
		departure: 'Please select a valid location from the drop down menu',
		destination: 'Please select a valid location from the drop down menu',
		departureDate:
			'Date must be at least one day ahead of your previous destination',
	};

	const handleAddNewSection = () => {
		if (departure && destination && departureDate) {
			onAddClick(destination, '', departureDate);
			addFlightErrorObject();
		}
	};

	const addDate = async (index, name, value) => {
		const result = await editFlightDetail(index, name, value);
		if (result) return true;
	};

	const addError = (string = '') => {
		setFlightError(
			index,
			'departureDate',
			string || errorStrings.departureDate
		);
	};

	const handleChange = (e) => {
		e.preventDefault();
		if (addDate) {
			addDate(index, e.target.name, e.target.value);
		} else if (!e.target.value) {
			addError();
		}

		setTripInputs({
			...tripInputs,
			[e.target.name]: e.target.value,
		});
	};

	const handleEdit = async (found, updated) => {
		let equal = true;
		for (let key in found) {
			if (!found[key] || found[key] !== updated[key]) {
				equal = false;
				break;
			}
		}
		if (!equal) {
			await editSuggestedHotel(updated);
		}
	};

	const action = async (values) => {
		let isNewHotel = false;
		let foundHotel = hotelDetails.find((item) => item.flightIndex === index);
		const prevHotel = hotelDetails.find(
			(item) => item.flightIndex === index - 1
		);
		const isEndIndex = index === endIndex;
		const updatedFlight = {
			...flight,
			...values,
		};
		const isClosed =
			isEndIndex &&
			sortedFlightDetails[0].departure === updatedFlight.destination;

		const city = updatedFlight.hotelItem;
		const suggestedDate =
			updatedFlight && updatedFlight.departureDate
				? returnTrueDate(updatedFlight.departureDate)
				: '';

		const formattedDate =
			updatedFlight && updatedFlight.departureDate
				? moment(updatedFlight.departureDate).format().slice(0, 10)
				: '';

		isNewHotel = !foundHotel;
		foundHotel = foundHotel
			? foundHotel
			: {
					flightIndex: index,
					radius: idealLocation >= 0 ? idealLocation : '',
					priceRange: lodgingPrice || '',
					rooms: 1,
			  };

		if (prevHotel) {
			prevHotel.endDate = formattedDate;
			prevHotel.suggestedEndDate = suggestedDate;
		}
		if (foundHotel) {
			foundHotel.startDate = formattedDate;
			foundHotel.suggestedStartDate = suggestedDate;
			foundHotel.city = city;
		}
		if (!isClosed) {
			prevHotel && (await editSuggestedHotel(prevHotel));
			!isNewHotel
				? await editSuggestedHotel(foundHotel)
				: await addSuggestedHotel(foundHotel);
		} else {
			prevHotel && editSuggestedHotel(prevHotel);
		}

		// if (
		// 	foundHotel &&
		// 	updatedFlight.destination &&
		// 	updatedFlight.departureDate
		// ) {
		// 	let updatedHotel = {
		// 		...foundHotel,
		// 		city,
		// 		startDate: moment(updatedFlight.departureDate).format().slice(0, 10),
		// 		suggestedStartDate: returnTrueDate(updatedFlight.departureDate),
		// 		flightIndex: index,
		// 	};
		// 	if (index > 0 && index < endIndex) {
		// 		updatedHotel = {
		// 			...foundHotel,
		// 			city,
		// 			suggestedStartDate: returnTrueDate(updatedFlight.departureDate),
		// 			startDate: moment(updatedFlight.departureDate).format().slice(0, 10),
		// 		};
		// 		prevUpdated = {
		// 			...prevHotel,
		// 			suggestedEndDate: returnTrueDate(updatedFlight.departureDate),
		// 			endDate: moment(updatedFlight.departureDate).format().slice(0, 10),
		// 			flightIndex: index - 1,
		// 		};
		// 	} else if (index === endIndex) {
		// 		prevUpdated = {
		// 			...prevHotel,
		// 			suggestedEndDate: returnTrueDate(updatedFlight.departureDate),
		// 			endDate: moment(updatedFlight.departureDate).format().slice(0, 10),
		// 			flightIndex: index - 1,
		// 		};
		// 	} else
		// 		updatedHotel = {
		// 			...foundHotel,
		// 			city,
		// 			suggestedStartDate: returnTrueDate(updatedFlight.departureDate),
		// 			startDate: moment(updatedFlight.departureDate).format().slice(0, 10),
		// 			flightIndex: index,
		// 		};

		// 	await handleEdit(foundHotel, updatedHotel);
		// 	if (typeof prevUpdated !== 'undefined')
		// 		await handleEdit(prevHotel, prevUpdated);
		// }
		// if (
		// 	typeof foundHotel === 'undefined' &&
		// 	hotelDetails.length < 6 &&
		// 	updatedFlight.destination &&
		// 	updatedFlight.departureDate
		// ) {
		// 	await addSuggestedHotel({
		// 		city: returnAirportCityData(updatedFlight),
		// 		startDate: moment(updatedFlight.departureDate).format().slice(0, 10),

		// 		suggestedStartDate: returnTrueDate(updatedFlight.departureDate),
		// 		suggestedEndDate: '',
		// 		endDate: '',
		// 		radius: idealLocation >= 0 ? idealLocation : '',
		// 		priceRange: lodgingPrice || '',
		// 		flightIndex: index,
		// 		rooms: 1,
		// 	});
		// }
	};
	useEffect(() => {
		// if (index === 0 && flight.departureDate) {
		// 	let departureDate =
		// 		flight.departureDate instanceof Date
		// 			? flight.departureDate.toISOString()
		// 			: flight.departureDate;

		// 	editHotel(
		// 		index,
		// 		'suggestedStartDate',
		// 		returnTrueDate(departureDate.slice(0, 10))
		// 	);
		// }
		// if (index === endIndex && flight.departureDate && hotelDetails.length) {
		// 	editHotel(
		// 		hotelDetails.length - 1,
		// 		'suggestedEndDate',
		// 		returnTrueDate(departureDate.slice(0, 10))
		// 	);
		// }
		if (!errors) addFlightErrorObject();
	}, []);

	useEffect(() => {
		const returnAirportInfo = (string) => {
			const foundItem = airports.find((item) => item.iata === string);
			if (foundItem) {
				const airport = foundItem.airportName;
				return ` ${string.toUpperCase()} - ${capitalize(airport)}`;
			} else return string;
		};
		setTripInputs({
			departure: returnAirportInfo(departure),
			destination: returnAirportInfo(destination),
			departureDate: departureDate ? departureDate.slice(0, 10) : '',
		});
	}, []);

	return (
		<motion.div
			style={{
				position: 'relative',
				zIndex: sortedFlightDetails.length + hotelDetails.length - index,
			}}
			key={index}
			initial={{ y: '-8rem', opacity: 0, height: '0px' }}
			animate={{ y: '0%', opacity: 1, height: 'auto' }}
			exit={{
				y: '-8rem',
				opacity: 0,
				height: '0px',
			}}
			transition={{ height: 0.6, opacity: 0.2, y: 0.5 }}>
			<div className='pt-4 multi-flight__section'>
				<div className='main'>
					<div className='departure'>
						<label htmlFor={`originLocationCode`} className='active'>
							Departure (Airport)
						</label>
						<AirportInputUnlocked
							setTripInputs={setTripInputs}
							tripInputs={tripInputs}
							handleHotel={null}
							inputKey={`${flightDetailKey}-departure`}
							detailIndex={index}
							value={tripInputs.departure}
							name='departure'
							error={errors && errors.departure}
							handleChange={handleChange}
						/>
					</div>
					<div className='destination'>
						<label htmlFor='destinationLocationCode' className='active'>
							Destination (Airport)
						</label>
						<AirportInputUnlocked
							setTripInputs={setTripInputs}
							tripInputs={tripInputs}
							handleHotel={action}
							inputKey={`${flightDetailKey}-destination`}
							detailIndex={index}
							value={tripInputs.destination}
							name='destination'
							multiCity={true}
							error={errors && errors.destination}
							handleChange={handleChange}
						/>
					</div>
					<TripDate
						handleHotel={action}
						index={index}
						label='Departure Date'
						name='departureDate'
						cname='departure-date'
						sortedFlightDetails={sortedFlightDetails}
						error={errors && errors.departureDate}
						handleChange={handleChange}
						value={tripInputs.departureDate}
						clearError={() => setFlightError(index, 'departureDate', '')}
						addError={addError}
						// addDate={addDate}
					/>
				</div>
				<div className='button__container'>
					<button
						onClick={() => removeFlight(index)}
						className='newcss-plus-button'>
						-
					</button>
					<button
						onClick={endIndex === 2 ? () => null : handleAddNewSection}
						className='newcss-plus-button'>
						+
					</button>
				</div>
			</div>
		</motion.div>
	);
};
const mapStateToProps = (state) => ({
	returnDate: state.trip.returnDate,
	hotelDetails: state.trip.hotelDetails,
	flightDetails: state.trip.flightDetails,
	idealLocation: state.preferences.idealLocation,
	lodgingPrice: state.preferences.lodgingPrice,
});

export default connect(mapStateToProps, {
	removeFlight,
	editFlightDetail,
	editSuggestedHotel,
	addSuggestedHotel,
	addFlightErrorObject,
	setFlightError,
	editHotel,
})(MultiFlightSection);
