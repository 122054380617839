import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactInfo from './ContactInfo';
import CardInfo from './CardInfo';
import CartTotal from './CartTotal';
import checkoutDetailsHOC from '../hoc/CheckoutDetails';

const PaymentDetails = ({
	stage,
	trip,
	curation,
	handleClick,
	updateTripDetails,
}) => {
	const [iframeSubmitSuccess, setIframeSubmitSuccess] = useState(false);

	return trip ? (
		<div className='payment-details'>
			<div className={`left email-me`}>
				<h1>Checkout</h1>
				<ContactInfo />
				{trip && (
					<CardInfo
						trip_id={trip._id}
						user_id={trip.user && (trip.user._id || trip.user)}
						setIframeSubmitSuccess={setIframeSubmitSuccess}
						iframeSubmitSuccess={iframeSubmitSuccess}
					/>
				)}
			</div>
			{trip && (
				<CartTotal
					purchase={trip && trip.purchase}
					iframeSubmitSuccess={iframeSubmitSuccess}
					curation={curation}
					handleClick={curation ? handleClick : updateTripDetails}
					stage={stage}
				/>
			)}
		</div>
	) : null;
};

PaymentDetails.propTypes = {
	trip: PropTypes.object,
};

export default checkoutDetailsHOC(PaymentDetails);
