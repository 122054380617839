import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { increment, decrement } from '../../../redux/actions/trip';

const TravelerNumAge = ({
	cname,
	range,
	label,
	amount,
	increment,
	decrement,
	handleChange,
}) => {
	const handleIncrement = (e) => {
		e.preventDefault();
		if (handleChange) {
			return handleChange(e, 1);
		} else {
			return increment(range, amount);
		}
	};

	const handleDecrement = (e) => {
		e.preventDefault();
		if (handleChange) {
			return handleChange(e, -1);
		} else {
			return decrement(range, amount);
		}
	};
	return (
		<div className={`age-section ${cname}`}>
			<label htmlFor={range}>{label}</label>
			<div className='newcss-number-of-travelers'>
				<button onClick={handleDecrement} id={range} name={range}>
					-
				</button>
				<p>{amount}</p>
				<button onClick={handleIncrement} id={range} name={range}>
					+
				</button>
			</div>
		</div>
	);
};

TravelerNumAge.propTypes = {
	increment: PropTypes.func.isRequired,
	decrement: PropTypes.func.isRequired,
};

export default connect(null, { increment, decrement })(TravelerNumAge);
