import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	addDayTrip,
	removeDayTrip,
	setLoading,
	editDayTrip,
} from '../../../redux/actions/trip';
import { connect } from 'react-redux';
import { setDayTripError } from '../../../redux/actions/errors';
import ErrMessage from '../../Layout/ErrMessage';
import { motion } from 'framer-motion';

const DayTripInput = ({
	currentCity,
	currentId,
	addDayTrip,
	removeTrip,
	addTrip,
	currentDate,
	itemIndex,
	editDayTrip,
	minDate,
	maxDate,
	setDayTripError,
	errors,
}) => {
	const [city, setCity] = useState(currentCity || '');
	const [date, setDate] = useState(currentDate || '');
	const [id, setId] = useState(currentId || '');
	const [appended, setAppended] = useState(currentId ? true : false);
	const sectionId = useRef();
	const handleChange = (event) => {
		event.preventDefault();
		const text = event.target.value;
		setCity(text);
		// editTrip();
	};
	const editTrip = (e) => {
		e.preventDefault();
		editDayTrip({
			index: itemIndex,
			key: e.target.name,
			value: e.target.value,
		});
	};

	// useEffect(() => {
	// 	if (id && city) {
	// 		addDayTrip({ city, date }, id);
	// 	}
	// }, [id, city, date, addDayTrip]);
	return (
		<motion.div
			key={itemIndex}
			initial={{ y: '-7rem', opacity: 0, height: 0 }}
			animate={{ y: '0%', opacity: 1, height: 'auto' }}
			exit={{ y: '-7rem', opacity: 0, height: 0 }}
			transition={{ height: 0.5, opacity: 0.1, y: 0.4 }}
			ref={sectionId}
			className='day-trip__input newcss-grid-2-with-button'
			id={currentId}>
			<div>
				<input
					onBlur={editTrip}
					className='no-auto-complete'
					type='text'
					value={city}
					name='city'
					onChange={handleChange}
					autoComplete='nope'
				/>
				{errors && errors.city ? <ErrMessage text={errors.city} /> : null}
			</div>
			<div>
				<input
					name='date'
					placeholder='yyyy-mm-dd'
					type='date'
					value={date}
					onChange={(e) => {
						e.preventDefault();
						const targetDate = new Date(e.target.value);
						if (targetDate <= maxDate && targetDate >= minDate) {
							setDate(e.target.value);
							errors.date && setDayTripError(itemIndex, 'date', '');
						} else {
							setDate('');
							setDayTripError(
								itemIndex,
								'date',
								'Please select a valid date from the drop down menu.'
							);
						}
						editTrip(e);
					}}
				/>
				{errors && errors.date ? <ErrMessage text={errors.date} /> : null}
			</div>
			<div className='button__container'>
				<button onClick={removeTrip} className='newcss-plus-button'>
					-
				</button>
				<button
					onClick={currentCity ? addTrip : () => null}
					className='newcss-plus-button'>
					+
				</button>
			</div>
		</motion.div>
	);
};

DayTripInput.propTypes = {
	addDayTrip: PropTypes.func.isRequired,
	removeDayTrip: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
};

export default connect(null, {
	addDayTrip,
	removeDayTrip,
	setLoading,
	editDayTrip,
	setDayTripError,
})(DayTripInput);
